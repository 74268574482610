import { Fragment, useEffect } from 'react';
import {
  Box,
  Text,
  Flex,
  Checkbox,
  useDisclosure,
  CheckboxProps,
  Icon,
  Tag,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { useRef, useState } from 'react';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { useEffect$ } from '@ngneat/react-rxjs';
import nodeStyles from './node.module.css';
import NodeMenu from '../node-menu/node-menu';
import {
  SelectionCategories,
  SelectionListIdNames,
  TreeItem,
} from '../../../engine/filters.model';
import { WithTooltip } from '@revelio/core';
import { cx } from '@chakra-ui/utils';
import { FiAlertCircle, FiInfo } from 'react-icons/fi';
import NodeFilterSetTooltipLabel from '../node-filter-set-tooltip/node-filter-set-tooltip-label';
import NodeToggleButton from '../node-toggle-button/node-toggle-button';
import WithSubmenu from '../nested-tree/with-submenu/with-submenu';
import { SelectionCategoryLabelOverrides } from '../nested-tree/node-submenu/node-submenu';
import { get, has, startCase } from 'lodash';
import { PAGE_CONTAINER_CLASS_NAME } from '../../utils/constants';
import { filterSetLabelLookup } from '../../../engine/filters.constants';

export interface WithCheckboxProps extends CheckboxProps {
  wrapCondition: boolean;
  children: React.ReactNode;
}

export function WithCheckbox(props: WithCheckboxProps) {
  const { wrapCondition, children, ...checkboxProps } = props;

  const ParentComponent = wrapCondition ? Checkbox : Fragment;
  const forwardedProps = wrapCondition ? checkboxProps : {};

  return <ParentComponent {...forwardedProps}>{children}</ParentComponent>;
}
const skillSubmenuHeaderFormatter = (header: string) => {
  const firstItem = header.split('/')[0];

  return `${startCase(firstItem)} ... `;
};

const SubmenuHeaderFormatterLookup: Record<string, (str: string) => string> = {
  [SelectionCategories.SKILL_K75]: skillSubmenuHeaderFormatter,
  [SelectionCategories.SKILL_K700]: skillSubmenuHeaderFormatter,
  [SelectionCategories.SKILL_K3000]: skillSubmenuHeaderFormatter,
};
export type RebuildTreeFn = ({
  selectedTree,
  selectionList,
  header,
}: {
  selectedTree: TreeItem[];
  selectionList: SelectionListIdNames;
  header: string;
}) => void;

export enum TreeType {
  SUB_MENU_NESTED = 'sub-menu-nested',
  BREADCRUMB_NESTED = 'breadcrumb-nested',
}

export interface NodeProps {
  data: {
    id: string;
    nestingLevel: number;
    nestingTreeType: TreeType;
    isLeaf: boolean;
    selected: Observable<boolean>;
    intermediate: Observable<boolean>;
    name: string;
    select: (id: string) => void;
    disableParentSelect: boolean;
    hideParentCheckbox: boolean;
    isDisabled: boolean;
    showActionMenu: boolean;
    showTooltip?: boolean;
    candidateTree?: TreeItem[];
    rebuildTreeOnSelection: RebuildTreeFn;
    [key: string]: any; // eslint-disable-line
  };
  isOpen: boolean;
  style: React.CSSProperties;
  setOpen: (isOpen: boolean) => void;
}

export function Node(props: NodeProps) {
  const {
    isNestedTree,
    isLeaf,
    children,
    submenuProps,
    select,
    isSelectableParent,
    disableParentSelect,
    labelFormatter = (label: string) => label,
    showMetaTag = false,
    metaTagKey = '',
    rebuildTreeOnSelection,
    nestingTreeType,
  } = props.data;

  const tooltipDelay = 500;

  const [isAllChildNodesSelected, setIsAllChildNodesSelected] =
    useState<boolean>(false);

  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [isIntermediate, setIsIntermediate] = useState<boolean>(false);

  // this is to avoid memory leak
  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const {
    isOpen: isTooltipOpen,
    onClose: onTooltipClose,
    onOpen: onTooltipOpen,
  } = useDisclosure();

  const [isActionMenuOpen, setIsActionMenuOpen] = useState<boolean>(false);

  const timeout = useRef<NodeJS.Timeout | null>(null);

  useEffect$(() => {
    return props.data.selected.pipe(
      tap((val) => {
        const isNonSelectableParentNode =
          children?.length > 0 && !isSelectableParent && !disableParentSelect;

        if (isNonSelectableParentNode) {
          const childIds = children.map((item: TreeItem) => item.id);
          const selectState = childIds.every((id: string) => has(val, id));

          setIsAllChildNodesSelected(selectState);
        }

        setIsSelected(has(val, props.data.id));
      })
    );
  });

  const {
    isOpen: isSubmenuOpen,
    onOpen: onSubmenuOpen,
    onClose: onSubmenuClose,
  } = useDisclosure();

  useEffect$(() => {
    return props.data.intermediate.pipe(
      tap((val) => {
        if (!props.data.disableParentSelect) {
          setIsIntermediate(val);
        }
      })
    );
  });

  const isExpandableNode = !props.data.isLeaf && props.data.disableParentSelect;
  const submenuSelectionListId = children?.[0]?.selectionListId;
  const showSubmenuSelectionInHeader = ![
    SelectionCategories.COUNTRY,
    SelectionCategories.STATE,
    SelectionCategories.METRO_AREA,
  ].includes(submenuSelectionListId);

  const headerFormatter = get(
    SubmenuHeaderFormatterLookup,
    submenuSelectionListId,
    (str: string) => str
  );
  const subMenuHeader = `${
    showSubmenuSelectionInHeader ? headerFormatter(props.data.name) : ''
  } ${SelectionCategoryLabelOverrides[submenuSelectionListId] || ''}`;

  const [isLabelTruncated, setIsLabelTruncated] = useState(false);

  const hasExpandToggle =
    !isNestedTree &&
    (!isLeaf ||
      (props.data.nestingLevel === 0 && !props.data.isSelectableParent));

  return (
    <WithSubmenu
      header={subMenuHeader}
      hasSubmenu={isNestedTree && children?.length > 0}
      candidateTree={children}
      disclosureProps={{
        isOpen: isSubmenuOpen,
        onOpen: onSubmenuOpen,
        onClose: onSubmenuClose,
      }}
      listId={submenuSelectionListId}
      labelFormatter={labelFormatter}
      select={select}
      showHeaderTooltip={
        ![
          SelectionCategories.REGION,
          SelectionCategories.COUNTRY,
          SelectionCategories.STATE,
          SelectionCategories.METRO_AREA,
        ].includes(submenuSelectionListId)
      }
      {...submenuProps}
    >
      <div
        data-testid="selection-tree-data-node"
        role="group"
        style={props.style}
        className={cx(
          isSubmenuOpen && nodeStyles.selected,
          nodeStyles.highlight,
          nodeStyles.itemContainer
        )}
        onMouseEnter={() => {
          if (!isActionMenuOpen) {
            timeout.current = setTimeout(() => {
              if (mounted.current) onTooltipOpen();
            }, tooltipDelay);
          }
        }}
        onMouseLeave={() => {
          if (timeout.current) {
            clearTimeout(timeout.current);
          }

          if (isTooltipOpen) {
            onTooltipClose();
          }
        }}
      >
        {props.data.id !== '$loading-node' &&
          props.data.id !== '$no-results-node' && (
            <WithTooltip
              showCondition={!!props.data.showTooltip}
              isOpen={isTooltipOpen}
              padding="12px"
              fontSize="12px"
              minWidth="330px"
              maxWidth="330px"
              overflowWrap="normal"
              label={
                <NodeFilterSetTooltipLabel
                  icon={FiAlertCircle}
                  text="This filter is only available on "
                  list={[filterSetLabelLookup[props.data.item.view]]}
                />
              }
              aria-label={`This filter is only available on ${
                filterSetLabelLookup[props.data.item.view]
              }`}
            >
              <Flex
                className={`${nodeStyles.item}`}
                direction="row"
                py="0.5"
                pr="0.25rem"
                justifyContent="space-between"
                alignItems="center"
              >
                <Flex alignItems="center" width="100%" overflow="hidden">
                  {hasExpandToggle ? (
                    <Box width="6" marginLeft={props.data.nestingLevel * 4}>
                      <NodeToggleButton
                        icon={props.isOpen ? ChevronDownIcon : ChevronRightIcon}
                        onClick={(e) => {
                          e.stopPropagation();
                          props.setOpen(!props.isOpen);
                        }}
                      />
                    </Box>
                  ) : (
                    <Box
                      width={
                        (props.data.offsetParent &&
                          props.data.nestingLevel === 0) ||
                        (props.data.nestingLevel > 0 && isLeaf)
                          ? '6'
                          : '0'
                      }
                      marginLeft={
                        props.data.nestingLevel *
                        (props.data.nestingLevel > 0 && isLeaf ? 4 : 0)
                      }
                    ></Box>
                  )}
                  <WithCheckbox
                    className="filter-tree-checkbox"
                    variant="node-checkbox"
                    width="100%"
                    overflow="hidden"
                    wrapCondition={
                      (props.data.isLeaf && props.data.nestingLevel > 0) ||
                      (props.data.isLeaf && props.data.isSelectableParent) ||
                      (!props.data.isLeaf && !props.data.hideParentCheckbox)
                    }
                    _groupHover={{
                      borderColor: 'gray.300',
                    }}
                    isChecked={
                      props.data.nestingLevel === 0 &&
                      !props.data.isSelectableParent
                        ? isAllChildNodesSelected
                        : isSelected
                    }
                    isDisabled={props.data.isDisabled || isExpandableNode}
                    isIndeterminate={
                      props.data.nestingLevel === 0 &&
                      !props.data.isSelectableParent
                        ? isIntermediate && !isAllChildNodesSelected
                        : isIntermediate && !isSelected
                    }
                    as="div"
                    colorScheme="green"
                    paddingLeft={
                      props.data.isLeaf && props.data.nestingLevel > 0 ? 2 : 1
                    }
                    onChange={(e) => {
                      e.stopPropagation();
                      if (isExpandableNode) {
                        return;
                      }

                      props.data.select?.(props.data.id);
                    }}
                    onMouseOver={(e: any) => {
                      setIsLabelTruncated(
                        e?.target?.offsetWidth < e?.target?.scrollWidth
                      );
                    }}
                  >
                    <Flex
                      className={cx(nodeStyles.nodeText)}
                      maxW={props.data.maxLabelWidth}
                      onClick={(e) => {
                        if (!isExpandableNode) {
                          return;
                        }

                        props.setOpen(!props.isOpen);
                      }}
                    >
                      <WithTooltip
                        showCondition={isLabelTruncated}
                        variant="label"
                        padding="12px"
                        fontSize="12px"
                        maxWidth="330px"
                        overflowWrap="normal"
                        openDelay={1000}
                        modifiers={[
                          {
                            name: 'preventOverflow',
                            options: {
                              boundary: document.querySelector(
                                `.${PAGE_CONTAINER_CLASS_NAME}`
                              ),
                              padding: { left: 10 },
                            },
                          },
                        ]}
                        label={
                          <NodeFilterSetTooltipLabel
                            text={labelFormatter(props.data.name)}
                          />
                        }
                      >
                        <Text className={nodeStyles.nodeLabel}>
                          {labelFormatter(props.data.name)}
                        </Text>
                      </WithTooltip>

                      <Text
                        ml={1}
                        color="gray.400"
                        className={nodeStyles.defaultTag}
                      >
                        {props.data.item?.isDefault &&
                          props.data.item?.matchesActiveFilterSetView &&
                          '(default)'}

                        {props.data.item?.isSuggestedResult && '(suggested)'}
                      </Text>

                      {showMetaTag && has(props.data.item, metaTagKey) && (
                        <Flex justifyContent="center">
                          <Tag
                            size="xs"
                            background="gray.100"
                            borderRadius="100"
                            padding="0 12px"
                            marginLeft="8px"
                            variant="subtle"
                          >
                            <Text
                              fontSize="10"
                              color="navyBlue.500"
                              justifyContent="center"
                            >
                              {Intl.NumberFormat('en', {
                                notation: 'compact',
                              }).format(get(props.data.item, metaTagKey))}
                            </Text>
                          </Tag>
                        </Flex>
                      )}

                      {(props.data?.item?.topCleanedTitles ||
                        props.data?.item?.topSkills) && (
                        <Box
                          alignSelf="center"
                          alignItems="center"
                          className={nodeStyles.hidden}
                          _groupHover={{ display: 'flex' }}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <WithTooltip
                            showCondition={true}
                            variant="label"
                            padding="12px"
                            fontSize="12px"
                            maxWidth="330px"
                            overflowWrap="normal"
                            modifiers={[
                              {
                                name: 'preventOverflow',
                                options: {
                                  boundary: document.querySelector(
                                    `.${PAGE_CONTAINER_CLASS_NAME}`
                                  ),
                                  padding: { left: 10 },
                                },
                              },
                            ]}
                            label={
                              <NodeFilterSetTooltipLabel
                                text={`Raw ${props.data.listId.includes('skill') ? 'Skills' : 'Titles'}: `}
                                textStyles={{
                                  fontWeight: 'bold',
                                }}
                                list={
                                  props.data.listId.includes('skill')
                                    ? props.data.item.topSkills
                                    : props.data.item.topCleanedTitles
                                }
                                quoteListItems={false}
                              />
                            }
                          >
                            <Flex ml={1}>
                              <Icon
                                aria-label="Raw Title Tooltip Icon"
                                as={FiInfo}
                              />
                            </Flex>
                          </WithTooltip>
                        </Box>
                      )}
                    </Flex>
                  </WithCheckbox>
                </Flex>

                {isNestedTree && children?.length > 0 && (
                  <NodeToggleButton
                    icon={ChevronRightIcon}
                    onClick={(e) => {
                      if (nestingTreeType === TreeType.BREADCRUMB_NESTED) {
                        rebuildTreeOnSelection({
                          selectedTree: children,
                          selectionList: submenuSelectionListId,
                          header: subMenuHeader,
                        });
                      }

                      if (nestingTreeType === TreeType.SUB_MENU_NESTED) {
                        if (isSubmenuOpen) {
                          onSubmenuClose();
                        } else {
                          onSubmenuOpen();
                        }
                      }
                    }}
                  />
                  // </Box>
                )}

                {props.data.showActionMenu && (
                  <NodeMenu
                    nodeText={props.data.name}
                    setMeta={props.data.item}
                    setNodeModalOpen={props.data.setNodeModalOpen}
                    setActionMenuOpen={props.data.setActionMenuOpen}
                    viewIdForDefault={props.data.viewIdForDefault}
                    isDisabled={props.data.isDisabled}
                    chakraMenuProps={{
                      closeOnBlur: true,
                      onOpen: () => {
                        if (isTooltipOpen) {
                          onTooltipClose();
                        }
                        setIsActionMenuOpen(true);
                        props.data.setActionMenuOpen?.(true);
                      },
                      onClose: () => {
                        setIsActionMenuOpen(false);
                        props.data.setActionMenuOpen?.(false);
                      },
                    }}
                  />
                )}
              </Flex>
            </WithTooltip>
          )}
        {props.data.id === '$loading-node' && <div>Loading...</div>}
        {props.data.id === '$no-results-node' && <div>No results found</div>}
      </div>
    </WithSubmenu>
  );
}

export default Node;
