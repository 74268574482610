import { PageTitles } from '@revelio/core';
import { Screener } from '../../../screener/screener';
import { SelectionCategories } from '@revelio/filtering';
import { generateFilterOptions } from '../../utils/generateFilterOptions';
import { ScreenerDimension } from '@revelio/data-access';

const title = [PageTitles.ROLE, PageTitles.SCREENER];
const filterOptions = generateFilterOptions(
  SelectionCategories.JOB_CATEGORY,
  'Role'
);

export const RoleScreener = () => {
  return (
    <Screener
      title={title}
      filterOptions={filterOptions}
      view={ScreenerDimension.Role}
      sortingDisabledColumns={['Role']}
    />
  );
};

export default RoleScreener;
