import { formatPercentageToTwoSigFigs } from '@revelio/d3';
import {
  GeographySummaryMetric,
  SingleOption,
  GeographyBarChartsData,
} from '../../types';

export const getGeographyBarChartProps = (
  compositionData: GeographyBarChartsData,
  selectedChart: SingleOption,
  name: string
) => {
  const data = compositionData[selectedChart?.value as GeographySummaryMetric];
  const formats = {
    base: '.0%',
    tooltip: ',.1f',
    func: (value: number) => formatPercentageToTwoSigFigs(value / 100),
  };

  return {
    chartPadding: { top: 0, right: 0, bottom: 0, left: 0 },
    colorLookup: {
      0: {
        id: data?.[0]?.id,
        columnName: 'geography',
        shortName: data?.[0]?.metadata?.shortName,
        longName: data?.[0]?.metadata?.longName,
        color: '#5CA9E9',
      },
    },
    chartStyle: '.top-postings-page',
    data,
    heading: 'default',
    height: 507,
    innerPadding: 0.25,
    isCentered: false,
    isFullHeight: true,
    isRenderingOrLoading: undefined,
    isSharesPlot: true,
    isXScaleNice: false,
    labelSize: 11.5,
    name,
    requestHash: undefined,
    setChartHasRendered: undefined,
    ttMainFormat: formats.tooltip,
    ttSecondaryFormat: formats.base,
    width: 492,
    widthHeight: { width: 492, height: 507 },
  };
};
