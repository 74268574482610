import {
  deleteFilter,
  FilterContainerName,
  OtherFilterNames,
  ScreenerFilterOption,
  ScreenerFilterProvider,
  ScreenerFilters,
} from '@revelio/filtering';
import { Grid, GridItem, Flex } from '@chakra-ui/react';
import { Card } from '@revelio/layout';
import { FeatureFlag, PageTitles } from '@revelio/core';
import DashboardPage from '../DashboardPage';
import { useUserTrialDetails } from '@revelio/auth';
import { PlotDataLagWarning } from '@revelio/composed';
import { ScreenerDimension, TrialType } from '@revelio/data-access';
import { DownloadScreenerButton } from './download-screener-button';
import { ScreenerTable } from './screener-table/screener-table';
import { useEffect } from 'react';
import { useUnleashFlag } from '../../hooks/unleash/useUnleashFlag';

export interface ScreenerProps {
  title: PageTitles[];
  filterOptions: ScreenerFilterOption[];
  view: ScreenerDimension;
  sortingDisabledColumns?: string[];
}

export const Screener = ({
  title,
  filterOptions,
  view,
  sortingDisabledColumns,
}: ScreenerProps) => {
  const { isTrialUser, trialType } = useUserTrialDetails();
  const isDataLagged = isTrialUser && trialType === TrialType.ONE_YEAR_DATA_LAG;
  const additionalFiltersEnabled = useUnleashFlag(
    FeatureFlag.CompanyScreenerAdditionalFilters
  );

  /** Adding this as a hotfix to disable adaptive taxonomy roles from being shown in the role dropdown list */
  useEffect(() => {
    deleteFilter(OtherFilterNames.ROLE_TAXONOMY);
  }, []);

  return (
    <ScreenerFilterProvider>
      <DashboardPage title={title} loaderActiveOnInit={false}>
        {/* Need to pass this custom property so DashboardPage renders the filters in the right grid position.
            Should remove this constraint from DashboardPage */}
        <Flex
          {...{ [FilterContainerName]: [FilterContainerName] }}
          width="full"
          justifyContent="space-between"
        >
          <ScreenerFilters filterOptions={filterOptions} />
          {isDataLagged && <PlotDataLagWarning persistent />}
          <DownloadScreenerButton view={view} />
        </Flex>

        <Grid
          height="100%"
          templateRows="1fr"
          templateColumns="1fr"
          data-testid="plots-grid"
        >
          <GridItem rowSpan={1} colSpan={1}>
            <Card p="16px">
              <ScreenerTable
                view={view}
                sortingDisabledColumns={sortingDisabledColumns}
                additionalFiltersEnabled={additionalFiltersEnabled}
              />
            </Card>
          </GridItem>
        </Grid>
      </DashboardPage>
    </ScreenerFilterProvider>
  );
};
