import {
  GetScreenerDataQuery,
  ScreenerReq,
  ScreenerReqSegments,
} from '@revelio/data-access';
import { ScreenerFilterState, getSegmentsText } from '@revelio/filtering';

export type TransformedScreenerEntityInfo = {
  [key: string]: number | string | boolean;
};

const getFilterInfo = (
  key: string,
  index: number,
  filters: Omit<ScreenerReq, 'pagination'>,
  state: ScreenerFilterState
): string => {
  const segments = filters.segments[key as keyof ScreenerReqSegments];

  if ((segments?.length || 0) > index) {
    const treeItems = state?.['filters']?.['sub_filters']?.filter(
      (subFilter) => subFilter.filter.name === key
    )?.[index]?.['treeItems'];

    return Object.keys(treeItems || {}).length
      ? ` (${getSegmentsText(treeItems)})`
      : '(All Employees)';
  }

  return `${index + 1}`;
};

const deserialiseCompanyInfo = (
  datum: GetScreenerDataQuery['screener']['entities'][number]
) => {
  const companyInfo = datum.entity_info?.company_info;

  if (companyInfo) {
    const companyFields = Object.keys(companyInfo).reduce((companyAcc, key) => {
      const value = companyInfo[key as keyof typeof companyInfo];

      if (
        typeof value === 'string' ||
        typeof value === 'number' ||
        typeof value === 'boolean'
      ) {
        return { ...companyAcc, [key]: value };
      } else {
        return companyAcc;
      }
    }, {} as TransformedScreenerEntityInfo);

    const genderMapping = {
      founder_male: 'Male',
      founder_female: 'Female',
    };

    const genderOptions = Object.keys(genderMapping).reduce(
      (acc, key) =>
        companyInfo[key as keyof typeof companyInfo]
          ? [...acc, genderMapping[key as keyof typeof genderMapping]]
          : acc,
      [] as string[]
    );

    const genderValue =
      genderOptions.length > 0 ? genderOptions.join(', ') : 'Unknown';

    const ethnicityMapping = {
      founder_api: 'Asian/Pacific Islander',
      founder_black: 'Black',
      founder_hispanic: 'Hispanic',
      founder_native: 'Native American',
      founder_white: 'White',
      founder_multiple: 'Multiple',
    };

    const ethnicityOptions = Object.keys(ethnicityMapping).reduce(
      (acc, key) =>
        companyInfo[key as keyof typeof companyInfo]
          ? [...acc, ethnicityMapping[key as keyof typeof ethnicityMapping]]
          : acc,
      [] as string[]
    );

    const ethnicityValue =
      ethnicityOptions.length > 0 ? ethnicityOptions.join(', ') : 'Unknown';

    const location = (() => {
      const { hq_country, hq_region, hq_metro_area } = companyInfo;
      const parts = [hq_country, hq_region, hq_metro_area].filter(
        (part) => !part.toLowerCase().includes('empty')
      );
      return parts.join(', ');
    })();

    const companyFieldsWithAggregates = {
      ...companyFields,
      founder_gender: genderValue,
      founder_ethnicity: ethnicityValue,
      hq_location: location,
    };

    return companyFieldsWithAggregates;
  }

  return null;
};

const deserialiseDatum =
  (filters: Omit<ScreenerReq, 'pagination'>, state: ScreenerFilterState) =>
  (
    datum: GetScreenerDataQuery['screener']['entities'][number]
  ): TransformedScreenerEntityInfo => {
    const topLevelFields = Object.keys(datum).reduce((acc, key) => {
      const value = datum[key as keyof typeof datum];

      if (Array.isArray(value)) {
        const arrayEntries = value.reduce((arrayAcc, val, index) => {
          const filterInfo =
            index === 0 ? '' : getFilterInfo(key, index - 1, filters, state);
          const newKey = index === 0 ? key : `${key}_${filterInfo}`;

          const uniqueKey = Object.keys(arrayAcc).includes(newKey)
            ? `${newKey}_1`
            : newKey;

          return { ...arrayAcc, [uniqueKey]: val };
        }, {} as TransformedScreenerEntityInfo);

        return { ...acc, ...arrayEntries };
      } else if (typeof value === 'string' || typeof value === 'number') {
        return { ...acc, [key]: value };
      } else {
        return acc;
      }
    }, {} as TransformedScreenerEntityInfo);

    const companyInfo = deserialiseCompanyInfo(datum);

    if (companyInfo) {
      return { ...topLevelFields, ...companyInfo };
    }

    return topLevelFields;
  };

export const deserialiseScreenerData = (
  data: GetScreenerDataQuery['screener']['entities'],
  filters: Omit<ScreenerReq, 'pagination'>,
  state: ScreenerFilterState
): TransformedScreenerEntityInfo[] =>
  data?.map(deserialiseDatum(filters, state)) || [];
