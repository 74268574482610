import {
  InputMaybe,
  ScreenerDimension,
  ScreenerReq,
  ScreenerReqCompanyInfoFilters,
  ScreenerReqFilters,
  ScreenerReqSegments,
} from '@revelio/data-access';
import {
  ScreenerCompanyDetailCheckboxFilterState,
  ScreenerCompanyDetailTreeFilterState,
  ScreenerCompanyDetailYearRangeFilterState,
  ScreenerCompanySearchFilterState,
  ScreenerFilterState,
  ScreenerSchoolSearchFilterState,
  isCheckboxFilterState,
  isCompanySearchFilterState,
  isSchoolSearchFilterState,
  isTreeFilterState,
  isYearRangeFilterState,
  serialiseSubFilter,
} from '@revelio/filtering';
import { FundingRange } from '../../../view-groups/utils/generateFilterOptions';

const convertToInt = (value: string | number): number => {
  return typeof value === 'string' ? parseInt(value, 10) : value;
};

const filterFundingRanges = (
  value: FundingRange
): { start_val: number; end_val: number } => {
  switch (value) {
    case FundingRange.LessThan100k:
      return { start_val: 0, end_val: 100_000 };
    case FundingRange.From100kTo1M:
      return { start_val: 100_000, end_val: 1_000_000 };
    case FundingRange.From1MTo10M:
      return { start_val: 1_000_000, end_val: 10_000_000 };
    case FundingRange.From10MTo100M:
      return { start_val: 10_000_000, end_val: 100_000_000 };
    case FundingRange.From100MTo1B:
      return { start_val: 100_000_000, end_val: 1_000_000_000 };
    case FundingRange.MoreThan1B:
      return { start_val: 1_000_000_000, end_val: 10_000_000_000_000 };
  }
};

const convertFilterValuesToInt = (
  filter: InputMaybe<ScreenerReqFilters> | undefined
): Record<string, number[]> => {
  return Object.entries(filter || {}).reduce(
    (acc, [key, values]) => {
      if (!values) {
        return acc;
      }

      return {
        ...acc,
        [key]: values.map((value) => convertToInt(value)),
      };
    },
    {} as Record<string, number[]>
  );
};

const serializeTreeFilter = (
  filter: ScreenerCompanyDetailTreeFilterState
): Partial<ScreenerReqCompanyInfoFilters> => {
  const treeItemValues: number[] = Object.values(filter.treeItems || {})
    .map((val) => {
      const id = val?.item?.id;
      if (typeof id === 'string') {
        return parseInt(id, 10);
      }
      if (typeof id === 'number') {
        return id;
      }
      return null;
    })
    .filter((val): val is number => val !== null);

  switch (filter.name) {
    case 'hq_location': {
      const idsBySelectionListId = Object.values(filter.treeItems || {}).reduce(
        (locationAcc, treeItem) => {
          const selectionListId = treeItem.selectionListId;
          const idWithoutPrefix = parseInt(treeItem.id.split('.')[1], 10);

          return {
            ...locationAcc,
            [selectionListId]: [
              ...(locationAcc[selectionListId] || []),
              idWithoutPrefix,
            ],
          };
        },
        {} as Record<string, number[]>
      );

      return {
        ...(idsBySelectionListId['region']
          ? { hq_region: idsBySelectionListId['region'] }
          : {}),
        ...(idsBySelectionListId['country']
          ? { hq_country: idsBySelectionListId['country'] }
          : {}),
        ...(idsBySelectionListId['metro_area']
          ? { hq_msa: idsBySelectionListId['metro_area'] }
          : {}),
      };
    }

    case 'founder_ethnicity': {
      const ethnicityBooleans = treeItemValues.reduce<
        Partial<ScreenerReqCompanyInfoFilters>
      >((ethAcc, value) => {
        switch (value) {
          case 1:
            return { ...ethAcc, founder_white: true };
          case 2:
            return { ...ethAcc, founder_api: true };
          case 3:
            return { ...ethAcc, founder_hispanic: true };
          case 4:
            return { ...ethAcc, founder_black: true };
          case 5:
            return { ...ethAcc, founder_native: true };
          case 6:
            return { ...ethAcc, founder_multiple: true };
          default:
            return ethAcc;
        }
      }, {});
      return ethnicityBooleans;
    }

    case 'founder_gender': {
      const genderBooleans = treeItemValues.reduce<
        Partial<ScreenerReqCompanyInfoFilters>
      >((genAcc, value) => {
        switch (value) {
          case 1:
            return { ...genAcc, founder_male: true };
          case 2:
            return { ...genAcc, founder_female: true };
          default:
            return genAcc;
        }
      }, {});
      return genderBooleans;
    }

    default:
      return {};
  }
};

const serializeCheckboxFilter = (
  filter: ScreenerCompanyDetailCheckboxFilterState
): Partial<ScreenerReqCompanyInfoFilters> => {
  const selectedValues = filter.options.map((option) => option.value);

  switch (filter.name) {
    case 'funding_stage':
      return {
        funding_stage: selectedValues.map((value) => parseInt(value, 10)),
      };

    case 'public':
      return { is_public: selectedValues.includes('true') };

    case 'last_funding_amount': {
      const fundingRanges = (selectedValues as FundingRange[]).map(
        filterFundingRanges
      );
      return { last_funding_amount: fundingRanges };
    }

    case 'total_funding_amount': {
      const totalFundingRanges = (selectedValues as FundingRange[]).map(
        filterFundingRanges
      );
      return { total_funding_amount: totalFundingRanges };
    }

    case 'valuation': {
      const valuationRanges = (selectedValues as FundingRange[]).map(
        filterFundingRanges
      );
      return { valuation: valuationRanges };
    }

    default:
      return {};
  }
};

const serializeYearRangeFilter = (
  filter: ScreenerCompanyDetailYearRangeFilterState,
  acc: ScreenerReqCompanyInfoFilters
): Partial<ScreenerReqCompanyInfoFilters> => {
  const range = {
    start_val: filter.start_year || 0,
    end_val: filter.end_year || 9999,
  };

  switch (filter.name) {
    case 'last_funding_year':
      return {
        last_funding_year: [...(acc.last_funding_year || []), range],
      };

    case 'year_founded':
      return {
        year_founded: [...(acc.year_founded || []), range],
      };

    default:
      return {};
  }
};

const serializeCompanySearchFilter = (
  filter: ScreenerCompanySearchFilterState
): Partial<ScreenerReqCompanyInfoFilters> => {
  const companyIds = Object.keys(filter.companyResultItems || {}).map((key) =>
    parseInt(key, 10)
  );

  switch (filter.name) {
    case 'competitors':
      return companyIds.length > 0 ? { competitors_of: companyIds } : {};

    case 'founder_previous_company':
      return { founder_prev_company: companyIds };

    default:
      return {};
  }
};

const serializeSchoolSearchFilter = (
  filter: ScreenerSchoolSearchFilterState
): Partial<ScreenerReqCompanyInfoFilters> => {
  const schoolIds = Object.keys(filter.schoolResultItems || {}).map((key) =>
    parseInt(key, 10)
  );

  if (filter.name === 'founder_previous_school') {
    return { founder_prev_university: schoolIds };
  } else {
    return {};
  }
};

export const serialiseCompanyDetailFilters = (
  companyDetailFilters: ScreenerFilterState['filters']['company_detail_filters']
): ScreenerReqCompanyInfoFilters => {
  if (!companyDetailFilters) {
    return {};
  }

  return companyDetailFilters.reduce<ScreenerReqCompanyInfoFilters>(
    (acc, filter) => {
      if (isTreeFilterState(filter)) {
        const treeFilter = filter as ScreenerCompanyDetailTreeFilterState;
        return { ...acc, ...serializeTreeFilter(treeFilter) };
      }

      if (isCheckboxFilterState(filter)) {
        const checkboxFilter =
          filter as ScreenerCompanyDetailCheckboxFilterState;
        return { ...acc, ...serializeCheckboxFilter(checkboxFilter) };
      }

      if (isYearRangeFilterState(filter)) {
        const yearRangeFilter =
          filter as ScreenerCompanyDetailYearRangeFilterState;
        return { ...acc, ...serializeYearRangeFilter(yearRangeFilter, acc) };
      }

      if (isCompanySearchFilterState(filter)) {
        const companySearchFilter = filter as ScreenerCompanySearchFilterState;
        return { ...acc, ...serializeCompanySearchFilter(companySearchFilter) };
      }

      if (isSchoolSearchFilterState(filter)) {
        const schoolSearchFilter = filter as ScreenerSchoolSearchFilterState;
        return { ...acc, ...serializeSchoolSearchFilter(schoolSearchFilter) };
      }

      return acc;
    },
    {}
  );
};

export const serialiseScreenerFilters = (
  filters: ScreenerFilterState['filters'],
  view: ScreenerDimension,
  additionalFiltersEnabled?: boolean
): Omit<ScreenerReq, 'pagination'> => {
  const primaryFilters = Object.values(filters?.primary_filter || {});

  // Group filters by their type selectionListId and convert to int
  const primarySelectors = primaryFilters.reduce(
    (acc, filter) => {
      const key = filter?.selectionListId || '';
      const filterId = filter?.item?.id;

      if (key && filterId) {
        const convertedId = convertToInt(filterId);
        return {
          ...acc,
          [key]: [...(acc[key] || []), convertedId],
        };
      }

      return acc;
    },
    {} as Record<string, number[]>
  );

  const subFilters: ScreenerReqSegments = (
    filters.sub_filters || []
  ).reduce<ScreenerReqSegments>((acc, filter) => {
    const segment = serialiseSubFilter(filter);

    return Object.keys(segment).reduce((innerAcc, key) => {
      const segmentKey = key as keyof ScreenerReqSegments;
      const convertedSegment = segment[segmentKey]?.map((item) => ({
        ...item,
        filter: convertFilterValuesToInt(item.filter),
      }));

      return {
        ...innerAcc,
        [segmentKey]: [
          ...(innerAcc[segmentKey] || []),
          ...(convertedSegment || []),
        ],
      };
    }, acc);
  }, {});

  const companyDetailFilters = serialiseCompanyDetailFilters(
    filters.company_detail_filters
  );

  const companyInfoSelectors = Object.keys(companyDetailFilters).length
    ? {
        company_info_selectors: companyDetailFilters,
      }
    : {};

  return {
    dim: view,
    primary_selector: primarySelectors,
    segments: subFilters,
    ...(additionalFiltersEnabled
      ? { entity_info_selectors: companyInfoSelectors }
      : {}),
  };
};
