import {
  Flex,
  Icon,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { FiMapPin } from 'react-icons/fi';
import { TransformedScreenerEntityInfo } from '../utils/deserialiseScreenerData';
import { CompanyIcon } from './icons/company-icon';
import { LinkedinIcon } from './icons/linkedin-icon';
import { EmailIcon } from './icons/email-icon';
import { EmailPopover } from './EmailPopover';

export interface ScreenerPopoverProps {
  data: TransformedScreenerEntityInfo;
  children: JSX.Element[];
}

export const ScreenerPopover = ({ data, children }: ScreenerPopoverProps) => {
  if (!data) return children;

  const {
    phone_number,
    linkedin_url,
    email = 'Not available',
    description,
    short_name = '',
    hq_location,
    website,
  } = data;

  return (
    <Popover
      variant="tableTooltip"
      trigger="hover"
      placement="bottom"
      isLazy
      openDelay={200}
      closeDelay={100}
    >
      <PopoverTrigger>{children[0]}</PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverArrow />
          <PopoverHeader>
            <Flex alignItems="center" gap={2}>
              <a href={`https://${website as string}`}>
                <IconButton
                  variant="solid"
                  colorScheme="grey"
                  aria-label="Company"
                  icon={<CompanyIcon height={40} width={40} bottom={3} />}
                  size="md"
                />
              </a>
              <Flex direction="column" w="full">
                <Flex direction="row" alignItems="center">
                  <Text color="white" fontSize="sm" fontWeight="semibold">
                    {short_name}
                  </Text>
                  <Spacer />
                  <Flex gap={1} alignItems="center">
                    {linkedin_url && (
                      <IconButton
                        isRound
                        variant="solid"
                        colorScheme="grey"
                        aria-label="LinkedIn"
                        size="xs"
                        icon={<LinkedinIcon />}
                        onClick={() => {
                          window.open(
                            `${linkedin_url}`,
                            '_blank',
                            'noopener,noreferrer'
                          );
                        }}
                      />
                    )}
                    {email && (
                      <EmailPopover
                        emails={{
                          email: email as string,
                        }}
                        trigger={
                          <IconButton
                            isRound
                            variant="solid"
                            colorScheme="grey"
                            aria-label="Email"
                            icon={<EmailIcon />}
                            size="xs"
                          />
                        }
                      />
                    )}
                  </Flex>
                </Flex>
                <Flex direction="row" alignItems="center" mt="-1px">
                  <Text color="white" fontSize="xs" opacity={0.6}>
                    <Icon as={FiMapPin} boxSize={2.5} mr={1} />
                    {hq_location || 'Not available'}
                  </Text>
                  <Spacer />
                  <Flex direction="column">
                    <Text color="white" fontSize="xs" opacity={0.6}>
                      {phone_number}
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </PopoverHeader>
          <PopoverBody pb={4}>
            <Text color="white" fontSize="xs" opacity={0.6}>
              {description || 'No description available'}
            </Text>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
