import {
  OtherFilterNames,
  PrimaryFilterValues,
  ScreenerFilterOption,
  SelectionCategories,
} from '@revelio/filtering';
import { capitalize } from 'lodash';

const SCREENER_SUB_FILTERS: ScreenerFilterOption[] = [
  {
    value: SelectionCategories.HEADCOUNT,
    label: 'Headcount',
  },
  { value: OtherFilterNames.INFLOW, label: 'Inflow' },
  { value: OtherFilterNames.OUTFLOW, label: 'Outflow' },
  {
    value: SelectionCategories.HIRING_RATE,
    label: 'Hiring Rate',
  },
  {
    value: SelectionCategories.ATTRITION_RATE,
    label: 'Attrition Rate',
  },
  {
    value: 'growth_yoy',
    label: 'Growth',
  },
  {
    value: SelectionCategories.AVERAGE_SALARY,
    label: 'Salary',
  },
  {
    value: SelectionCategories.AVERAGE_TENURE,
    label: 'Tenure',
  },
];

export enum FundingRange {
  LessThan100k = '<100k',
  From100kTo1M = '100k-1M',
  From1MTo10M = '1M-10M',
  From10MTo100M = '10M-100M',
  From100MTo1B = '100M-1B',
  MoreThan1B = '1B+',
}

const VALUE_OPTIONS = [
  {
    value: FundingRange.LessThan100k,
    label: FundingRange.LessThan100k,
  },
  {
    value: FundingRange.From100kTo1M,
    label: FundingRange.From100kTo1M,
  },
  {
    value: FundingRange.From1MTo10M,
    label: FundingRange.From1MTo10M,
  },
  {
    value: FundingRange.From10MTo100M,
    label: FundingRange.From10MTo100M,
  },
  {
    value: FundingRange.From100MTo1B,
    label: FundingRange.From100MTo1B,
  },
  {
    value: FundingRange.MoreThan1B,
    label: FundingRange.MoreThan1B,
  },
];

const COMPANY_DETAIL_FILTERS: ScreenerFilterOption[] = [
  {
    value: 'founder_previous_company',
    label: 'Founder Previous Company',
    companyDetail: true,
  },
  {
    value: 'competitors',
    label: 'Competitors',
    companyDetail: true,
  },
  {
    value: 'public',
    label: 'Public',
    companyDetail: true,
    checkboxOptions: [
      { value: 'true', label: 'True' },
      { value: 'false', label: 'False' },
    ],
    selectionLimit: 1,
  },
  {
    value: 'hq_location',
    label: 'HQ Location',
    companyDetail: true,
    filterGranularities: [
      SelectionCategories.REGION,
      SelectionCategories.COUNTRY,
      SelectionCategories.METRO_AREA,
    ],
  },
  {
    value: 'last_funding_year',
    label: 'Last Funding Year',
    companyDetail: true,
  },
  {
    value: 'year_founded',
    label: 'Year Founded',
    companyDetail: true,
  },
  {
    value: 'founder_ethnicity',
    label: 'Founder Ethnicity',
    companyDetail: true,
    filterGranularities: [SelectionCategories.ETHNICITY],
  },
  {
    value: 'founder_gender',
    label: 'Founder Gender',
    companyDetail: true,
    filterGranularities: [SelectionCategories.GENDER],
  },
  {
    value: 'funding_stage',
    label: 'Funding Stage',
    companyDetail: true,
    checkboxOptions: [
      {
        value: '1',
        label: 'Angel/Pre-Seed',
      },
      {
        value: '2',
        label: 'Seed',
      },
      {
        value: '3',
        label: 'Series A',
      },
      {
        value: '4',
        label: 'Series B',
      },
      {
        value: '5',
        label: 'Series C',
      },
      {
        value: '6',
        label: 'Series D',
      },
      {
        value: '7',
        label: 'Series E+',
      },
      {
        value: '8',
        label: 'IPO',
      },
    ],
  },
  {
    value: 'last_funding_amount',
    label: 'Last Funding Amount',
    companyDetail: true,
    checkboxOptions: VALUE_OPTIONS,
  },
  {
    value: 'total_funding_amount',
    label: 'Total Funding Amount',
    companyDetail: true,
    checkboxOptions: VALUE_OPTIONS,
  },
  {
    value: 'founder_previous_school',
    label: 'Founder Previous School',
    companyDetail: true,
  },
  // {  // to be added back in later
  //   value: 'valuation',
  //   label: 'Valuation',
  //   companyDetail: true,
  //   checkboxOptions: VALUE_OPTIONS,
  // },
];

export const generateFilterOptions = (
  primaryFilter: PrimaryFilterValues,
  primaryFilterLabel?: string,
  additionalFiltersEnabled = false
): ScreenerFilterOption[] => {
  const labelPostfix = (() => {
    if (primaryFilter === SelectionCategories.INDUSTRY) {
      return '(of entire company)';
    }

    return undefined;
  })();

  const filterGranularities = (() => {
    if (primaryFilter === SelectionCategories.JOB_CATEGORY) {
      return [SelectionCategories.ROLE_K150];
    }

    if (primaryFilter === SelectionCategories.REGION) {
      return [SelectionCategories.COUNTRY];
    }

    return undefined;
  })();

  const filterOptions: ScreenerFilterOption[] = [
    {
      label: primaryFilterLabel || capitalize(primaryFilter),
      value: primaryFilter,
      primary: true,
      filterGranularities,
    },
    ...(primaryFilter === SelectionCategories.INDUSTRY &&
    additionalFiltersEnabled
      ? COMPANY_DETAIL_FILTERS
      : []),
    ...SCREENER_SUB_FILTERS.map((filter) => ({ ...filter, labelPostfix })),
  ].sort((a, b) => a.label.localeCompare(b.label));

  return filterOptions;
};
