import { ScreenerReqFilters, ScreenerReqSegments } from '@revelio/data-access';
import {
  ScreenerCompanyDetailFilterState,
  ScreenerCompanyDetailCheckboxFilterState,
  ScreenerCompanyDetailTreeFilterState,
  ScreenerCompanyDetailYearRangeFilterState,
  ScreenerCompanySearchFilterState,
  ScreenerSchoolSearchFilterState,
} from './screener-filter-reducer';

export type PrimaryFilterValues = keyof Pick<
  ScreenerReqFilters,
  'industry' | 'region' | 'job_category'
>;

export type SubFilterValues = keyof ScreenerReqSegments;

const companyDetailTreeFilters = [
  'founder_ethnicity',
  'founder_gender',
  'hq_location',
] as const;

export type CompanyDetailTreeFilters =
  (typeof companyDetailTreeFilters)[number];

export const isCompanyDetailTreeFilter = (
  filter: string | undefined
): filter is CompanyDetailTreeFilters =>
  companyDetailTreeFilters.includes(filter as CompanyDetailTreeFilters);

const companyDetailCheckboxFilters = [
  'funding_stage',
  'last_funding_amount',
  'total_funding_amount',
  'public',
  'valuation',
] as const;

export type CompanyDetailCheckboxFilters =
  (typeof companyDetailCheckboxFilters)[number];

export const isCompanyDetailCheckboxFilter = (
  filter: string | undefined
): filter is CompanyDetailCheckboxFilters =>
  companyDetailCheckboxFilters.includes(filter as CompanyDetailCheckboxFilters);

const companyDetailYearRangeFilters = [
  'last_funding_year',
  'year_founded',
] as const;

export type CompanyDetailYearRangeFilters =
  (typeof companyDetailYearRangeFilters)[number];

export const isCompanyDetailYearRangeFilter = (
  filter: string | undefined
): filter is CompanyDetailYearRangeFilters =>
  companyDetailYearRangeFilters.includes(
    filter as CompanyDetailYearRangeFilters
  );

const companySearchFilters = [
  'competitors',
  'founder_previous_company',
] as const;

export type CompanySearchFilters = (typeof companySearchFilters)[number];

export const isCompanySearchFilter = (
  filter: string | undefined
): filter is CompanySearchFilters =>
  companySearchFilters.includes(filter as CompanySearchFilters);

const schoolSearchFilters = ['founder_previous_school'] as const;

export type SchoolSearchFilters = (typeof schoolSearchFilters)[number];

export const isSchoolSearchFilter = (
  filter: string | undefined
): filter is SchoolSearchFilters =>
  schoolSearchFilters.includes(filter as SchoolSearchFilters);

export const isTreeFilterState = (
  state: ScreenerCompanyDetailFilterState | undefined
): state is ScreenerCompanyDetailTreeFilterState =>
  companyDetailTreeFilters.includes(state?.name as CompanyDetailTreeFilters);

export const isCheckboxFilterState = (
  state: ScreenerCompanyDetailFilterState | undefined
): state is ScreenerCompanyDetailCheckboxFilterState =>
  companyDetailCheckboxFilters.includes(
    state?.name as CompanyDetailCheckboxFilters
  );

export const isYearRangeFilterState = (
  state: ScreenerCompanyDetailFilterState | undefined
): state is ScreenerCompanyDetailYearRangeFilterState =>
  companyDetailYearRangeFilters.includes(
    state?.name as CompanyDetailYearRangeFilters
  );

export const isCompanySearchFilterState = (
  state: ScreenerCompanyDetailFilterState | undefined
): state is ScreenerCompanySearchFilterState =>
  companySearchFilters.includes(state?.name as CompanySearchFilters);

export const isSchoolSearchFilterState = (
  state: ScreenerCompanyDetailFilterState | undefined
): state is ScreenerSchoolSearchFilterState =>
  schoolSearchFilters.includes(state?.name as SchoolSearchFilters);

export interface ScreenerFilterOption {
  label: string;
  labelPostfix?: string;
  value:
    | PrimaryFilterValues
    | SubFilterValues
    | CompanyDetailTreeFilters
    | CompanyDetailCheckboxFilters
    | CompanyDetailYearRangeFilters
    | CompanySearchFilters
    | SchoolSearchFilters;
  primary?: boolean;
  companyDetail?: boolean;
  filterGranularities?: string[];
  checkboxOptions?: { value: string; label: string }[];
  selectionLimit?: number;
}

export type OperatorOption = {
  value: 'between' | 'greater_than' | 'less_than' | 'is';
  label: string;
};
