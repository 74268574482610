import { TalentDiscoveryMap, PopupDataKey } from '@revelio/d3';
import { TalentDiscoveryDataQuery } from '@revelio/data-access';
import { Card } from '@revelio/layout';

const popupDataKeys = [
  'headcount',
  'postings',
  'timeToFill',
  'salaryDist',
] as PopupDataKey[];

export const CompanyMap = ({
  mapData,
  postingsStartDate,
  postingsEndDate,
}: {
  mapData: TalentDiscoveryDataQuery['talent_discovery_search_v1_5']['map_data'];
  postingsStartDate: string | undefined;
  postingsEndDate: string | undefined;
}) => {
  return (
    <Card p="12px">
      <TalentDiscoveryMap
        data={{
          data: mapData || [],
          popupDataKeys,
        }}
        minZoomLevel={1}
        tooltipHeight={200}
        initialCoords={{
          lng: 20,
          lat: 15,
        }}
        tooltipRequestOverrides={{
          metric_mode: 'expected_hires',
          postings_start_date: postingsStartDate,
          postings_end_date: postingsEndDate,
        }}
        headcountFromPostings
      />
    </Card>
  );
};
