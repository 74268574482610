import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
  Text,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import ClientForm, { ClientFormValues } from './client-form';
import { useState } from 'react';
import { useMutation } from 'urql';
import { CreateClientMutation } from './admin-user-edit/admin-user-client-group-select';
import {
  Client,
  CreateClientRewriteMutationVariables,
  CustomTaxonomyEnum,
  Role,
  S3Format,
} from '@revelio/data-access';
import { useSearchParams } from 'react-router-dom';
import { compact } from 'lodash';
import {
  serializeOptions,
  useAllClientUsersQuery,
  useSubmitUser,
} from './utils/helpers';
import UserForm, { UserFormValues } from './user-form';
import { useGetLoggedInUser } from '@revelio/auth';
import { useUnleashFlag } from '../../hooks/unleash/useUnleashFlag';
import { FeatureFlag } from '@revelio/core';

interface AdminClientAddProps {
  isOpen: boolean;
  onClose: () => void;
}

const AdminClientAdd = ({ isOpen, onClose }: AdminClientAddProps) => {
  const adminReportTypesFeatureFlag = useUnleashFlag(
    FeatureFlag.AdminReportTypes
  );

  const [searchParams, setSearchParams] = useSearchParams();

  const toast = useToast();

  const {
    control: clientControl,
    register,
    formState: { errors },
    handleSubmit,
    watch: clientWatch,
  } = useForm<ClientFormValues>();

  const {
    control: userControl,
    register: userRegister,
    formState: { errors: userErrors },
    handleSubmit: handleUserSubmit,
    setValue: setUserValue,
  } = useForm<UserFormValues>();
  const { loggedInUser } = useGetLoggedInUser();
  const isClientAdmin = loggedInUser.role === Role.ClientAdmin;

  const [, createClientGroup] = useMutation(CreateClientMutation);

  const hasDeliverablesTab = clientWatch('tabs', [])?.some(
    (tab) => tab?.value === 'deliverables'
  );

  const hasReportsTab = clientWatch('tabs', [])?.some(
    (tab) => tab?.value === 'reports'
  );

  const { addUser } = useSubmitUser({
    loggedInUser,
    isClientAdmin,
    onClose,
  });

  const [clientName, setClientName] = useState<string>('');
  const { refetch } = useAllClientUsersQuery(clientName);

  const onSubmit = handleSubmit((formData) => {
    // Defaulting to selectedClient's data_builder_configuration if formData.pipeline_types is undefined
    const pipelineTypesToUse = formData.pipeline_types
      ? serializeOptions(formData.pipeline_types).pipelineTypes
      : [];

    const reportTypesToUse =
      formData.report_types && adminReportTypesFeatureFlag
        ? serializeOptions(formData.report_types).reportTypes
        : [];

    const postingSourcesToUse = formData.pipeline_types
      ? serializeOptions(formData.pipeline_types).postingSources
      : [];

    const s3Location = formData.s3_location
      ? {
          s3_bucket: formData.s3_location,
          s3_file_prefix: '', // placeholders
          s3_format: S3Format.Csv, // placeholders
        }
      : undefined;

    const snowflakeLocation =
      formData.snowflake_database || formData.snowflake_schema
        ? {
            snowflake_db: formData.snowflake_database || '',
            snowflake_schema: formData.snowflake_schema || '',
            snowflake_file_prefix: '', // placeholder
          }
        : undefined;

    const formatData: CreateClientRewriteMutationVariables = {
      client_name: formData.client_name,
      tabs: compact(formData.tabs.map((tab) => tab.value)),
      live: formData.live,
      active: true, // backend cannot create inactive client group. Can only be edited
      linkup_postings: formData.linkup_postings,
      unified_postings: formData.unified_postings,
      num_seats: formData?.num_seats?.toString(),
      custom_taxonomy:
        formData.custom_taxonomy?.value || CustomTaxonomyEnum.Adaptive,
      data_builder_configuration: hasDeliverablesTab
        ? {
            pipeline_type: pipelineTypesToUse,
            posting_source: postingSourcesToUse,
            s3_location: s3Location,
            snowflake_location: snowflakeLocation,
          }
        : {},
      ...(hasReportsTab && reportTypesToUse && adminReportTypesFeatureFlag
        ? { reports_configuration: { report_types: reportTypesToUse } }
        : {}),
    };

    return createClientGroup(formatData).then((result) => {
      if (result?.data?.createClientGroup && !result.error) {
        if (searchParams.get('editing') !== 'user') {
          const newClientName = result.data?.createClientGroup?.client_name;
          const { company_lists, tabs, ...updatedFormatData } = formatData;
          const newClient = {
            ...updatedFormatData,
            ...(newClientName ? { client_name: newClientName } : {}),
          };
          const newSearchParams = new URLSearchParams(searchParams);

          handleUserSubmit(async (formData) => {
            if (newClientName) {
              await addUser({
                formData,
                specifiedClient: newClient as unknown as Client,
              });

              setClientName(newClientName);
              refetch();
            }
          })();

          newSearchParams.set('client', newClientName ?? '');

          setSearchParams(newSearchParams);
        }
        onClose();
      } else {
        toast({
          title: 'Error',
          description: 'An error occurred',
          status: 'error',
          duration: 4000,
          position: 'top-right',
        });
      }
    });
  });

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="md"
      isCentered
      trapFocus={false}
    >
      <ModalOverlay />
      <ModalContent minWidth="65%" maxHeight="95vh" margin="5">
        <ModalHeader>Add a new client</ModalHeader>
        <ModalCloseButton />
        <ModalBody overflowY="auto">
          <FormControl id="name" isInvalid={!!errors.client_name}>
            <FormLabel fontSize="sm" fontWeight="semibold">
              Client Name
            </FormLabel>
            <Input
              {...register('client_name', {
                required: 'Name is required.',
                minLength: {
                  value: 1,
                  message: 'Minimum length should be 1',
                },
              })}
            />
            <FormErrorMessage>
              {errors.client_name && errors.client_name.message}
            </FormErrorMessage>
          </FormControl>
          <ClientForm
            clientToEdit={null}
            control={clientControl}
            register={register}
            watch={clientWatch}
            errors={errors}
          />
          <Text
            fontSize={20}
            fontWeight={600}
            color="blackAlpha.900"
            mt={6}
            mb={4}
          >
            Add a new user
          </Text>
          <UserForm
            userToEdit={null}
            register={userRegister}
            errors={userErrors}
            control={userControl}
            setPassword={(newPassword: string) =>
              setUserValue('password', newPassword)
            }
            hideChangeClientGroup
          />
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button>
          <Button onClick={onSubmit} colorScheme="green">
            Create
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AdminClientAdd;
