import { Box, Flex, Text, TextProps } from '@chakra-ui/layout';
import { PlotInfoComp } from '@revelio/composed';
import { Maybe } from '@revelio/data-access';

export interface StatProps {
  name: string;
  header: string;
  statValues: {
    primaryValue?: string;
    percentChange?: Maybe<number>;
    vsCompetitors?: Maybe<number>;
  };
  children?: React.ReactNode;
  competitorFormatting?: (value: number) => string;
  headerStyles?: TextProps;
  competitorStyles?: TextProps;
  plotInfo?: React.ReactElement;
}

export const Stat = ({
  name,
  header,
  statValues: { primaryValue, percentChange, vsCompetitors },
  children,
  headerStyles,
  competitorFormatting,
  competitorStyles,
  plotInfo,
}: StatProps) => {
  const formattedVsCompetitors =
    competitorFormatting && vsCompetitors
      ? competitorFormatting(vsCompetitors)
      : vsCompetitors;

  const percentageChange = percentChange?.toFixed(1);
  return (
    <Flex key={name} height="100%" role="group">
      <Flex flexDirection="column" flexShrink={1}>
        <Flex>
          <Text
            fontSize="sm"
            fontWeight="semibold"
            {...headerStyles}
            flexShrink="1"
            whiteSpace="nowrap"
          >
            {header}
          </Text>
          {plotInfo && (
            <PlotInfoComp
              plotInfoBody={plotInfo}
              plotInfoConfig={{
                popoverPlacement: 'right',
                iconBoxSize: 2.5,
              }}
            />
          )}
        </Flex>
        <Flex h="full" justifyContent="end" direction="column" mr="10px">
          <Flex gap={2} alignItems="center">
            <Text fontSize={28} fontWeight="semibold">
              {primaryValue}
            </Text>
            <Box>
              <Text
                fontSize={12}
                marginTop={1}
                fontWeight={600}
                color={
                  percentChange && percentChange > 0 ? '#40B975' : '#FF5876'
                }
              >
                {percentChange && percentChange > 0 ? '+' : ''}
                {percentageChange === '-0' ? '0' : percentageChange}%
              </Text>
            </Box>
          </Flex>

          <Text
            fontSize={11}
            marginTop="-6px"
            opacity={0.7}
            minW="95px"
            {...competitorStyles}
          >
            {vsCompetitors && vsCompetitors > 0 ? '+' : ''}
            {formattedVsCompetitors === '-0' ? '+0' : formattedVsCompetitors} vs
            competitors
          </Text>
        </Flex>
      </Flex>

      <Flex height="100%" alignItems="flex-end">
        <Box height="80%">{children}</Box>
      </Flex>
    </Flex>
  );
};

export const calculateStatValues = (
  timeseries:
    | ({
        value?: number | null | undefined;
      } | null)[]
    | null
    | undefined
): { lastValue: number; percentageChange: number } => {
  const firstValue = timeseries?.[0]?.value;
  const lastValue = timeseries?.[timeseries.length - 1]?.value || 0;

  const percentageChange = firstValue
    ? ((lastValue - firstValue) / firstValue) * 100
    : 0;

  return { lastValue, percentageChange };
};

export enum CompositionStatsMetric {
  Headcount = 'headcount',
  GrowthRate = 'growth_rate',
}

export enum PostingsStatsMetric {
  ActivePostings = 'active',
}

export enum SentimentMetric {
  OverallRating = 'overallRating',
  BusinessOutlookRating = 'businessOutlookRating',
  CompensationsBenefitsRating = 'compensationsBenefitsRating',
}
