import { select, selectAll, scaleLinear, min, max, ascending } from 'd3';
import { axisLeft, axisBottom } from 'd3-axis';
import { format as d3Format } from 'd3-format';
import { line, curveCatmullRom, area } from 'd3-shape';
import { generateInlineStyles } from '../../utilities/generate-inline-styles';
import { appendWatermark } from '../../utilities/append-watermark';
import { notifyChartRenderComplete } from '../../utilities/notify-chart-render-complete';
import { appendTitle } from '../../utilities/append-title';
import { adjustDownloadMargins } from '../../utilities/adjust-download-margins';
import { get, isUndefined, last, zip } from 'lodash';
import '../../d3-styles.scss';
import { getAssignedColor } from './helpers';
import { startCasePreserveChars } from '@revelio/core';
import { isArray } from '@chakra-ui/utils';

export const KDEPlotGenerator = (plotConfigs, downloadOptions) => {
  let {
    name,
    data,
    height,
    width,
    targetRef,
    ttMainFormat,
    requestHash,
    isRenderingOrLoading,
    lineColor,
    lineStrokeWidth,
    gradient,
    linearGradient,
    xAxisLabelColor,
    xAxisLabelSize,
    setIsLoading,
    customMargins,
    axisGridLineClassName,
    showMaxPoint,
    setHighestScore,
  } = plotConfigs;
  const {
    title,
    download,
    getSVGNode,
    svgHeight,
    svgWidth,
    containerId,
    padding,
    watermark,
  } = downloadOptions;

  const dims = {};

  const watermarkHeight = watermark?.height || 0;

  name = getSVGNode ? name + '-download' : name;
  height = svgHeight || height;
  width = svgWidth || width;

  if (data && (targetRef?.current || containerId) && height) {
    // remove old svg
    select(`.svg-${name}`).remove();
    select(`.tooltip-${name}`).remove();

    // setup margins and inner dims
    dims.margin = {
      top: 40,
      left: 15,
      bottom: 20,
      right: 15,
    };

    if (download) {
      adjustDownloadMargins(dims, {
        title,
        watermark,
        watermarkHeight,
        padding,
      });
    }
    //Override margins
    if (customMargins) {
      dims.margin = { ...dims.margin, ...customMargins };
    }

    dims.innerHeight = height - (dims.margin.top + dims.margin.bottom);
    dims.innerWidth = width - (dims.margin.left + dims.margin.right);
    // setup svg node

    const node = targetRef?.current;

    const svg = node
      ? select(node).append('svg')
      : select(containerId).append('svg');
    svg
      .attr('width', svgWidth || '100%')
      .attr('height', svgHeight || '100%')
      .attr('class', `svg-${name}`);
    const chart = svg.append('g');
    chart.attr(
      'transform',
      `translate(${dims.margin.left}, ${dims.margin.top})`
    );

    const allSalaryPoints = new Set();
    const allYValues = new Set();
    const pairedData = {};

    const dataMap = data?.map((val) => {
      const { id, metadata = {}, value: yValues = [] } = val;

      const { longName, salaries, cdf } = metadata;

      salaries?.forEach((point, i) => {
        allSalaryPoints.add(point);
        allYValues.add(yValues[i]);
      });

      const zippedValues = zip(salaries, yValues, cdf);
      pairedData[id] = zippedValues;

      return {
        id,
        metadata,
        longName,
        value: zippedValues,
      };
    });

    const allSalaryPointsSorted =
      Array.from(allSalaryPoints).toSorted(ascending);

    const xScaleLine = scaleLinear()
      .domain([min(allSalaryPoints), max(allSalaryPoints)])
      .range([0, dims.innerWidth]);

    const yScale = scaleLinear()
      .domain([min(allYValues), max(allYValues)])
      .range([dims.innerHeight, 0]);

    // // Add tooltips
    const tooltipLineWidth = 250;

    const tooltip = select(`.react-node-${name}`)
      .append('div')
      .style('width', tooltipLineWidth + 'px')
      .style('opacity', 0)
      .style('pointer-events', 'none')
      .style('display', null)
      .style('color', '#ffffff')
      .classed('tooltip-kde', true)
      .classed(`tooltip-${name}`, true)
      .attr('data-testid', 'kde-plot-tooltip');

    const getTooltipData = ({ data, closestDataPoint }) => {
      const ttData = [];
      data?.forEach((entity, index) => {
        const meta = get(entity, 'metadata', {});
        const allValues = get(entity, 'value', []);

        const color = (() => {
          if (isArray(lineColor)) {
            return lineColor[index % lineColor.length];
          }

          return lineColor || getAssignedColor(entity, index);
        })();

        const filteredValues = allValues.find((val) => {
          return val[0] === closestDataPoint;
        });

        const curatedTTData = {
          metadata: meta,
          color,
          value: filteredValues,
        };

        ttData.push(curatedTTData);
      });

      return ttData;
    };

    const generateHTMLTooltip = ({ data, title }) => {
      const outputArray = [];

      outputArray.push(
        `<div class="tt-container"><div class="linechart-title">${d3Format(
          ttMainFormat
        )(title)}</div>`
      );

      data?.forEach((item) => {
        const meta = get(item, 'metadata', {});

        const htmlString = `
        <div class="row" style="display: 'flex'">
          <div class ="tt-color-container"><span style="height: 7px; width: 7px; border-radius: 30%; background-color: ${
            item.color
          }; position: absolute; top: 15%"></span></div>
          <div class="tt-title">${startCasePreserveChars(meta.longName)}</div>
        </div>
        <div class="row tt-cdf" style="display: 'flex' margin-left: '10px'">
        ${(item.value[2] || 0)?.toFixed(1)}% of people make up to ${d3Format(
          '$.3s'
        )(item.value[0])}.
        </div>`;

        outputArray.push(htmlString);
      });

      outputArray.push('</div>');

      return outputArray.join('');
    };

    const arrowLength = 10;

    const mouseMove = (event) => {
      let flipped = false;

      const hoveredValue = xScaleLine.invert(event.offsetX - dims.margin.left);

      const closestDataPoint = allSalaryPointsSorted.find(
        (sal) => hoveredValue <= sal
      );

      const ttData = getTooltipData({
        data: dataMap,
        closestDataPoint,
      });

      const ttBody = generateHTMLTooltip({
        data: ttData,
        title: closestDataPoint,
      });

      tooltip
        .style('opacity', 1)
        .style('display', null)
        .classed(`kde-tooltip-${name}`, true)
        .html(ttBody);

      tooltip.style('transform', () => {
        const ttElement = select(`.kde-tooltip-${name}`).node();

        const ttElementBBox = ttElement?.getBoundingClientRect();

        const ttHeight = ttElementBBox?.height ?? 0;
        const ttWidth = ttElementBBox?.width ?? 0;

        const extraPadBottom = 9;

        const ttHeightWithBottomPad = ttHeight + extraPadBottom;

        let maxValue = 0;

        ttData?.forEach((d) => {
          if (d.value[1] > maxValue) {
            maxValue = d.value[1];
          }
        });

        let offsetX = event.offsetX + dims.margin.left;
        let offsetY =
          dims.margin.top + yScale(maxValue) - ttHeightWithBottomPad;

        if (event.offsetX + ttWidth >= dims.innerWidth) {
          offsetX = event.offsetX - ttWidth - arrowLength;
          flipped = true;
        }

        return `translate(${offsetX}px, ${offsetY}px)`;
      });

      tooltipActiveLine
        .style('opacity', 1)
        .style('display', null)
        .style(
          'transform',
          `translate(${event.offsetX - dims.margin.left}px, ${0}px)`
        );

      if (flipped) {
        tooltip.classed('tooltip-kde-arrow-right', true);
        tooltip.classed('tooltip-kde-arrow-left', false);
      } else {
        tooltip.classed('tooltip-kde-arrow-right', false);
        tooltip.classed('tooltip-kde-arrow-left', true);
      }
    };

    // // Remove tooltips
    const mouseOut = () => {
      tooltip.style('opacity', 0).style('display', 'none');
      tooltipActiveLine.style('opacity', 0).style('display', 'none');
    };

    // const mouseOutActive = () => {
    //   tooltipActive.style('opacity', 0).style('display', 'none');
    //   tooltipActiveLine.style('opacity', 0).style('display', 'none');
    // };

    // add the area chart
    dataMap?.forEach((val, index) => {
      // // set up the area chart gradient
      chart
        .append('linearGradient')
        .attr('id', `color-gradient-${index}`)
        .attr('gradientUnits', 'userSpaceOnUse')
        .attr('x1', 0)
        .attr('y1', yScale(0))
        .attr('x2', 0)
        .attr('y2', yScale(max(allYValues)))
        .selectAll('stop')
        .data(() => {
          const color = gradient || getAssignedColor(val, index);

          if (linearGradient) {
            return [
              { offset: '0%', color: linearGradient[1] },
              { offset: '100%', color: linearGradient[0] },
            ];
          }

          const gradientColor = (() => {
            if (isArray(gradient)) {
              return gradient[index % gradient.length];
            }

            return gradient;
          })();

          return [
            {
              offset: '100%',
              color: gradientColor || `${color}35`,
            },
          ];
        })
        .enter()
        .append('stop')
        .attr('offset', function (d) {
          return d.offset;
        })
        .attr('stop-color', function (d) {
          return d.color;
        });

      chart
        .datum(val.value)
        .append('path')
        .attr('class', 'area')
        .attr('fill', `url(#color-gradient-${index})`)
        .attr(
          'd',
          area()
            .curve(curveCatmullRom)
            .x((d) => xScaleLine(d[0]))
            .y0(yScale(0))
            .y1((d) => yScale(d[1]))
        );
    });

    const yAxisGrid = axisLeft()
      .scale(yScale)
      .tickSize(-dims.innerWidth)
      .tickFormat('')
      .ticks(4);

    const highestSalary = last(allSalaryPointsSorted);
    const xAxisBottom = axisBottom()
      .scale(xScaleLine)
      .ticks(4)
      .tickFormat((d) => {
        const digitsBeforeSeparator = Math.floor(
          d / (highestSalary < 1000 ? 1 : 1000)
        ).toString();
        return `$${digitsBeforeSeparator}${highestSalary < 1000 ? '' : 'k'}`;
      });

    // // place gridlines above area chart but below line and bars
    // // y gridlines
    chart
      .append('g')
      .attr(
        'class',
        `y ${axisGridLineClassName ? axisGridLineClassName : 'axis-grid'}`
      )
      .attr('transform', `translate(0,0)`)
      .call(yAxisGrid)
      .call((g) => g.selectAll('.domain').remove());

    // // TODO: Pull these from stylesheet
    selectAll(
      `.${axisGridLineClassName ? axisGridLineClassName : 'axis-grid'} line`
    )
      .attr('stroke', '#e6e8ed')
      .attr('stroke-dasharray', '1,3')
      .attr('stroke-width', '1.3')
      .attr('stroke-linecap', 'round');

    // // Add vertical line for the active tooltip to the chart over the area chart
    const tooltipActiveLine = chart
      .append('line')
      .style('opacity', 0)
      .style('pointer-events', 'none')
      .style('display', null)
      .attr('y1', dims.innerHeight)
      .attr('x1', 0)
      .attr('y2', 0)
      .attr('x2', 0)
      .attr('stroke', 'black')
      .attr('stroke-width', 1.5)
      .attr('stroke-dasharray', 3, 1);

    // draw bars
    // if (dataMap.length === 1) {
    //   chart
    //     .selectAll('.bar')
    //     .data(dataMap[0].value)
    //     .join('path')
    //     .attr('class', 'bar')
    //     .attr('d', (d) => {
    //       return roundedRect(
    //         xScaleBar(d[0]),
    //         yScale(d[1]),
    //         xScaleBar.bandwidth(),
    //         yScale(0) - yScale(d[1]),
    //         [1, 1, 0, 0] //round top corners of bar
    //       );
    //     })
    //     .attr('fill', colors[1])
    //     .attr('cursor', 'pointer')
    //     .on('mouseover', mouseOverBars)
    //     .on('mouseout', mouseOut);
    // }

    // // add the line for the area chart

    dataMap.forEach((dataValue, index) => {
      chart
        .datum(dataValue.value)
        .append('path')
        .attr('class', 'area-line-stroke')
        .attr('fill', 'none')
        .attr('stroke', () => {
          if (isArray(lineColor)) {
            return lineColor[index % lineColor.length];
          }

          return lineColor || getAssignedColor(dataValue, index);
        })
        .attr('stroke-width', lineStrokeWidth || 2)
        .attr(
          'd',
          line()
            .curve(curveCatmullRom)
            .x((d) => xScaleLine(d[0]))
            .y((d) => yScale(d[1]))
        );
    });

    if (showMaxPoint) {
      dataMap.forEach((dataValue, index) => {
        let maxPoint;

        dataValue.value.forEach((point) => {
          if (isUndefined(maxPoint) || point[1] > maxPoint[1]) {
            maxPoint = point;
          }
        });

        if (!maxPoint) return;

        const updateHighestValue = (newValue) => {
          setHighestScore((prev) => (newValue > prev ? newValue : prev));
        };

        updateHighestValue(maxPoint[0]);

        chart
          .datum(maxPoint)
          .append('g')
          .append('text')
          .attr('x', (d) => xScaleLine(d[0]))
          .attr('y', (d) => yScale(d[1]) - 15)
          .attr('fill', 'white')
          .attr('text-anchor', 'middle')
          .text((d) => {
            const numToFormat = d[0];

            const numThousands = numToFormat / 1000;

            const wholeThousands = Math.floor(numThousands);

            if (wholeThousands > 0) {
              return `$${Math.round(numThousands)}k`;
            }

            if (wholeThousands === 0) {
              return `$${numToFormat}`;
            }

            return d3Format('$.0s')(numToFormat);
          })
          .style('font-size', '2.5vh');

        chart
          .datum(maxPoint)
          .append('circle')
          .attr('cx', (d) => xScaleLine(d[0]))
          .attr('cy', (d) => yScale(d[1]))
          .attr('r', 5)
          .style('fill', 'white');
      });
    }

    chart
      .append('g')
      .attr('transform', `translate(0, ${dims.innerHeight})`)
      .classed(`inline-style-target-${name}`, !!download)
      .classed('axis-label-main-postings', true)
      .attr('data-testid', 'plot-x-axis')
      .call(xAxisBottom)
      .style('color', xAxisLabelColor)
      .style('font-size', xAxisLabelSize)
      .call((g) => g.selectAll('.domain').remove())
      .call((g) => g.selectAll('line').remove());

    const grid = chart
      .append('rect')
      .attr('width', dims.innerWidth)
      .attr('height', dims.innerHeight)
      .attr('x', 0)
      .attr('y', 0)
      .attr('cursor', 'pointer')
      .attr('fill-opacity', '0');

    grid.on('mousemove', mouseMove).on('mouseout', mouseOut);

    if (!download) {
      notifyChartRenderComplete(chart, requestHash, () => {
        isRenderingOrLoading?.next(false);
        setIsLoading?.(false);
      });
    }

    if (download) {
      generateInlineStyles(`.inline-style-target-${name}`);
    }

    if (title) {
      appendTitle(chart, title, dims, padding);
    }

    if (watermark) {
      appendWatermark(
        chart,
        watermark,
        dims.innerWidth + dims.margin.right,
        dims.innerHeight + dims.margin.bottom,
        padding
      );
    }

    if (getSVGNode) {
      return svg.node();
    }
  }
};
