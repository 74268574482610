import React from 'react';
import {
  Box,
  Flex,
  //Spacer,
  Text,
} from '@chakra-ui/layout';
// import { format as d3Format } from 'd3-format';
import { usePlayer } from './context/PlayerContext';
import { truncate } from 'lodash';

const InfoBar = () => {
  // const playerEmail = 'charles.slotsemailtest@reveliolabs.com';
  // const playerHighScore = 102985;
  // const playerSpinCount = 14;

  const { player } = usePlayer();

  return (
    <Box
      zIndex={2}
      color="white"
      w="full"
      h="100px"
      backgroundColor="black"
      px={8}
      py={4}
    >
      <Flex alignItems="center" h="full" gap={8}>
        <Flex flexDirection="column" minW={0}>
          <Text
            color="white"
            fontWeight={600}
            fontSize={16}
            textTransform="uppercase"
          >
            Player
          </Text>
          <Text
            color="white"
            fontWeight={700}
            fontSize={30}
            flexShrink={1}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {player.email ||
              truncate(player.playerId, {
                length: 14,
                omission: '',
              })}
          </Text>
        </Flex>
        {/* <Spacer />
        <Flex flexDirection="column" alignItems="end">
          <Text
            color="white"
            fontWeight={600}
            fontSize={16}
            textTransform="uppercase"
          >
            Personal Best Salary
          </Text>
          <Text color="white" fontWeight={700} fontSize={30}>
            {d3Format('$,')(playerHighScore)}
          </Text>
        </Flex>
        <Flex flexDirection="column" alignItems="end">
          <Text
            color="white"
            fontWeight={600}
            fontSize={16}
            textTransform="uppercase"
          >
            Spin Count
          </Text>
          <Text color="white" fontWeight={700} fontSize={30}>
            {playerSpinCount}
          </Text>
        </Flex> */}
      </Flex>
    </Box>
  );
};

export default InfoBar;
