import { Button, Icon, MenuProps, useDisclosure } from '@chakra-ui/react';
import { ActionMenu } from '@revelio/core';
import { useRef } from 'react';
import { FiEdit, FiMoreHorizontal, FiStar, FiTrash2 } from 'react-icons/fi';
import { DefaultKeyLookup } from '../../../data-api/data-api.model';
import {
  UserStoredFilterSet,
  ViewFilterDefaultIds,
} from '../../../engine/filters.model';
import { PersistMethods } from '../../../engine/filters.persist';
import {
  deleteExistingStoredFilterSetById,
  upsertStoredFilterSet,
} from '../../../engine/filters.storedset';
import { ACTION_MENU_LIST_CLASS_NAME } from '../../utils/constants';
import NodeEditModal from '../node-edit-modal/node-edit-modal';

/* eslint-disable-next-line */
export interface NodeMenuProps {
  nodeText: string;
  chakraMenuProps?: Partial<MenuProps>;
  setActionMenuOpen: React.Dispatch<boolean>;
  setNodeModalOpen: React.Dispatch<boolean>;
  setMeta: UserStoredFilterSet;
  viewIdForDefault?: string;

  isDisabled?: boolean;
}

export function NodeMenu({
  nodeText,
  chakraMenuProps = {},
  setActionMenuOpen,
  setNodeModalOpen,
  setMeta,
  viewIdForDefault,

  isDisabled,
  ...props
}: NodeMenuProps) {
  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose,
  } = useDisclosure();
  const menuRef = useRef(null);

  const handleOnToggleDefault = () => {
    upsertStoredFilterSet({
      def: {
        ...setMeta,
        lastModified: new Date(),
      },
      options: {
        updateFiltersState: false,
        updateView: false,
        defaultOptions: {
          isDefault: !setMeta.isDefault,
          viewIdForDefault,
          existingSet: setMeta,
        },
      },
      mode: PersistMethods.UPDATE_PRESET,
    })
      .then((res) => {
        // handle success
      })
      .catch((err) => {
        // handle error
        console.error('Error: ', err);
      });
  };

  const handleOnDelete = () => {
    const { id, isDefault, view } = setMeta;

    const defaultKey: ViewFilterDefaultIds = DefaultKeyLookup[
      view
    ] as ViewFilterDefaultIds;

    if (id) {
      deleteExistingStoredFilterSetById(id, isDefault, defaultKey)
        .then((res) => {
          // TODO: handle success, maybe a success toast
        })
        .catch((err) => {
          // TODO: handle error, maybe an error toast
          console.error('Delete Error: ', err);
        });
    } else {
      console.error('Delete Error: No preset id provided');
    }
  };

  return (
    <>
      <NodeEditModal
        setMeta={setMeta}
        isOpen={isModalOpen}
        onClose={() => {
          setNodeModalOpen?.(false);
          onModalClose();
        }}
        defaultText={nodeText}
        finalFocusRef={menuRef}
      />
      <ActionMenu
        testId="node-action-menu"
        portalMenu={true}
        preventOverflow={false}
        menuButtonIcon={FiMoreHorizontal}
        menuButtonVariant="horizontalSlim"
        menuIconProps={{ color: 'icon.lightGray' }}
        menuButtonRef={menuRef}
        chakraMenuProps={chakraMenuProps}
        menuListClassName={ACTION_MENU_LIST_CLASS_NAME}
      >
        <Button
          leftIcon={<Icon as={FiTrash2} boxSize={3.5} />}
          size="sm"
          variant="white"
          data-testid="delete-item"
          onClick={handleOnDelete}
          justifyContent="flex-start"
          borderRadius="0"
          w="100%"
        >
          Delete
        </Button>
        <Button
          leftIcon={<Icon as={FiEdit} boxSize={3.5} />}
          size="sm"
          variant="white"
          onClick={(e) => {
            setNodeModalOpen?.(true);
            onModalOpen();
          }}
          justifyContent="flex-start"
          borderRadius="0"
          w="100%"
        >
          Rename
        </Button>
        {!isDisabled && (
          <Button
            leftIcon={<Icon as={FiStar} boxSize={3.5} />}
            size="sm"
            variant="white"
            onClick={handleOnToggleDefault}
            justifyContent="flex-start"
            borderRadius="0"
            w="100%"
          >
            {`${setMeta.isDefault ? 'Unset' : 'Set'} Default`}
          </Button>
        )}
      </ActionMenu>
    </>
  );
}

export default NodeMenu;
