import { propsFactory, withProps, createStore } from '@ngneat/elf';
import {
  entitiesPropsFactory,
  withEntities,
  withActiveIds,
} from '@ngneat/elf-entities';
import { withRequestsStatus, withRequestsCache } from '@ngneat/elf-requests';
import {
  FilterStoreRootProps,
  StoredFilterSet,
  SelectionList,
  ViewFilterDefaults,
  Filter,
} from './filters.model';
import { ViewTypes } from '../data-api/data-api.model';
import { PYTHON_COMPANY_SELECTION_ID } from './filters.constants';

export const listNameOverrides: Record<string, string> = {
  [PYTHON_COMPANY_SELECTION_ID]: 'company',
};

export const GlobalFilterSetId = 'GLOBAL';

export const {
  withActiveSet,
  updateActiveSet,
  selectActiveSet,
  resetActiveSet,
  getActiveSet,
  setActiveSet,
} = propsFactory('activeSet', {
  initialValue: GlobalFilterSetId,
});

export const {
  withActiveEntitiesCount,
  updateActiveEntitiesCount,
  selectActiveEntitiesCount,
  resetActiveEntitiesCount,
  getActiveEntitiesCount,
  setActiveEntitiesCount,
} = propsFactory('activeEntitiesCount', {
  initialValue: {},
});

export type Tab = ViewTypes.COMPANY | ViewTypes.GEO | ViewTypes.ROLE;
export type ActiveTab = Tab | '' | string;
const initialTabValue: ActiveTab = '';

export const { withActiveTab, getActiveTab, setActiveTab } = propsFactory(
  'activeTab',
  { initialValue: initialTabValue as ActiveTab }
);

const initialStringUnset = undefined as string | undefined;

export const {
  withDefaultLastMonth,
  selectDefaultLastMonth,
  getDefaultLastMonth,
  setDefaultLastMonth,
} = propsFactory('defaultLastMonth', { initialValue: initialStringUnset });

export const {
  withLastStartDate,
  selectLastStartDate,
  getLastStartDate,
  setLastStartDate,
} = propsFactory('lastStartDate', { initialValue: initialStringUnset });

export const {
  withCustomActiveSet,
  selectCustomActiveSet,
  resetCustomActiveSet,
  setCustomActiveSet,
} = propsFactory('customActiveSet', { initialValue: initialStringUnset });

export const { storedFilterSetEntitiesRef, withStoredFilterSetEntities } =
  entitiesPropsFactory('storedFilterSet');
export const { selectionListEntitiesRef, withSelectionListEntities } =
  entitiesPropsFactory('selectionList');
export const { viewFilterDefaultEntitiesRef, withViewFilterDefaultEntities } =
  entitiesPropsFactory('viewFilterDefault');

export const filterStore = createStore(
  // base store props & entities
  { name: 'filters' },
  withProps<FilterStoreRootProps>({}),
  // TODO/jbellizzi: we should have separate filter entities for different filter types
  withEntities<Filter>(),
  withActiveIds(),
  // custom entities
  withStoredFilterSetEntities<StoredFilterSet>({ initialValue: [] }),
  withSelectionListEntities<SelectionList>(),
  withViewFilterDefaultEntities<ViewFilterDefaults>(),
  // custom props factories
  withActiveSet(),
  withActiveTab(),
  withDefaultLastMonth(),
  withLastStartDate(),
  withCustomActiveSet(),
  withRequestsStatus<`selectionList`>(),
  withRequestsCache<`selectionList`>(),
  withRequestsStatus<`viewFilterDefault`>(),
  withRequestsCache<`viewFilterDefault`>()
);
