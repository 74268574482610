import mapboxgl from 'mapbox-gl';
import { useEffect } from 'react';
import { getTalentDiscoveryInput } from './td-utils';
import {
  Subject,
  bufferTime,
  concatMap,
  debounceTime,
  filter,
  map,
  mergeAll,
  of,
  switchMap,
} from 'rxjs';
import { GetTalentDiscoveryTooltipPayload } from '@revelio/data-access';
import hash from 'object-hash';
import { seedTooltipCache } from './helpers';

interface UseTalentDiscoveryPreFetchProps {
  map: mapboxgl.Map | undefined;
}

// Max points to requesting at a time
const SEED_BATCH_SIZE = 4;

const preFetchSubject$ = new Subject<GetTalentDiscoveryTooltipPayload[]>();

// hash set to limit the amount of requests we make so we don't repeat the same request
const hashSet = new Set<string>();

preFetchSubject$
  .pipe(
    // debounce for map movement
    debounceTime(500),
    // switch to new request on map movement if in the middle of request
    switchMap((queries) =>
      of(queries).pipe(
        // generate hash of each query and filter out queries that are already in cache
        map((items) =>
          items
            .map((item) => ({ query: item, hash: hash(item) }))
            .filter(({ hash }) => !hashSet.has(hash))
        ),
        // convert array to stream of queries
        mergeAll(),
        // batch stream into groups of MAX_FEATURES_TO_SEED
        bufferTime(500, null, SEED_BATCH_SIZE),
        // ignore empty groups
        filter((queries) => queries.length > 0),
        // send request to seed-cache and add to cache on successful response
        concatMap(async (queries) => {
          const seedResponse = await seedTooltipCache(
            queries.map(({ query }) => query)
          );
          if (seedResponse.success) {
            queries.forEach(({ hash }) => hashSet.add(hash));
          }
          return seedResponse;
        })
      )
    )
  )
  .subscribe();

export const useTalentDiscoveryPreFetch = ({
  map,
}: UseTalentDiscoveryPreFetchProps) => {
  useEffect(() => {
    if (map) {
      map.on('moveend', () => {
        // only call this once so we don't keep querying the filter store for each
        // geo point. Then reduce into multiple values overwriting each id and type
        const getBaseInput = getTalentDiscoveryInput({ id: 1, type: 1 });

        const feats = map
          .queryRenderedFeatures()
          .filter((feature) =>
            ['places-1', 'places-2'].includes(feature.layer.id)
          )
          .map((feature) => {
            const properties = feature.properties;
            return {
              id: properties?.['id'],
              type: properties?.['type'],
              count: properties?.['counts'],
            };
          });

        const sortedMaxFeatures = feats.sort((a, b) => b.count - a.count);
        const first20SortedFeatures = sortedMaxFeatures.slice(0, 20);

        const featuresWithBaseInput = first20SortedFeatures.map(
          ({ type, id }) => {
            const { country, ...restBaseInput } = getBaseInput;
            return { ...restBaseInput, [type === 1 ? 'country' : 'msa']: [id] };
          }
        );

        preFetchSubject$.next(featuresWithBaseInput);
      });
    }
  }, [map]);
};
