import { GetScreenerDataQuery, ScreenerDimension } from '@revelio/data-access';
import { TransformedScreenerEntityInfo } from './deserialiseScreenerData';
import { FORMAT_MAP, SubFilterValues } from '@revelio/filtering';
import { capitalize } from 'lodash';
import { formatIntegerToDecimal } from '@revelio/core';
import { format } from 'd3-format';

type DataItem = NonNullable<
  GetScreenerDataQuery['screener']['entities']
>[number] &
  GetScreenerDataQuery['screener']['entities'][number]['entity_info']['company_info'];
type ColumnKey =
  | keyof DataItem
  | 'founder_ethnicity'
  | 'founder_gender'
  | 'hq_location';

const COLUMN_WHITELIST: ColumnKey[] = [
  'long_name',
  'headcount',
  'inflow',
  'outflow',
  'hiring',
  'attrition',
  'growth_yoy',
  'salary',
  'tenure',
];

const ADDITIONAL_COLUMN_WHITELIST: ColumnKey[] = [
  'description',
  'email',
  'funding_stage',
  'is_public',
  'last_funding_amount',
  'last_funding_year',
  'total_funding_amount',
  // 'valuation', // to be added later
  'year_founded',
  'founder_ethnicity',
  'founder_gender',
  'hq_location',
  'website',
  'phone_number',
  'linkedin_url',
];

const rateColumns: ColumnKey[] = ['hiring', 'attrition', 'growth'];

const customHeaders: { [key in ColumnKey]?: string } = {
  is_public: 'Public Company',
  hq_location: 'HQ Location',
  linkedin_url: 'LinkedIn URL',
};

const formatHeader = (name: ColumnKey, view: ScreenerDimension) => {
  if (name === 'long_name') {
    if (view === ScreenerDimension.Company) {
      return 'Company Name';
    }

    if (view === ScreenerDimension.Geography) {
      return 'Metro Area';
    }

    if (view === ScreenerDimension.Role) {
      return 'Role';
    }
  }

  if (customHeaders[name]) {
    return customHeaders[name];
  }

  // Split the name into words while preserving the text inside parentheses
  const words = name.replace(/_/g, ' ').match(/(\([^)]+\)|[^\s]+)/g);

  const parsedName = words
    ?.map((word) => {
      if (word.startsWith('(') && word.endsWith(')')) {
        return word;
      }

      return capitalize(word);
    })
    .join(' ');

  if (parsedName?.includes('Yoy')) {
    return parsedName.replace('Growth Yoy', 'Growth');
  }

  if (parsedName?.includes('Growth')) {
    return parsedName.replace('Growth', 'Cumulative Growth');
  }

  if (rateColumns.includes(name)) {
    return `${parsedName} Rate`;
  }

  return parsedName;
};

const roundValue = (value: number): string | number => {
  return Math.round(value).toLocaleString();
};

const applyFormattingRule = (
  value: number | string | boolean,
  columnName: ColumnKey
): string | number => {
  if (Array.isArray(value)) {
    value = value[0];
  }

  if (typeof value === 'boolean') {
    return value ? 'Yes' : 'No';
  }

  if (typeof value !== 'number' || isNaN(value)) {
    return value;
  }

  const isRateColumn = rateColumns.some((rateColumn) =>
    columnName.startsWith(rateColumn)
  );

  if (isRateColumn) {
    return formatIntegerToDecimal(value * 100, { decimals: 2 });
  }

  const rawColumnName = columnName.split('_')[0].trim();

  if (
    ['salary', 'valuation'].includes(rawColumnName) ||
    ['last_funding_amount', 'total_funding_amount'].includes(columnName)
  ) {
    return format('.3s')(value).replace('G', 'B');
  }

  if (['headcount', 'inflow', 'outflow'].includes(rawColumnName)) {
    return roundValue(value);
  }

  if (columnName.includes('year')) {
    return value;
  }

  return formatIntegerToDecimal(value, { decimals: 2 });
};

interface RenderFieldWithFormatProps {
  value: string | number;
  column: { name: ColumnKey } | null | undefined;
}

const renderFieldWithFormat = ({
  value,
  column,
}: RenderFieldWithFormatProps) => {
  if (!column) {
    return value;
  }

  if (typeof value === 'string') {
    if (value.includes('http')) {
      return (
        <a
          href={value}
          target="_blank"
          rel="noreferrer"
          style={{
            textDecoration: 'underline',
          }}
        >
          {value}
        </a>
      );
    }

    return value;
  }

  const formattedValue = applyFormattingRule(value, column.name);

  const foundKey = Object.keys(FORMAT_MAP).find((key) =>
    column?.name?.toLowerCase().includes(key)
  );

  if (foundKey) {
    const format = FORMAT_MAP[foundKey as SubFilterValues];

    return format.type === 'prefix'
      ? `${format.text}${formattedValue}`
      : `${formattedValue}${format.text}`;
  }

  return formattedValue;
};

export const getScreenerColumns = (
  data: TransformedScreenerEntityInfo[],
  view: ScreenerDimension,
  additionalFiltersEnabled?: boolean
) => {
  if (!data || data.length === 0) {
    return [];
  }

  const whitelist = additionalFiltersEnabled
    ? [...COLUMN_WHITELIST, ...ADDITIONAL_COLUMN_WHITELIST]
    : COLUMN_WHITELIST;

  // Filter and map the keys to COLUMN_WHITELIST
  const keys = Object.keys(data[0] || {}).filter((columnName) =>
    whitelist.some((whitelistItem) =>
      columnName.toLowerCase().startsWith(whitelistItem)
    )
  ) as ColumnKey[];

  // Group columns by their base names
  const groupedColumns = keys.reduce(
    (acc, name) => {
      const baseColumnName = name.includes('growth_yoy')
        ? 'growthYoy'
        : name.split('_')[0].trim();

      return {
        ...acc,
        [baseColumnName]: [...(acc[baseColumnName] || []), name],
      };
    },
    {} as { [key: string]: ColumnKey[] }
  );

  // Map the grouped columns to the final column definitions
  const columns = Object.entries(groupedColumns).flatMap(([key, group]) =>
    group.map((name, index) => {
      const header = formatHeader(name, view);
      const render = (cellData: { value: string | number }) =>
        renderFieldWithFormat({
          value: cellData.value,
          column: { name },
        });

      return {
        name,
        header,
        render,
        minWidth: 30,
        defaultFlex: 1,
        columnNumber: index,
        key,
      };
    })
  );

  return columns;
};
