import { Flex } from '@chakra-ui/react';
import {
  PageTitles,
  AddEntityButtonText,
  PrimaryFilters,
  Views,
  PrimaryView,
} from '@revelio/core';
import {
  AddEntityButton,
  FilterChipsContainer,
  ROLE_GRANULARITY_FILTERS,
  PrimaryFilterLimits,
  SHARED_SET_ENTITY_LIMIT,
  createSelectableFiltersMap,
  useViewFilters,
  SelectionCategories,
  SKILL_GRANULARITY_FILTERS,
  INDUSTRY_AND_COMPANY_FILTERS,
  useSelectionLists,
  FilterSets,
  ViewTypes,
  useStoredFilterSet,
  LocalSelectionCategories,
  useViewFilterDefaults,
  useSyncFiltersToSearchParams,
  useActiveFiltersState,
  COMPOSITION_GET_DATA,
  serializeFilters,
  DateRangeFormattedValues,
  useDefaultLastMonth,
  DefaultDates,
  FilterContainer,
  FilterChips,
  FilterMenuLimits,
  FilterMenu,
  GEOGRAPHY_GRANULARITY_FILTERS,
  FilterChipsLoader,
  FilterItem,
  POSTINGS_GET_ACTIVE,
  SENTIMENT_GET_SUMMARY_DATA,
  GET_ENTITY_DATA,
} from '@revelio/filtering';
import { flatten, omit } from 'lodash';
import DashboardPage from '../../DashboardPage';
import { Dimension1, Filters } from '@revelio/data-access';
import { useQuery } from 'urql';
import {
  ChartsRenderedContext,
  ChartsRenderedProvider,
} from '../shared/charts-rendered-context';
import { RoleSummaryTopSkills } from './top-skills';
import { RoleSummaryTopCompanies } from './top-companies';
import { RoleSummaryTopGeographies } from './top-geographies';
import { RoleStats } from './role-stats/role-stats';
import { useContext, useEffect, useMemo, useState } from 'react';
import { transformFiltersToPostingVariables } from '../../postings/utils';
import { RoleSummaryOverview } from './role-summary-overview';
import {
  CompositionStatsMetric,
  PostingsStatsMetric,
  SentimentMetric,
} from '../shared/stat';

const ROLE_SUMMARY_PRIMARY_VIEW_FILTER = [
  {
    filters: ROLE_GRANULARITY_FILTERS,
    isNested: true,
    limit: 1,
  },
];
const ROLE_SUMMARY_SECONDARY_FILTERS = [
  SelectionCategories.SENIORITY,
  {
    filters: SKILL_GRANULARITY_FILTERS,
    isNested: true,
    limit: 10,
  },
  {
    filters: INDUSTRY_AND_COMPANY_FILTERS,
    isNested: true,
    limit: 10,
  },
  {
    filters: GEOGRAPHY_GRANULARITY_FILTERS,
    isNested: true,
    limit: 10,
  },
];

const RoleSummary = () => {
  const primaryFilters = flatten(
    createSelectableFiltersMap(ROLE_SUMMARY_PRIMARY_VIEW_FILTER)
  );
  const selectableFiltersMap = createSelectableFiltersMap(
    ROLE_SUMMARY_SECONDARY_FILTERS
  );
  const flattenedSelectableFilters = flatten(selectableFiltersMap);

  useViewFilters([
    ...primaryFilters,
    ...flattenedSelectableFilters,
    SelectionCategories.DATE_RANGE,
    SelectionCategories.DATE_RANGE_FULL,
  ]);

  const selectionLists = useSelectionLists([
    SelectionCategories.ROLE_K1500,
    SelectionCategories.ROLE_K150,
    SelectionCategories.JOB_CATEGORY,
    ...flattenedSelectableFilters,
  ]);

  useStoredFilterSet({
    sharedSetId: FilterSets.ROLE_SUMMARY,
    tab: ViewTypes.ROLE,
    primaryEntitiesSync: true,
    limit: 1,
    defaultLimit: 1,
    filterNames: primaryFilters,
  });

  const viewFilterDefaultArgs = {
    view: Views.ENTITY_SUMMARY,
    viewType: ViewTypes.ROLE,
    presetView: FilterSets.ROLE_SUMMARY,
    onlyConsiderTheseFiltersToTriggerDefaults: [
      LocalSelectionCategories.PRIMARY_ENTITIES,
    ],
    viewFilters: [LocalSelectionCategories.PRIMARY_ENTITIES],
    limit: PrimaryFilterLimits.ENTITY_SUMMARY,
    dateKey: SelectionCategories.DATE_RANGE,
    primaryFilters,
    supportPrimaryEntities: true,
  };

  useViewFilterDefaults(viewFilterDefaultArgs);

  useSyncFiltersToSearchParams({
    primaryFilters,
    syncToPrimaryEntities: true,
  });

  const activeFilters = useActiveFiltersState();

  const serializedFilters: Filters = serializeFilters(activeFilters, {
    isCustomRoleTaxonomyEnabled: false,
  });

  useDefaultLastMonth({
    view: Views.ENTITY_SUMMARY,
    viewType: ViewTypes.GEO,
    dateType: DefaultDates.DEFAULT_LAST_MONTH,
  });
  // This is used for the active postings query in the overtime plots
  useDefaultLastMonth({
    view: Views.ENTITY_SUMMARY,
    viewType: ViewTypes.COMPANY,
    dateType: DefaultDates.LAST_START_DATE,
  });

  const dates = activeFilters.find(
    (filter) => filter.id === SelectionCategories.DATE_RANGE
  )?.value as DateRangeFormattedValues;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [{ data: compositionData, fetching: compositionLoading }] = useQuery({
    query: COMPOSITION_GET_DATA,
    variables: {
      dim1: Dimension1.Role,
      filters: {
        ...serializedFilters,
      },
    },
    pause:
      (!serializedFilters?.job_category &&
        !serializedFilters?.role_k150 &&
        !serializedFilters?.role_k1500) ||
      !dates,
  });

  const selectedRoleId = parseInt(
    (
      activeFilters.find((filter) =>
        ROLE_GRANULARITY_FILTERS.includes(filter.id as SelectionCategories)
      )?.value as FilterItem[]
    )?.[0]?.id as string,
    10
  );

  const selectedRoleType = activeFilters.find((filter) =>
    ROLE_GRANULARITY_FILTERS.includes(filter.id as SelectionCategories)
  )?.id;

  const [{ data: entityData, fetching: primaryRoleLoading }] = useQuery({
    query: GET_ENTITY_DATA,
    variables: {
      filters: {
        [selectedRoleType as SelectionCategories]: [selectedRoleId],
      },
    },
    pause: !selectedRoleId,
  });

  const commonTitles = useMemo(
    () =>
      selectionLists
        ?.find((list) => list.id === selectedRoleType)
        ?.value?.find((entity) => entity.id == selectedRoleId)
        ?.topCleanedTitles || '',
    [selectionLists, selectedRoleType, selectedRoleId]
  );

  const queryFilters = useMemo(
    () =>
      transformFiltersToPostingVariables({
        view: PrimaryView.ROLE,
        filters: [...activeFilters],
        isCustomRoleTaxonomyEnabled: false,
      }),
    [activeFilters]
  );

  const postingsFiltersWithCompetitors = useMemo(
    () => ({
      ...queryFilters,
      filters: {
        ...queryFilters.filters,
        // provider: [9], //todo what is this? do we need?
        metric_mode: 'expected_hires',
        [selectedRoleType as SelectionCategories]: [selectedRoleId],
      },
    }),
    [selectedRoleType, selectedRoleId, queryFilters]
  );

  const [{ data: postingsActiveData, fetching: postingsLoading }] = useQuery({
    query: POSTINGS_GET_ACTIVE,
    variables: postingsFiltersWithCompetitors,
    pause: !entityData,
  });

  const [{ data: sentimentData, fetching: sentimentLoading }] = useQuery({
    query: SENTIMENT_GET_SUMMARY_DATA,
    variables: {
      dim1: Dimension1.Role,
      filters: omit(serializedFilters, [
        // sentiment does not support seniority or skills filters
        SelectionCategories.SENIORITY,
        ...SKILL_GRANULARITY_FILTERS,
      ]),
    },
    pause: !entityData,
  });

  const [firstLoad, setFirstLoad] = useState(false);

  const allLoadingStates = useMemo(
    () => [
      compositionLoading,
      postingsLoading,
      sentimentLoading,
      primaryRoleLoading,
    ],
    [compositionLoading, postingsLoading, sentimentLoading, primaryRoleLoading]
  );

  useEffect(() => {
    if (firstLoad && allLoadingStates.some((loading) => loading)) {
      setFirstLoad(false);
    }

    if (firstLoad && allLoadingStates.every((loading) => !loading)) {
      setFirstLoad(false);
    }
  }, [firstLoad, allLoadingStates]);

  const context = useContext(ChartsRenderedContext);
  if (!context) {
    throw new Error(
      'chartRenderedContext must be used within a ChartsRenderedProvider'
    );
  }
  const { allChartsRendered } = context;
  const anyRequestsLoading = useMemo(() => {
    const allLoadingComplete =
      allLoadingStates.every((loading) => !loading) && allChartsRendered;

    return !allLoadingComplete || firstLoad;
  }, [allLoadingStates, firstLoad, allChartsRendered]);
  return (
    <DashboardPage
      title={[PageTitles.ROLE, PageTitles.SUMMARY]}
      hideSelectionsMargins
      loading={anyRequestsLoading}
      selections={
        <Flex
          justifyContent="flex-start"
          alignItems="center"
          flexDirection="row"
        >
          <FilterChipsContainer
            filterNames={primaryFilters}
            variant="filterChip"
            isPrimaryChip
            useChipsSkeleton={false}
            min={1}
            limit={PrimaryFilterLimits.ROLE_SUMMARY}
            addButton={
              <AddEntityButton
                entities={ROLE_SUMMARY_PRIMARY_VIEW_FILTER}
                entityName={AddEntityButtonText[PrimaryFilters.ROLE]}
                buttonText={AddEntityButtonText[PrimaryFilters.ROLE]}
                activeLimit={PrimaryFilterLimits.ROLE_SUMMARY}
                limit={SHARED_SET_ENTITY_LIMIT}
                required={1}
              />
            }
          />
        </Flex>
      }
    >
      <FilterContainer
        flexDirection="row"
        alignItems="flex-start"
        justifyContent={'space-between'}
      >
        <Flex
          justifyContent="flex-start"
          alignItems="flex-start"
          flexDirection="row"
          wrap="wrap"
          rowGap="0.5rem"
        >
          <FilterChipsLoader loading={anyRequestsLoading}>
            <FilterChips
              filterNames={ROLE_SUMMARY_SECONDARY_FILTERS}
              variant="filterChip"
              limit={FilterMenuLimits.ENTITY_SUMMARY}
              showGranularity={true}
              viewType={ViewTypes.ROLE}
              useChipsSkeleton={false}
              addButton={
                <FilterMenu
                  title="Filter"
                  filters={ROLE_SUMMARY_SECONDARY_FILTERS}
                  selectMenuOpenDefault
                  limit={FilterMenuLimits.ENTITY_SUMMARY}
                  showFilterSetSaveMenu={false}
                  viewIdForDefault={`${Views.ENTITY_SUMMARY}_${ViewTypes.ROLE}`}
                />
              }
            />
          </FilterChipsLoader>
        </Flex>
      </FilterContainer>
      <Flex width="full" height="full" flexDirection="column" gap={4}>
        <Flex
          width="full"
          justifyContent="space-between"
          flexBasis="40%"
          gap={4}
        >
          <Flex flexBasis="67%" flexDirection="column">
            {anyRequestsLoading ? null : ( // this is to prevent gql cached text overlapping loading spinner when navigating between pages
              <RoleSummaryOverview
                description={entityData?.entity?.summary?.description}
                roleTitle={entityData?.entity?.summary?.name}
                commonTitles={commonTitles}
              />
            )}
          </Flex>
          <Flex flexBasis="33%" flexDirection="column">
            <RoleStats
              compositionData={compositionData}
              selectedRoleId={selectedRoleId}
              postingsActiveData={postingsActiveData}
              sentimentData={sentimentData}
            />
          </Flex>
        </Flex>
        <Flex
          width="full"
          justifyContent="space-between"
          flexBasis="60%"
          gap={4}
        >
          <Flex flexBasis="33%" flexDirection="column" height="full">
            <RoleSummaryTopSkills data={compositionData} />
          </Flex>
          <Flex flexBasis="33%" flexDirection="column" height="full">
            <RoleSummaryTopGeographies data={compositionData} />
          </Flex>
          <Flex flexBasis="33%" flexDirection="column" height="full">
            <RoleSummaryTopCompanies data={compositionData} />
          </Flex>
        </Flex>
      </Flex>
    </DashboardPage>
  );
};

export const RoleSummaryWithChartRenderingProvider = () => (
  // update correct chartNames, or remove if not needed
  <ChartsRenderedProvider
    chartNames={[
      CompositionStatsMetric.Headcount,
      CompositionStatsMetric.GrowthRate,
      PostingsStatsMetric.ActivePostings,
      SentimentMetric.OverallRating,
    ]}
  >
    <RoleSummary />
  </ChartsRenderedProvider>
);
