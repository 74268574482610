import {
  FeatureFlag,
  PageTitles,
  PrimaryFilters,
  PrimaryView,
} from '@revelio/core';
import {
  FilterMenuLimits,
  FilterSets,
  GEOGRAPHY_GRANULARITY_FILTERS,
  LocalSelectionCategories,
  PrimaryFilterLimits,
  ROLE_GRANULARITY_FILTERS,
  SKILL_GRANULARITY_FILTERS,
  SelectionCategories,
  ViewTypes,
} from '@revelio/filtering';
import Overview from '../../../overview/overview';
import { useFlag } from '@unleash/proxy-client-react';

const title = [PageTitles.ROLE, PageTitles.COMPOSITIONS];

const primaryViewFilters = [
  {
    filters: ROLE_GRANULARITY_FILTERS,
    isNested: true,
  },
];

export function RoleCompositions() {
  const keywordFiltersFeatureFlag = useFlag(FeatureFlag.RoleCompositionKeyword);

  const SHARED_SELECTABLE_FILTERS = [
    SelectionCategories.HIGHEST_DEGREE,
    SelectionCategories.ETHNICITY,
    SelectionCategories.INDUSTRY,
    SelectionCategories.GENDER,

    {
      filters: GEOGRAPHY_GRANULARITY_FILTERS,
      isNested: true,
      limit: 10,
    },

    {
      filters: SKILL_GRANULARITY_FILTERS,
      isNested: true,
      limit: 10,
    },

    SelectionCategories.SENIORITY,
    ...(keywordFiltersFeatureFlag
      ? [SelectionCategories.KEYWORD, SelectionCategories.RAW_TITLE]
      : []),
    LocalSelectionCategories.DATA_METRIC as unknown as SelectionCategories,
  ];
  const selectableFilters = {
    snapshot: [SelectionCategories.SNAPSHOT_DATE, ...SHARED_SELECTABLE_FILTERS],
    overtime: [SelectionCategories.DATE_RANGE, ...SHARED_SELECTABLE_FILTERS],
  };
  return (
    <Overview
      title={title}
      primaryView={PrimaryView.ROLE}
      viewType={ViewTypes.ROLE}
      primaryFilter={PrimaryFilters.ROLE}
      primaryViewFilterLimit={PrimaryFilterLimits.ROLE_OVERVIEW}
      filterMenuLimit={FilterMenuLimits.ROLE_OVERVIEW}
      primaryViewFilters={primaryViewFilters}
      selectableFilters={selectableFilters}
      sharedFilterSetId={FilterSets.ROLE}
      filterSet={FilterSets.ROLE_COMPOSITIONS}
    />
  );
}

export default RoleCompositions;
