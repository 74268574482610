import { useQuery } from 'urql';

import {
  Filter,
  FilterItem,
  FilterList,
  GET_COMPANY_SUMMARY_MAP_DATA,
  SelectionCategories,
  serializeTDFilters,
  SkillFilter,
  SkillLevel,
  talentDiscoveryFilterTransformation,
  useActiveFiltersState,
} from '@revelio/filtering';
import { TalentDiscoveryV1_5Filter } from '@revelio/data-access';
import { useCallback, useEffect, useState } from 'react';
import { fetchSubsidiaries } from '../../../deliverables/company-selection/subsidiary-mapping/subsidiary-mapping.api';
import produce from 'immer';

export const useGetCompanyMapData = () => {
  const activeFilters = useActiveFiltersState();
  const numOfFilterSelections = activeFilters.length;
  const selectedCompanyRCID = parseInt(
    (
      activeFilters.find((filter) => filter.id === SelectionCategories.COMPANY)
        ?.value as FilterItem[]
    )?.[0]?.id as string,
    10
  );
  const [companySubsidiaries, setCompanySubsidiaries] = useState<number[]>([]);
  const [subsidiariesLoaded, setSubsidiariesLoaded] = useState(false);
  const [skillFilters, setSkillFilters] = useState<SkillFilter[]>([]);

  useEffect(() => {
    const activeK75FilterValues = (
      (activeFilters?.find((filter) => filter.id === 'skill_k75')
        ?.value as FilterList) ?? []
    ).map((val) => ({
      skillLevel: SkillLevel.SkillK75,
      id: val.id as number,
    }));

    const activeK700FilterValues = (
      (activeFilters?.find((filter) => filter.id === 'skill_k700')
        ?.value as FilterList) ?? []
    ).map((val) => ({
      skillLevel: SkillLevel.SkillK700,
      id: val.id as number,
    }));

    const activeK3000FilterValues = (
      (activeFilters?.find((filter) => filter.id === 'skill_k3000')
        ?.value as FilterList) ?? []
    ).map((val) => ({
      skillLevel: SkillLevel.SkillK3000,
      id: val.id as number,
    }));

    setSkillFilters([
      ...activeK75FilterValues,
      ...activeK700FilterValues,
      ...activeK3000FilterValues,
    ]);
  }, [activeFilters]);

  useEffect(() => {
    const fetchSubs = async () => {
      setSubsidiariesLoaded(false);
      const subs = await fetchSubsidiaries(selectedCompanyRCID);
      setCompanySubsidiaries(subs.map((sub) => sub.rcid));
      setSubsidiariesLoaded(true);
    };
    if (selectedCompanyRCID) {
      fetchSubs();
    }
  }, [selectedCompanyRCID]);

  const getActiveFiltersWithSubsidiariesAndWithoutDate = useCallback(
    (filters: Filter[]) => {
      return produce(filters, (draftFilters) => {
        // remove date filters
        const dateRangeFilterIndex = draftFilters.findIndex(
          (todo) => todo.id === SelectionCategories.DATE_RANGE
        );
        if (dateRangeFilterIndex !== -1) {
          draftFilters.splice(dateRangeFilterIndex, 1);
        }
        const dateRangeFullFilterIndex = draftFilters.findIndex(
          (todo) => todo.id === SelectionCategories.DATE_RANGE_FULL
        );
        if (dateRangeFullFilterIndex !== -1) {
          draftFilters.splice(dateRangeFullFilterIndex, 1);
        }

        // add subsidiaries to company filter
        const companyFilterIndex = draftFilters.findIndex(
          (todo) => todo.id === SelectionCategories.COMPANY
        );
        if (companyFilterIndex !== -1) {
          const companyFilter = draftFilters[companyFilterIndex];
          const { shortName, id } = (companyFilter.value as FilterList)[0];
          companyFilter.value = [
            {
              data: {
                hasSubsidiaries: !!companySubsidiaries.length,
                shortName: shortName,
                rcid: Number(id),
              },
              id: Number(id),
              label: shortName,
            },
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ] as any;

          if (companySubsidiaries.length) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ((companyFilter.value as FilterList)[0] as any).subsidiaryRCIDs =
              companySubsidiaries;
          }
        }
      });
    },
    [companySubsidiaries]
  );

  const [{ data, fetching }] = useQuery({
    query: GET_COMPANY_SUMMARY_MAP_DATA,
    variables: {
      query: {
        ...(talentDiscoveryFilterTransformation({
          skillFilters: [skillFilters],
          filters: serializeTDFilters(
            getActiveFiltersWithSubsidiariesAndWithoutDate(activeFilters)
          ),
        }) as TalentDiscoveryV1_5Filter),
      },
    },
    pause:
      numOfFilterSelections === 0 ||
      !selectedCompanyRCID ||
      !subsidiariesLoaded,
  });

  return {
    data: numOfFilterSelections > 0 ? data : undefined,
    loading: fetching,
  };
};
