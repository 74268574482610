import { ArrowBackIcon, ChevronUpIcon } from '@chakra-ui/icons';
import {
  Button,
  ButtonGroup,
  ButtonProps,
  Flex,
  FlexProps,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
type CustomProps = {
  containerProps?: FlexProps;
  submitButtonProps?: ButtonProps;
  cancelButtonProps?: ButtonProps;
};

/* eslint-disable-next-line */
export interface ActionModalControlPanelProps {
  submitText?: string;
  resetText?: string;
  backText?: string;
  cancelText?: string;
  onClose: () => void;
  onSubmit: () => void;
  onCancel?: () => void;
  onReset?: () => void;
  onBack?: () => void;
  menuHandlers?: { text: string; handler: () => void }[];
  handlersAreDisabled?: boolean;
  submitIsDisabled?: boolean;
  submitIsLoading?: boolean;
  includeReset?: boolean;
  includeBack?: boolean;
  customProps?: CustomProps;
  additionalCTA?: React.ReactNode;
  isInsideButton?: boolean;
}
export function ActionModalControlPanel({
  submitText = 'Submit',
  backText = 'Back',
  resetText = 'Reset',
  cancelText = 'Cancel',
  onClose,
  onSubmit,
  onCancel,
  onReset,
  onBack,
  menuHandlers,
  handlersAreDisabled,
  submitIsDisabled,
  submitIsLoading,
  includeReset = true,
  includeBack = false,
  customProps = {},
  additionalCTA,
  isInsideButton = false,
  ...props
}: ActionModalControlPanelProps) {
  const {
    containerProps = {},
    submitButtonProps = {},
    cancelButtonProps = {},
  } = customProps;

  const isMenuDisabled =
    handlersAreDisabled === undefined ? submitIsDisabled : handlersAreDisabled;

  const SubmitButtonOrGroup =
    menuHandlers && menuHandlers.length ? (
      <ButtonGroup size="sm" isAttached variant="outline">
        <Button
          {...(isInsideButton && { as: 'div' })}
          borderRadius="4px"
          fontSize="12px"
          fontWeight="600"
          colorScheme="green"
          variant="solid"
          size="sm"
          isLoading={submitIsLoading}
          isDisabled={submitIsDisabled}
          onClick={onSubmit}
          {...submitButtonProps}
          data-testid={`${submitText}-submit`}
        >
          {submitText}
        </Button>
        <Menu placement="top-start">
          <MenuButton
            as={IconButton}
            icon={<ChevronUpIcon />}
            borderRadius="4px"
            fontSize="20px"
            fontWeight="600"
            colorScheme="green"
            variant="solid"
            borderLeft="2px"
            borderColor="white"
            size="sm"
            isDisabled={isMenuDisabled}
            data-testid="submit-menu-dropdown"
          />
          <MenuList>
            {menuHandlers?.map((menuItem, index) => (
              <MenuItem
                key={index}
                onClick={menuItem.handler}
                color="text.primary"
                fontSize="12px"
                data-testid={menuItem.text}
              >
                {menuItem.text}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </ButtonGroup>
    ) : (
      <Button
        {...(isInsideButton && { as: 'div' })}
        borderRadius="4px"
        fontSize="12px"
        fontWeight="600"
        colorScheme="green"
        variant="solid"
        size="sm"
        isLoading={submitIsLoading}
        isDisabled={submitIsDisabled}
        onClick={onSubmit}
        {...submitButtonProps}
        data-testid={`${submitText}-submit`}
      >
        {submitText}
      </Button>
    );

  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      {...containerProps}
    >
      {includeBack && (
        <Button
          {...(isInsideButton && { as: 'div' })}
          leftIcon={<ArrowBackIcon boxSize={3.5} />}
          sx={{
            '.chakra-button__icon': {
              marginInlineEnd: '0.25rem',
              marginTop: '-1px',
            },
          }}
          variant="moreSlim"
          size="sm"
          fontSize="12px"
          onClick={onBack}
        >
          {backText}
        </Button>
      )}
      {includeReset && !includeBack && (
        <Button
          {...(isInsideButton && { as: 'div' })}
          variant="ghost"
          size="sm"
          fontSize="12px"
          colorScheme="red"
          onClick={onReset}
        >
          {resetText}
        </Button>
      )}
      <ButtonGroup spacing={4}>
        <Button
          {...(isInsideButton && { as: 'div' })}
          variant="link"
          fontSize="12px"
          colorScheme="gray"
          onClick={() => {
            const providedHandler = onCancel || onClose;
            providedHandler?.();
          }}
          {...cancelButtonProps}
        >
          {cancelText}
        </Button>

        {additionalCTA}

        {SubmitButtonOrGroup}
      </ButtonGroup>
    </Flex>
  );
}

export default ActionModalControlPanel;
