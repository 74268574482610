import { FeatureFlag, PageTitles } from '@revelio/core';
import { SelectionCategories } from '@revelio/filtering';
import { Screener } from '../../../screener/screener';
import { generateFilterOptions } from '../../utils/generateFilterOptions';
import { ScreenerDimension } from '@revelio/data-access';
import { useUnleashFlag } from '../../../../hooks/unleash/useUnleashFlag';

const title = [PageTitles.COMPANY, PageTitles.SCREENER];

export const CompanyScreener = () => {
  const additionalFiltersEnabled = useUnleashFlag(
    FeatureFlag.CompanyScreenerAdditionalFilters
  );
  const filterOptions = generateFilterOptions(
    SelectionCategories.INDUSTRY,
    undefined,
    additionalFiltersEnabled
  );

  return (
    <Screener
      title={title}
      filterOptions={filterOptions}
      view={ScreenerDimension.Company}
      sortingDisabledColumns={[
        'Company Name',
        'Description',
        'Email',
        'Funding Stage',
        'Public Company',
        'Last Funding Amount',
        'Last Funding Year',
        'Total Funding Amount',
        'Valuation',
        'Year Founded',
        'Founder Gender',
        'Founder Ethnicity',
        'HQ Location',
        'Website',
        'LinkedIn URL',
        'Phone Number',
      ]}
    />
  );
};

export default CompanyScreener;
