import { PageTitles } from '@revelio/core';
import { SelectionCategories } from '@revelio/filtering';
import { Screener } from '../../../screener/screener';
import { generateFilterOptions } from '../../utils/generateFilterOptions';
import { ScreenerDimension } from '@revelio/data-access';

const title = [PageTitles.GEOGRAPHY, PageTitles.SCREENER];
const filterOptions = generateFilterOptions(
  SelectionCategories.REGION,
  'Geography'
);

export const GeographyScreener = () => {
  return (
    <Screener
      title={title}
      filterOptions={filterOptions}
      view={ScreenerDimension.Geography}
      sortingDisabledColumns={['Metro Area']}
    />
  );
};

export default GeographyScreener;
