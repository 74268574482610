import {
  concat,
  forkJoin,
  iif,
  lastValueFrom,
  map,
  Observable,
  of,
} from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { fromFetch } from 'rxjs/fetch';
import {
  getEntitiesCountByPredicate,
  selectAllEntities,
  upsertEntities,
} from '@ngneat/elf-entities';
import { StateStorage } from '@ngneat/elf-persist-state';
import { authStore, AuthStoreRootProps, isResponseOk } from '@revelio/auth';
import { API_ROOT } from './filters.constants';
import {
  EnumeratedFilters,
  FilterTypes,
  RequestMethod,
  StoredFilterSet,
  UserStoredFilterSet,
  ValidValueTypes,
} from './filters.model';
import {
  filterStore,
  getActiveSet,
  storedFilterSetEntitiesRef,
} from './filters.core';
import { get, mapValues } from 'lodash';
import { buildFilters, formatAndBreakoutFilters } from './filters.serialize';
import { API_READY } from '@revelio/core';

export enum PersistMethods {
  GET_USER_PRESETS = 'get_user_presets',
  GET_PRESET = 'get_preset',
  CREATE_PRESET = 'create_preset',
  DELETE_PRESET = 'delete_preset',
  UPDATE_PRESET = 'update_preset',
}

interface IPersistConfig {
  path?: string;
  method: RequestMethod;
}

interface IServerPreset {
  name: string;
  default: boolean;
  meta?: { [key: string]: any };
  filters: EnumeratedFilters<ValidValueTypes | ValidValueTypes[]>;
}

interface IPersistOptions {
  presetId?: string;
  body?: IServerPreset;
}

const PersistMethodsLookup: { [key in PersistMethods]: IPersistConfig } = {
  [PersistMethods.GET_USER_PRESETS]: {
    method: RequestMethod.GET,
  },
  [PersistMethods.GET_PRESET]: {
    method: RequestMethod.GET,
  },
  [PersistMethods.CREATE_PRESET]: {
    method: RequestMethod.POST,
  },
  [PersistMethods.UPDATE_PRESET]: {
    method: RequestMethod.PUT,
  },
  [PersistMethods.DELETE_PRESET]: {
    method: RequestMethod.DELETE,
  },
};

export const getPersistedPresets = makePersistCall(
  PersistMethods.GET_USER_PRESETS
).pipe(
  switchMap((results) => {
    return forkJoin<{ preset: IServerPreset }[]>(
      get(results, 'preset_list', []).reduce(
        (builtList: { [key: string]: Observable<any> }, l: { id: string }) => {
          builtList[l.id] = makePersistCall(PersistMethods.GET_PRESET, {
            presetId: l.id,
          });
          return builtList;
        },
        {}
      )
    );
  }),
  map((presetListWithDetails) => {
    const { user }: AuthStoreRootProps = authStore.getValue();
    const filtSetIds: string[] = [];
    const hydratedPreset = mapValues(
      presetListWithDetails,
      (
        {
          preset: { filters, name, meta, default: isDefault },
        }: { preset: IServerPreset },
        key
      ) => {
        filtSetIds.push(key);

        return {
          id: key,
          name,
          label: name,
          view: get(meta, 'view'),
          lastModified: get(meta, 'lastModified', new Date()),
          creator: get(user, 'username'),
          entities: buildFilters(filters, meta),
          isDefault,
        };
      }
    );
    return {
      storedFilterSetEntities: hydratedPreset,
      storedFilterSetIds: filtSetIds,
    };
  })
);

const storage = {
  getItem(key: string): any {
    const [selector, ref] = [
      (e: any) => get(e, 'creator'),
      { ref: storedFilterSetEntitiesRef },
    ];
    return iif(
      () => {
        const filterSetsLoaded = filterStore.query(
          getEntitiesCountByPredicate(selector, ref)
        );
        return filterSetsLoaded > 0;
      },
      filterStore.pipe(
        selectAllEntities(ref),
        map((filterSets: StoredFilterSet[]) => {
          return {
            storedFilterSetEntities: filterSets,
            storedFilterSetIds: filterSets.map((v) => v.id),
          };
        })
      ),
      getPersistedPresets
    );
  },
};

function makePersistCall<T = any>(
  operation: PersistMethods,
  options: IPersistOptions = {}
) {
  const { presetId, body } = options;
  const config = PersistMethodsLookup[operation];
  const path = presetId ? `/${presetId}` : '';
  return API_READY.pipe(
    switchMap(() => {
      return fromFetch<T>(`${API_ROOT}/api/presets${path}`, {
        method: config.method,
        credentials: 'include',
        body: body && JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
        },
        selector: (response) => {
          return isResponseOk({ response });
        },
      });
    })
  );
}

export const refreshFilterSetStorage = () => {
  const [predicate, ref] = [
    (e: any) => get(e, 'creator'),
    { ref: storedFilterSetEntitiesRef },
  ];

  const filterSetsLoaded = filterStore.query(
    getEntitiesCountByPredicate(predicate, ref)
  );

  const setsLoaded = filterSetsLoaded > 0;

  if (!setsLoaded) {
    getPersistedPresets.pipe(take(1)).subscribe({
      next: (persistedSets) => {
        if (persistedSets) {
          const { storedFilterSetEntities = {} } = persistedSets;

          const entitiesToUpsert = Object.values(storedFilterSetEntities);

          if (entitiesToUpsert.length > 0) {
            filterStore.update(
              // TODO: jbellizzi - fix to align with filterStore
              upsertEntities(
                Object.values(
                  storedFilterSetEntities
                ) as unknown as Partial<StoredFilterSet>[],
                ref
              )
            );
          }
        }
      },
      error: (e) => {
        console.error('Preset Error:', e);
      },
    });
  }
};

interface SaveStoredFilterSetToRemoteProps {
  setEntry: Partial<UserStoredFilterSet>;
  updateView?: boolean;
  isDefault?: boolean;
  mode?: PersistMethods.CREATE_PRESET | PersistMethods.UPDATE_PRESET;
}
export async function saveStoredFilterSetToRemote({
  setEntry,
  updateView = true,
  isDefault = false,
  mode = PersistMethods.CREATE_PRESET,
}: SaveStoredFilterSetToRemoteProps) {
  const { name = '', entities = [], id, view: currentEntryView } = setEntry;
  const { formattedFilter } = formatAndBreakoutFilters(entities, []);
  const filterSelectionListMap = entities.reduce(
    (lookup, f) => {
      if (f.type == FilterTypes.SELECT && f.selectionListId) {
        lookup[f.id] = f.selectionListId;
      }
      return lookup;
    },
    {} as { [key: string]: string }
  );
  const activeSet = filterStore.query(getActiveSet);
  const toSend = {
    name: name,
    default: isDefault,
    meta: {
      view: updateView ? activeSet : currentEntryView,
      filterSelectionListMap: filterSelectionListMap,
    },
    filters: formattedFilter,
  };

  const serverOperations = [];

  const presetId = mode === PersistMethods.UPDATE_PRESET ? id : undefined;
  serverOperations.push(makePersistCall(mode, { body: toSend, presetId }));

  const result = await lastValueFrom(concat(...serverOperations));

  return result;
}

export function deleteStoredFilterSetFromRemote(id: string) {
  return makePersistCall(PersistMethods.DELETE_PRESET, { presetId: id });
}

export function createRevelioStorage(): StateStorage {
  return {
    getItem(key: string) {
      return storage.getItem(key);
    },
    setItem(key: string, value: Record<string, any>) {
      // NOTE: this is done on filterSet upsert on an individual set since that's how the server works
      return of(true);
    },
    removeItem(key: string) {
      // NOTE: this is done on filterSet delete on an individual set since that's how the server works
      return of(true);
    },
  };
}
