import { Box, Divider, Flex, Text } from '@chakra-ui/layout';
import { GetEntityDataQuery } from '@revelio/data-access';
import { Card } from '@revelio/layout';
import { useEffect, useRef, useState } from 'react';
import { PlotInfoComp } from '@revelio/composed';
import { Textfit } from 'react-textfit';

type EntitySummary = NonNullable<
  NonNullable<GetEntityDataQuery['entity']>['summary']
>;

interface RoleSummaryOverviewProps {
  description: EntitySummary['description'];
  roleTitle: EntitySummary['name'];
  commonTitles?: string;
}

export const RoleSummaryOverview = ({
  description,
  roleTitle,
  commonTitles,
}: RoleSummaryOverviewProps) => {
  const factSize = '85px';
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);

  /** ======== Resize Observer ======== */
  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const resizeObserver = new ResizeObserver(() => {
      const { width, height } = container.getBoundingClientRect();
      setWidth(width);
      setHeight(height);
    });
    resizeObserver.observe(container);
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <Card p="12px 20px">
      <Flex height="100%" flexDirection="column" justifyContent="space-between">
        <Box height={`calc(100% - ${factSize})`} ref={containerRef}>
          <Flex mb={3}>
            <Text fontWeight={600} fontSize={14}>
              {roleTitle}
            </Text>
            <PlotInfoComp
              plotInfoBody={
                <Text color="white">
                  Descriptions are sourced from AI generated text.
                </Text>
              }
              plotInfoConfig={{
                popoverPlacement: 'right',
                iconBoxSize: 2.5,
              }}
            />
          </Flex>
          <Textfit
            mode="multi"
            min={10}
            max={200}
            style={{
              height: '90%',
              lineHeight: '1.2',
            }}
            key={`${width}x${height}`} // Force remount on window size change
          >
            {description}
          </Textfit>
        </Box>

        <Box height={factSize}>
          <Divider my={4} />
          <Flex>
            <Text
              fontSize={14}
              title={commonTitles || ''}
              sx={{
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2, // Limits to 2 lines and adds ellipsis
              }}
            >
              <b>Common Titles:</b> {commonTitles}
            </Text>
          </Flex>
        </Box>
      </Flex>
    </Card>
  );
};
