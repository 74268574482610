import { MonthlyLineChart } from '../../../../shared/monthly-line-chart-with-has-rendered-context';

import { RoleStatsProps } from '../types';
import { Text } from '@chakra-ui/react';
import {
  calculateStatValues,
  CompositionStatsMetric,
  Stat,
} from '../../../../shared/stat';

const percentise = (value: number) => `${(value * 100).toFixed(1)}%`;

interface GrowthChartProps {
  compositionData: RoleStatsProps['compositionData'];
  selectedRoleId: RoleStatsProps['selectedRoleId'];
  roleName: string | null | undefined;
}
export const GrowthChart = ({
  compositionData,
  selectedRoleId,
  roleName,
}: GrowthChartProps) => {
  const primaryRoleCompositionData = compositionData?.composition?.find(
    (datum) => datum?.metadata?.id === selectedRoleId
  );

  const { lastValue, percentageChange } = calculateStatValues(
    primaryRoleCompositionData?.metrics?.[CompositionStatsMetric.GrowthRate]
      ?.timeseries
  );

  return (
    <Stat
      name={CompositionStatsMetric.GrowthRate}
      plotInfo={
        <Text color="white">
          The growth rate is the percent change in the total workforce. It is
          the difference between the hiring rate and attrition rate.
        </Text>
      }
      header="Growth"
      statValues={{
        primaryValue: percentise(lastValue),
        percentChange: percentageChange,
      }}
    >
      <MonthlyLineChart
        name={CompositionStatsMetric.GrowthRate}
        shortName={roleName}
        timeSeries={
          primaryRoleCompositionData?.metrics?.growth_rate?.timeseries
        }
        chartProps={{
          ttPosition: 'left',
        }}
        type="Role"
      />
    </Stat>
  );
};
