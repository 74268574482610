import { Button, ButtonGroup, Flex } from '@chakra-ui/react';

export const SelectFooter = ({
  onClose,
  onClearSelections,
  onAdd = () => undefined,
  addLabel = 'Add',
  isAddDisabled = false,
}: {
  onClose: () => void;
  onClearSelections?: () => void;
  onAdd?: () => void;
  isAddDisabled?: boolean;
  addLabel?: string;
}) => {
  return (
    <Flex justifyContent="space-between">
      {onClearSelections && (
        <Button
          variant="ghost"
          size="sm"
          fontSize="xs"
          colorScheme="red"
          onClick={onClearSelections}
        >
          Clear Selections
        </Button>
      )}
      <ButtonGroup
        width="full"
        spacing={4}
        justifyContent="flex-end"
        alignItems="center"
      >
        <Button
          variant="link"
          size="sm"
          fontSize={'xs'}
          fontWeight={600}
          colorScheme="gray"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          isDisabled={isAddDisabled}
          colorScheme="green"
          variant="solid"
          size="sm"
          fontSize={'xs'}
          onClick={onAdd}
        >
          {addLabel}
        </Button>
      </ButtonGroup>
    </Flex>
  );
};
