import { RoleStatsProps } from '../types';
import { Text } from '@chakra-ui/react';
import { MonthlyLineChartProps } from '@revelio/d3';
import { MonthlyLineChart } from '../../../../shared/monthly-line-chart-with-has-rendered-context';
import {
  Stat,
  SentimentMetric,
  calculateStatValues,
} from '../../../../shared/stat';

interface SentimentChartProps {
  sentimentData: RoleStatsProps['sentimentData'];
  selectedRoleId: RoleStatsProps['selectedRoleId'];
  roleName: string | null | undefined;
}
export const SentimentChart = ({
  selectedRoleId,
  roleName,
  sentimentData,
}: SentimentChartProps) => {
  const primarySentimentData = sentimentData?.sentiment2d?.find(
    (datum) => datum?.metadata?.id === selectedRoleId
  );
  const primaryRoleCategories = primarySentimentData?.category || [];

  const sentimentTimeseries: MonthlyLineChartProps['timeSeries'] =
    primaryRoleCategories?.map((datum) => ({
      id: datum?.metadata?.id,
      value: datum?.metrics?.overallRating,
      date: datum?.metadata?.shortName,
      count: datum?.metrics?.overallRatingCount,
    })) || [];

  const { lastValue, percentageChange } =
    calculateStatValues(sentimentTimeseries);

  return (
    <Stat
      name={SentimentMetric.OverallRating}
      plotInfo={
        <Text color="white">
          The average overall sentiment score reported by workers
        </Text>
      }
      header="Sentiment"
      statValues={{
        primaryValue: lastValue.toFixed(2),
        percentChange: percentageChange,
      }}
    >
      <MonthlyLineChart
        name={SentimentMetric.OverallRating}
        shortName={roleName}
        timeSeries={sentimentTimeseries}
        chartProps={{
          ttType: 'percent',
          ttMainFormat: '.2f',
          ttSecondaryFormat: ',',
          ttPosition: 'left',
        }}
        type="Role"
      />
    </Stat>
  );
};
