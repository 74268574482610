import {
  Input,
  List,
  ListItem,
  PopoverBody,
  PopoverFooter,
  PopoverHeader,
} from '@chakra-ui/react';
import styles from './select-filter.module.scss';
import { ChangeEventHandler, Dispatch, SetStateAction, useState } from 'react';
import { ScreenerFilterOption } from './types';
import { SelectFooter } from './select-footer';

export const SelectFilter = ({
  filterOptions,
  setSelectedFilter,
  closeMenu,
}: {
  filterOptions: ScreenerFilterOption[];
  setSelectedFilter: Dispatch<SetStateAction<ScreenerFilterOption | null>>;
  closeMenu: () => void;
}) => {
  const [search, setSearch] = useState('');
  const handleSearch: ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearch(e.target.value);
  };
  const filteredFilterOptions = filterOptions.filter((option) =>
    option.label.toLowerCase().includes(search.toLowerCase())
  );

  const handleFilterSelect = (filter: ScreenerFilterOption) => {
    setSelectedFilter(filter);
  };

  return (
    <>
      <PopoverHeader padding={0} borderBottomWidth={0}>
        <Input
          placeholder="Select a filter"
          size="sm"
          className={styles.input}
          onChange={handleSearch}
        />
      </PopoverHeader>
      <PopoverBody padding={0}>
        <List
          paddingY={2}
          border="1px solid #E5EBF1"
          borderTopWidth={0}
          overflowY="scroll"
          maxHeight="400px"
        >
          {filteredFilterOptions.length === 0 && (
            <ListItem paddingY={2} textAlign={'center'} opacity={0.5}>
              No options
            </ListItem>
          )}
          {filteredFilterOptions.map((option, index) => (
            <ListItem
              key={index}
              cursor={'pointer'}
              onClick={() => handleFilterSelect(option)}
              paddingX={3}
              paddingY={1.5}
              _hover={{ bg: 'gray.100' }}
              data-testid="select-filter-option"
            >
              {option.label}
            </ListItem>
          ))}
        </List>
      </PopoverBody>
      <PopoverFooter padding={'8px 0 0'} borderTopWidth={0}>
        <SelectFooter onClose={closeMenu} isAddDisabled />
      </PopoverFooter>
    </>
  );
};
