/* eslint-disable react-hooks/exhaustive-deps */

import React, {
  useEffect,
  useState,
  useRef,
  LegacyRef,
  useImperativeHandle,
  ReactNode,
  useContext,
} from 'react';
import { FixedSizeTree } from 'react-vtree';
import type { TreeWalker } from 'react-vtree';
import { useUpdate } from 'react-use';
import {
  pipe,
  Observable,
  of,
  Subject,
  merge,
  ReplaySubject,
  BehaviorSubject,
  combineLatest,
} from 'rxjs';
import {
  tap,
  map,
  distinctUntilChanged,
  switchMap,
  debounceTime,
  withLatestFrom,
  filter,
  startWith,
} from 'rxjs/operators';
import { useEffect$ } from '@ngneat/react-rxjs';
import {
  Box,
  Input,
  Button,
  InputGroup,
  InputRightElement,
  useToast,
  forwardRef,
  useOutsideClick,
  ButtonGroup,
  Text,
} from '@chakra-ui/react';
import { CloseIcon, SearchIcon } from '@chakra-ui/icons';
import { cx } from '@chakra-ui/utils';
import {
  get,
  isEmpty,
  isEqual,
  isString,
  isUndefined,
  omit,
  unionBy,
  deburr,
  has,
  upperFirst,
  map as lMap,
  startCase,
  isNumber,
  isDate,
} from 'lodash';

import { arrayToTree } from '../../utils/arrayToTree';
import { useUserTrialDetails } from '@revelio/auth';
import {
  useSingleOrMoreFilterState,
  useSelectionLists,
  upsertFilter,
  deleteFilter,
  DEFAULT_SELECTION_LIST_PARENT_MAP,
  addParentToSelectionLists,
} from '../../../engine/filters.engine';
import {
  SelectionCategories,
  SelectFilter,
  SelectionList,
  OtherFilterNames,
  SelectionListIdNames,
  ValidValueTypes,
  FilterItem,
  ScreenerType,
  ScreenEmployeeTypesSelectionCategories,
  LocalSelectionCategories,
  SelectableCategories,
  TreeItem,
  Item,
  Filter,
} from '../../../engine/filters.model';
import { Node, TreeType } from '../node/node';
import {
  getManyFiltersState,
  selectionListDataSource,
} from '../../../engine/filters.repository';
import TreeStyles from './tree.module.css';
import { VariableLengthSkeleton, Views } from '@revelio/core';
import formatFilterLabel from '../../utils/formatFilterLabel';
import { FilterPopoverContext } from '../../filter-popover/filter-popover';
import {
  getCustomActiveSetState,
  getFiltersState,
  resetStoredFilterState,
  useCustomActiveSetState,
} from '../../../engine/filters.storedset';
import { addAllParentsToSearchResults, searchNestedList } from './treeSearch';
import {
  categoriesToTrackUserSubmission,
  nestedSelections,
} from './tree.constants';
import { NESTED_MENU_TOP_LEVEL_CLASS_NAME } from '../../utils/constants';
import { refreshFilterSetStorage } from '../../../engine/filters.persist';
import TreeHeader from '../tree-header/tree-header';
import { notMultiFilters } from '../../../engine/filters.constants';
import { ToggleSelect } from './toggle-select/toggle-select';
import NestedTreeBreadcrumbHeader from '../tree-breadcrumb-header/nested';
import { findSelectionListItemByItemId } from '../../utils/findSelectionListItem';
import { SelectionListParentMap } from '../../../engine/selection-lists.gql';
import { DropdownSelect } from './dropdown-select/dropdown-select';
import { WithDropdown } from './dropdown/WithDropdown';
import { pluralize } from '../../utils/pluralize';
import { selectTreeItem } from './utils/select-tree-item';

type BreadcrumbParentTrees = {
  [id in SelectionListIdNames]?: {
    candidateTree: TreeItem[];
    header: string;
  };
};

export type FilterTreeSelection = { [key: string]: TreeItem };
export type FilterTreeItemData<
  T extends FilterTreeSelection = FilterTreeSelection,
> = (data: T) => T;

export interface TreeProps {
  placeholder?: string;
  filterName?: string;
  selectionLists: SelectionListIdNames[];
  width?: React.CSSProperties['width'];
  submitOnBlur?: boolean;
  setTempSelections?: React.Dispatch<
    React.SetStateAction<Record<string, TreeItem>>
  >;
  forwardedRef?: any;
  disableChildren?: boolean;
  disableParentSelect?: boolean;
  hideParentCheckbox?: boolean; // same as disableParentSelect ?
  parentFilter?: (string | number)[];
  childFilter?: (string | number)[];
  limit?: number;
  activeLimit?: number;
  required?: number;
  onClose?: () => void;
  additionalBottomButtons?: ReactNode; // dead
  screenerFilter?: SelectionListIdNames;
  formatOverride?: Views;
  expandRootsByDefault?: boolean;
  offsetParent?: SelectableCategories[];
  initialFocusRef?: any;
  rebuildOnClose?: boolean;
  showActionMenu?: boolean; // what's this ?
  setNodeModalOpen?: React.Dispatch<boolean>;
  unselectParentOnChildSelect?: boolean;
  viewIdForDefault?: string;
  nestingTreeType?: TreeType;
  sortSelectedToTop?: boolean;
  internalControl?: boolean;
  showHeader?: boolean;
  showBreadcrumbsByDefault?: boolean;
  trialNoResultsMessage?: JSX.Element;
  showMetaTag?: boolean;
  metaTagKey?: string;
  showSelectedTree?: boolean;
  defaultSelectedItemIds?: string[]; // includes selection list in the id e.g. "role_k50.48"
  showToggleBar?: boolean;
  filterNameForToggle?: string;
  onToggleBarChange?: any;
  listNameOverrides?: Record<string, string>;
  selectionListParentMap?: SelectionListParentMap;
  isDropdown?: boolean;
  syncWithPrimaryEntities?: boolean;
  height?: number;
  filterTreeItemData?: FilterTreeItemData;
}

export const Tree = ({
  submitOnBlur = false,
  filterName,
  setTempSelections,
  forwardedRef,
  disableChildren = false,
  disableParentSelect = false,
  parentFilter = [],
  childFilter = [],
  limit,
  activeLimit,
  required = 0,
  additionalBottomButtons,
  screenerFilter,
  formatOverride,
  expandRootsByDefault = false,
  hideParentCheckbox = false,
  offsetParent = [],
  rebuildOnClose = false,
  initialFocusRef,
  showActionMenu = false,
  setNodeModalOpen,
  unselectParentOnChildSelect = false,
  viewIdForDefault,
  nestingTreeType,
  sortSelectedToTop = true,
  internalControl = false,
  showHeader = false,
  showBreadcrumbsByDefault = false,
  trialNoResultsMessage,
  showMetaTag = false,
  metaTagKey = '',
  showSelectedTree = false,
  showToggleBar = false,
  filterNameForToggle = '',
  onToggleBarChange,
  listNameOverrides,
  selectionListParentMap = DEFAULT_SELECTION_LIST_PARENT_MAP,
  isDropdown = false,
  syncWithPrimaryEntities = false,
  height,
  filterTreeItemData,
  ...props
}: TreeProps) => {
  const isNestedTree = !!nestingTreeType;
  const [actionMenuOpen, setActionMenuOpen] = useState<boolean>(false);

  const isMenuOpen = useContext(FilterPopoverContext);

  const toast = useToast();

  const forceUpdate = useUpdate();

  const [search, setSearch] = useState('');

  const [expandBtn, setExpandBtn] = useState(false);

  const treeRef = useRef<FixedSizeTree<any>>();

  const paneRef = useRef(null);

  const { isTrialUser } = useUserTrialDetails();

  const selectionRef = useRef<{
    [key: string]: TreeItem;
  }>({});

  const lookupHandle = useRef<Subject<{ [key: string]: TreeItem }>>(
    new ReplaySubject<{ [key: string]: TreeItem }>()
  );

  const intermediateStateHandle = useRef<Subject<{ [key: string]: TreeItem }>>(
    new ReplaySubject<{ [key: string]: TreeItem }>()
  );

  const searchString = useRef<Subject<string>>(new BehaviorSubject<string>(''));
  const searchValue = useRef<string>();

  const searchHandle = useRef<Subject<{ tree: TreeItem[]; search: string }>>(
    new Subject()
  );

  const selectionLists = useSelectionLists(
    props.selectionLists,
    selectionListParentMap,
    pipe(
      tap((lists) => {
        if (lists.includes(SelectionCategories.SAVED_FILTER_SET)) {
          refreshFilterSetStorage();
        }
      })
    ),
    pipe(
      switchMap((lists) => getSearchTree('', lists)),
      tap((unfilteredSearchTree) => {
        searchHandle.current.next({
          tree: unfilteredSearchTree as TreeItem[],
          search: '',
        });
      })
    )
  );

  const [candidateTree, setCandidateTree] = useState<TreeItem[]>([]);
  const [parentBreadcrumbTrees, setParentBreadcrumbTrees] =
    useState<BreadcrumbParentTrees>();
  const [treeWalker, setTreeWalker] = useState<
    // eslint-disable-next-line @typescript-eslint/ban-types
    TreeWalker<any, {}> | undefined
  >();

  const rootItemsAndLookup = useRef(arrayToTree(...selectionLists));

  const itemLookup = selectionListDataSource
    .data$({
      key: props.selectionLists,
    })
    .pipe(
      distinctUntilChanged((prev, curr) => {
        const prevIds = get(prev, 'selectionLists').map((l) => l.id);
        const currIds = get(curr, 'selectionLists').map((l) => l.id);
        return isEqual(prevIds, currIds);
      }),
      map((selectionLists) => {
        rootItemsAndLookup.current = arrayToTree(
          // ...selectionLists.selectionLists
          ...addParentToSelectionLists({
            selectionLists: selectionLists.selectionLists,
          })
        );
        return rootItemsAndLookup.current;
      })
    );

  const [data] = useSingleOrMoreFilterState<any, SelectFilter[]>(
    filterName
      ? [filterName as OtherFilterNames]
      : props.selectionLists.map((name: any) =>
          get(listNameOverrides, name, name)
        )
  );

  const [primaryEntities] = useSingleOrMoreFilterState<any, SelectFilter[]>([
    LocalSelectionCategories.PRIMARY_ENTITIES,
  ]);

  const updateIntermediateState = (selections: { [key: string]: TreeItem }) => {
    const tempIntermediateState: { [key: string]: TreeItem } = {};

    const addParent = (item: TreeItem) => {
      const parentSelectionListId =
        selectionListParentMap[item?.selectionListId];
      if (parentSelectionListId) {
        const parentId = getParentId({
          item: item.item as Item,
          selectionListParentMap,
          selectionListId: item.selectionListId,
        }) as string;
        const parent = rootItemsAndLookup.current.lookup[parentId];
        tempIntermediateState[parent?.id] = parent;
        addParent(parent);
      }
    };

    Object.values(selections).forEach((item) => {
      addParent(item);
    });

    intermediateStateHandle.current.next(tempIntermediateState);
  };

  const select = selectTreeItem({
    rootItemsAndLookup,
    selectionRef,
    limit,
    unselectParentOnChildSelect,
    isNestedTree,
    toast,
    selectionLists: props.selectionLists,
    setTempSelections,
    lookupHandle,
    updateIntermediateState,
  });

  const [screenerData] = useSingleOrMoreFilterState<SelectFilter<ScreenerType>>(
    screenerFilter ? screenerFilter : []
  );

  const [activeCustomSet] = useCustomActiveSetState();

  const formatFilterSetSelectedItem = (
    activeSetId: string | undefined,
    selectionLists: any
  ) => {
    let selectedItem;

    const savedFilterSets = selectionLists[0];
    const values: any[] = savedFilterSets?.value;

    const item = values?.find((set) => set.id === activeSetId);

    if (item) {
      const entities = item.entities;
      const currentFilterState = getFiltersState();

      const isCurrentActiveCustomSetStillActive = isEqual(
        entities,
        currentFilterState
      );

      if (isCurrentActiveCustomSetStillActive) {
        const id = savedFilterSets.id;
        const itemId = id + '.' + item.id;

        selectedItem = {
          item: item,
          id: itemId,
          isMulti: false,
          parentId: undefined,
          children: [],
          selectionListId: id,
        };
      } else {
        resetStoredFilterState();
      }
    }

    return selectedItem;
  };

  // sets selected items on load and as a controlled list
  useEffect(() => {
    const hasDefaultSelectedItems =
      (data && data.length) || props.defaultSelectedItemIds?.length;
    const noSelectedItems = Object.keys(selectionRef.current).length === 0;
    if (
      selectionLists.length === props.selectionLists.length &&
      hasDefaultSelectedItems && // without these further checks, causes infinite render loop due to
      noSelectedItems // adding parent via selectionList.map and shallow diff failing
    ) {
      const selections: {
        [key: string]: TreeItem;
      } = {};

      if (
        selectionLists?.length === 1 &&
        selectionLists[0]?.id === SelectionCategories.SAVED_FILTER_SET
      ) {
        const selectedItem = formatFilterSetSelectedItem(
          activeCustomSet,
          selectionLists
        );

        if (selectedItem) {
          selections[selectedItem.id] = selectedItem;
        }
      }

      if (
        screenerData &&
        typeof screenerData === 'object' &&
        Object.keys(screenerData).length > 0
      ) {
        const id = filterName as ScreenEmployeeTypesSelectionCategories;
        const screenerDataEmployeeTypes =
          screenerData?.value?.employeeTypes[id];
        if (screenerDataEmployeeTypes) {
          for (const item of screenerDataEmployeeTypes) {
            const itemId = id + '.' + item.id;

            if (!Object.prototype.hasOwnProperty.call(selections, itemId)) {
              selections[itemId] = {
                item: item,
                id: itemId,
                parentId: undefined,
                children: [],
                selectionListId: id,
              };
            }
          }
        }
      }

      if (props.defaultSelectedItemIds) {
        props.defaultSelectedItemIds.forEach((itemId) => {
          const selectionListItem = findSelectionListItemByItemId({
            itemId,
            selectionLists,
          });
          if (selectionListItem) {
            selections[selectionListItem.id] = selectionListItem;
          }
        });
      } else {
        data?.forEach((category: any) => {
          //For each category in the data
          const id: SelectionListIdNames =
            category.selectionListId || category.id;

          const catVal = get(category, 'isMulti', true)
            ? category.value
            : [category.value];

          for (const item of catVal) {
            const itemId = id + '.' + item.id;

            if (!has(selections, itemId)) {
              selections[itemId] = {
                item: { ...item, isActive: true },
                id: itemId,
                isMulti: get(category, 'isMulti', true),
                parentId: getParentId({
                  selectionListId: id,
                  selectionListParentMap,
                  item,
                }),
                children: [],
                selectionListId: id,
              };
            }
          }
        });
      }

      const primaryEntityValues = get(primaryEntities?.[0], 'value', []);

      if (
        syncWithPrimaryEntities &&
        primaryEntityValues &&
        Array.isArray(primaryEntityValues)
      ) {
        primaryEntityValues.forEach((entity: any) => {
          const { id, selectionListId } = entity;
          const itemId = `${selectionListId}.${id}`;
          if (!has(selections, itemId)) {
            selections[itemId] = {
              item: { ...entity, isActive: false },
              id: itemId,
              isMulti: true,
              parentId: getParentId({
                selectionListId: id,
                selectionListParentMap,
                item: entity,
              }),
              children: [],
              selectionListId,
            };
          }
        });
      }
      if (typeof setTempSelections === 'function') {
        setTempSelections(selections);
      }

      const filteredSelections = filterTreeItemData
        ? filterTreeItemData(selections)
        : selections;

      selectionRef.current = filteredSelections;
      lookupHandle.current.next(filteredSelections);
      updateIntermediateState(filteredSelections);
      forceUpdate();
    }
  }, [data, screenerData, selectionLists, isMenuOpen, activeCustomSet]);

  useOutsideClick({
    ref: paneRef,
    handler: (e) => {
      if (submitOnBlur) {
        submit();
      }
    },
  });

  const getSearchTree = (
    search: string,
    selectionLists: SelectionList<ValidValueTypes>[]
  ): Observable<TreeItem[]> => {
    // Expand button does not show up on Nested Tree
    const isNestedTreeState = isNestedTree && isEmpty(search);
    setShowExpandBtn(
      !isNestedTreeState && selectionLists?.length > 1 && !disableChildren
    );

    // initially search is empty and can be made empty
    if (!search) {
      rootItemsAndLookup.current = arrayToTree(...selectionLists);
      return of(rootItemsAndLookup.current.rootItems);
    }

    // initialising as empty first instead of mapping selectionLists straight away allows us to add search result's parent
    const searchLists: SelectionList[] = selectionLists.map(
      (unfilteredList) => ({
        ...unfilteredList,
        value: [],
      })
    );

    // filter out disabled lists so they don't appear in search
    if (disableChildren) {
      selectionLists = selectionLists.filter((sl) => {
        return (
          isUndefined(sl.parent) ||
          !selectionLists.find((list) => list.id === sl.parent)
        );
      });
    }

    selectionLists.forEach((unfilteredList, index) => {
      const hasMixOfNestedListsAndValuesInTree = nestedSelections.includes(
        unfilteredList.id as LocalSelectionCategories
      );
      if (hasMixOfNestedListsAndValuesInTree) {
        searchLists[index].value = unionBy(
          searchLists[index].value,
          searchNestedList(search, unfilteredList),
          'id'
        );
        return;
      }

      let hasSuggestedResults = false;

      const searchResults = unfilteredList.value.reduce(
        (
          filteredList: (
            | FilterItem
            | (FilterItem & { isSuggestedResult: boolean })
          )[],
          item: FilterItem
        ) => {
          // search matches item label
          const matchedLabel = deburr(item.label?.toLowerCase()).includes(
            search
          );
          // search matches ticker
          const matchedTicker = item.ticker?.toLowerCase().includes(search);

          const matchedSedol = item.sedol?.toLowerCase().includes(search);

          const matchedIsin = item.isin?.toLowerCase().includes(search);

          // search matches raw title
          const rawTitles = get(item, 'topRawTitles', []);

          const cleanedTitles = get(item, 'topCleanedTitles', []);

          const topSkills = get(item, 'topSkills', []);

          const rawTitlesArray = isString(rawTitles)
            ? rawTitles.split(',')
            : rawTitles;

          const cleanedTitlesArray = isString(cleanedTitles)
            ? cleanedTitles.split(',')
            : cleanedTitles;

          const topSkillsArray = isString(topSkills)
            ? topSkills.split(',')
            : topSkills;

          const matchedRawTitle =
            rawTitlesArray &&
            rawTitlesArray.some((title: string) =>
              deburr(title.toLowerCase().trim()).includes(search)
            );

          const matchedCleanedTitle =
            cleanedTitlesArray &&
            cleanedTitlesArray.some((title: string) =>
              deburr(title.toLowerCase().trim()).includes(search)
            );

          const matchedTopSkills =
            topSkillsArray &&
            topSkillsArray.some((title: string) =>
              deburr(title.toLowerCase().trim()).includes(search)
            );

          const isIncludedInSearch =
            matchedLabel ||
            matchedTicker ||
            matchedIsin ||
            matchedSedol ||
            matchedRawTitle ||
            matchedCleanedTitle ||
            matchedTopSkills;

          const isSuggestedResult =
            (matchedRawTitle || matchedCleanedTitle || matchedTopSkills) &&
            !matchedLabel;

          if (isIncludedInSearch) {
            let suggestedResult;
            if (isSuggestedResult) {
              hasSuggestedResults = true;

              suggestedResult = {
                ...item,
                isSuggestedResult: true,
              };
            }

            addAllParentsToSearchResults(
              item as Item,
              unfilteredList,
              searchLists,
              selectionLists
            );

            return [...filteredList, suggestedResult || item];
          }
          return filteredList;
        },
        []
      );

      searchLists[index].value = unionBy(
        searchLists[index].value,
        hasSuggestedResults
          ? searchResults.sort((a, b) => {
              const aIsSuggested = get(a, 'isSuggestedResult');

              const bIsSuggested = get(b, 'isSuggestedResult');

              return (bIsSuggested ? -1 : 1) + (aIsSuggested ? 1 : -1);
            })
          : searchResults,
        'id'
      );
    });

    rootItemsAndLookup.current = arrayToTree(...searchLists);

    const { rootItems: searchRootItems } = rootItemsAndLookup.current;

    return of(searchRootItems);
  };

  const getNodeData = (
    node: any,
    nestingLevel: any,
    lookup: Observable<{ [key: string]: TreeItem }>,
    intermediateLookup: Observable<Record<string, unknown>>,
    search = '',
    isNestedTree = false,
    rebuildTreeOnSelection?: (freshCandidateTree: any) => void
  ) => {
    const selected: Observable<any> = merge(
      lookup.pipe(distinctUntilChanged()),
      lookupHandle.current
    ).pipe(
      map((state) => {
        return state;
      })
    );

    const intermediate = merge(
      intermediateLookup.pipe(distinctUntilChanged()),
      intermediateStateHandle.current
    ).pipe(
      map((state) => {
        return Object.prototype.hasOwnProperty.call(state, node.id);
      })
    );

    const offset = offsetParent.some((item: any) =>
      props.selectionLists.includes(item)
    );

    const isSelectableParent =
      Object.prototype.hasOwnProperty.call(node, 'isSelectableParent') &&
      node.isSelectableParent &&
      !disableParentSelect;

    const isLeaf = disableChildren || node.children.length === 0;

    const isDisabled = !get(node.item, 'matchesActiveFilterSetView', true);

    const showTooltip =
      props.selectionLists[0] === SelectionCategories.SAVED_FILTER_SET &&
      isDisabled;

    const labelFormatter =
      props.selectionLists[0] !== SelectionCategories.SAVED_FILTER_SET
        ? upperFirst
        : (x: string) => x;

    return {
      data: {
        id: node.id.toString(),
        listId: node.selectionListId,
        isLeaf,
        children: node.children,
        isOpenByDefault: !isEmpty(search) || expandRootsByDefault,
        name: node.item.label,
        nestingLevel,
        nestingTreeType,
        select,
        intermediate,
        selected,
        disableParentSelect,
        hideParentCheckbox,
        // parent always selectable for breadcrumb nested tree otherwise selections don't work for nestings + checkbox doesn't appear in leaf node
        isSelectableParent:
          nestingTreeType === TreeType.BREADCRUMB_NESTED || isSelectableParent,
        offsetParent: offset,
        isDisabled,
        showTooltip,
        item: node.item,
        showActionMenu,
        setActionMenuOpen,
        setNodeModalOpen,
        viewIdForDefault,
        isNestedTree,
        labelFormatter,
        showMetaTag,
        metaTagKey,
        rebuildTreeOnSelection,
        submenuProps: {
          lookupHandle,
          intermediateStateHandle,
          selectionLookupRef: lookup,
          intermediateLookupRef: intermediateLookup,
          parentRef: paneRef,
          compareFunction: selectedToTop,
        },
      },
      offsetParent: offset,
      nestingLevel,
      node,
    };
  };

  const relevantFilterRef = useRef(
    props.selectionLists.includes(SelectionCategories.SAVED_FILTER_SET)
      ? getCustomActiveSetState()
      : getManyFiltersState(
          filterName
            ? [filterName as OtherFilterNames]
            : props.selectionLists.map((name: any) =>
                get(listNameOverrides, name, name)
              )
        )
  );

  const primaryEntitiesRef = useRef(
    getManyFiltersState([LocalSelectionCategories.PRIMARY_ENTITIES])
  );

  const selectionLookupRef = useRef<Observable<{ [key: string]: TreeItem }>>(
    combineLatest([
      relevantFilterRef.current,
      primaryEntitiesRef.current,
      selectionListDataSource.data$({
        key: props.selectionLists,
      }),
    ]).pipe(
      filter((value) => {
        const [, , { selectionLists }] = value;

        return selectionLists.length === props.selectionLists.length;
      }),
      switchMap(([data, primaryEntities, { selectionLists }]) => {
        const selections: {
          [key: string]: TreeItem;
        } = {};

        if (
          selectionLists?.length === 1 &&
          selectionLists[0]?.id === SelectionCategories.SAVED_FILTER_SET
        ) {
          const [setName] = data;

          if (isString(setName)) {
            const selectedItem = formatFilterSetSelectedItem(
              setName,
              selectionLists
            );

            if (selectedItem) {
              selections[selectedItem.id] = selectedItem;
            }
          }
        } else {
          Object.values(data).forEach((category: any) => {
            //For each category in the data
            const id = category.selectionListId || category.id;
            // in nested selection lists, it is its own parent

            const parent = nestedSelections.includes(id)
              ? id
              : selectionLists.find(
                  (item) =>
                    item.id === get(category, 'selectionListId', category.id)
                )?.parent;

            const catVal = get(category, 'isMulti', true)
              ? category.value
              : [category.value];

            for (const item of catVal) {
              const itemId = id + '.' + item.id;
              let parentId;

              if (item?.parentId) {
                parentId = parent + '.' + item.parentId;
              }

              if (!has(selections, itemId)) {
                selections[itemId] = {
                  item: item,
                  id: itemId,
                  isMulti: get(category, 'isMulti', true),
                  parentId,
                  children: [],
                  selectionListId: id,
                };
              }
            }
          });
        }

        const primaryEntityValues = get(primaryEntities?.[0], 'value', []);

        if (primaryEntityValues && Array.isArray(primaryEntityValues)) {
          primaryEntityValues.forEach((entity: any) => {
            const { id, selectionListId } = entity;
            const itemId = `${selectionListId}.${id}`;

            if (!has(selections, itemId)) {
              selections[itemId] = {
                item: entity,
                id: itemId,
                isMulti: true,
                parentId: getParentId({
                  selectionListId: id,
                  selectionListParentMap,
                  item: entity,
                }),
                children: [],
                selectionListId,
              };
            }
          });
        }

        return of(selections);
      })
    )
  );

  const intermediateLookupRef = useRef<Observable<{ [key: string]: TreeItem }>>(
    selectionLookupRef.current.pipe(
      distinctUntilChanged(),
      withLatestFrom(itemLookup),
      map(([state, selectionLists]) => {
        const tempIntermediateState: { [key: string]: TreeItem } = {};

        const { lookup } = selectionLists;

        const addParent = (item: TreeItem) => {
          const parentSelectionListId =
            selectionListParentMap[item?.selectionListId];
          if (parentSelectionListId) {
            const parent =
              lookup[
                getParentId({
                  item: item.item as Item,
                  selectionListParentMap,
                  selectionListId: item.selectionListId,
                }) as string
              ];
            tempIntermediateState[parent?.id] = parent;
            addParent(parent);
          }
        };

        Object.values(state).forEach((item) => {
          addParent(item);
        });
        return tempIntermediateState;
      })
    )
  );

  const toggleRoots = (open: boolean) => {
    setExpandBtn(open);
    const openSesame = { refreshNodes: true } as any;
    rootItemsAndLookup.current.rootItems.forEach((item: TreeItem) => {
      openSesame[item.id] = { open };
    });
    treeRef.current?.recomputeTree(openSesame);
  };

  const getSelectionListIdOverride = (id: SelectionListIdNames) =>
    filterName || get(listNameOverrides, id, id);

  const submit = (temp = undefined) => {
    const upsertCandidates: {
      [key: string]: {
        selectionList: SelectionListIdNames;
        values: TreeItem[];
      };
    } = {};

    const activeEntities: {
      [key: string]: {
        selectionList: SelectionListIdNames;
        values: TreeItem[];
      };
    } = {};

    const selections = temp ? temp : selectionRef.current;

    if (Object.keys(selections).length < required) {
      toast({
        position: 'top-right',
        status: 'warning',
        title: `${props.selectionLists
          .map((sl) => formatFilterLabel(sl, required))
          .join('/')} ${required === 1 ? 'Selection' : 'Selections'} Required`,
        description: `You must make at least ${required} ${props.selectionLists
          .map((sl) => formatFilterLabel(sl, required))
          .join('/')} ${required === 1 ? 'Selection' : 'Selections'}!`,
        isClosable: true,
        variant: 'subtle',
      });
      return;
    }

    const parentList =
      selectionLists.find(
        (item) =>
          !item.parent || !selectionLists.find((li) => li.id === item.parent)
      ) || selectionLists[0];

    const childList =
      selectionLists.find((item) => item.parent && item.id !== parentList.id) ||
      selectionLists[1];

    selectionLists.forEach((selectionList) => {
      const correctSelectionList = (() => {
        if (disableChildren) {
          return parentList.id;
        }

        if (disableParentSelect) {
          return childList.id;
        }

        return selectionList.id;
      })();

      const candidateKey = getSelectionListIdOverride(selectionList.id);
      upsertCandidates[candidateKey] = {
        selectionList: correctSelectionList,
        values: [],
      };

      if (syncWithPrimaryEntities) {
        activeEntities[candidateKey] = {
          selectionList: correctSelectionList,
          values: [],
        };
      }
    });

    const updatedPrimaryEntities: any = [];

    Object.values(selections).forEach((value) => {
      updatedPrimaryEntities.unshift({
        ...value.item,
        selectionListId: value.selectionListId,
      });

      upsertCandidates[
        getSelectionListIdOverride(value.selectionListId)
      ]?.values.push(selections[value.id]);
    });

    if (syncWithPrimaryEntities) {
      const primaryEntityValues = get(primaryEntities?.[0], 'value', []);

      if (!Array.isArray(primaryEntityValues)) return;

      const primaryEntityIds = primaryEntityValues
        .map((ent) => {
          if (isNumber(ent) || isString(ent) || isDate(ent)) {
            return ent.toString();
          }

          return `${ent.selectionListId}.${ent.id}`;
        })
        ?.reverse();

      const newEntities: Filter[] = [];

      const entitiesToSort = updatedPrimaryEntities.filter((ent: any) => {
        const indexOfEnt = primaryEntityIds.indexOf(
          `${ent.selectionListId}.${ent.id}`
        );

        if (indexOfEnt == -1) {
          newEntities.push(ent);
        }

        return indexOfEnt > -1;
      });

      const sortedEntities = entitiesToSort.sort((a: any, b: any) => {
        const indexOfA = primaryEntityIds.indexOf(
          `${a.selectionListId}.${a.id}`
        );

        const indexOfB = primaryEntityIds.indexOf(
          `${b.selectionListId}.${b.id}`
        );

        return indexOfB - indexOfA;
      });

      const entitiesForStore = [...newEntities, ...sortedEntities];

      upsertFilter<SelectFilter>(LocalSelectionCategories.PRIMARY_ENTITIES, {
        value: entitiesForStore,
      });

      let limitRemaining = activeLimit || 6;

      entitiesForStore.some((entity: any, index: number) => {
        const { selectionListId } = entity;

        const lookupName = get(
          listNameOverrides,
          selectionListId,
          selectionListId
        );

        if (has(activeEntities, lookupName) && get(entity, 'isActive', true)) {
          if (limitRemaining == 0) return true;

          activeEntities[lookupName].values.push(entity);
          limitRemaining -= 1;
        }

        return false;
      });
    }

    const entityLookup = syncWithPrimaryEntities
      ? activeEntities
      : upsertCandidates;

    (Object.keys(entityLookup) as SelectionListIdNames[]).forEach(
      (selectionCategory) => {
        const lookupName = getSelectionListIdOverride(selectionCategory) as
          | SelectionListIdNames
          | OtherFilterNames;

        const singleSelectFilterMapping: Record<
          string,
          SelectableCategories[]
        > = {
          [SelectionCategories.INDUSTRY]: [SelectionCategories.COMPANY],
        };

        const items = entityLookup[lookupName].values;

        if (!items) {
          return;
        }
        const itemsArray = syncWithPrimaryEntities
          ? items
          : items.map((item: any) => item.item);

        if (itemsArray.length == 0) {
          deleteFilter(lookupName);
        } else {
          if (unselectParentOnChildSelect) {
            // Note: This is to handle the company/industry filters
            // on role/geo, where onlw one of company or industry may be selected
            const associatedChildren = get(
              singleSelectFilterMapping,
              lookupName,
              []
            );

            associatedChildren.forEach((filterName) => {
              deleteFilter(filterName);
            });
          }

          const noMulti = notMultiFilters.includes(lookupName);

          const upsertObject = {
            isMulti: !noMulti,
            formatOverride: formatOverride,
            selectionListId: entityLookup[lookupName].selectionList,
            value: noMulti ? itemsArray[0] : itemsArray,
            ...(categoriesToTrackUserSubmission.includes(lookupName) && {
              isUserSubmitted: true,
            }),
          };

          upsertFilter<SelectFilter>(lookupName, upsertObject);
        }
      }
    );
    props.onClose?.();
  };

  const handleClearSelections = () => {
    lookupHandle.current.next({});
    selectionRef.current = {};
    updateIntermediateState({});
    setTempSelections?.({});
  };

  useImperativeHandle(forwardedRef, () => ({
    submit,
    handleClearSelections,
  }));

  useEffect$(() => {
    return searchString.current.pipe(
      debounceTime(250),
      distinctUntilChanged(),
      tap((value) => {
        searchValue.current = value;
      }),
      withLatestFrom(
        selectionListDataSource.data$({
          key: props.selectionLists,
        })
      ),
      switchMap(([searchString, selectionLists]) => {
        return combineLatest({
          tree: getSearchTree(
            searchString,
            addParentToSelectionLists({
              selectionLists: selectionLists.selectionLists,
              selectionListParentMap,
            })
          ),
          search: of(searchString),
        });
      }),
      tap(({ tree, search }) => {
        searchHandle.current.next({
          tree: tree as TreeItem[],
          search: search,
        });
      })
    );
  }, []);

  const selectedToTop = (
    a: TreeItem,
    b: TreeItem,
    selectionLookup: any,
    intermediateLookup: any
  ): number => {
    const aIndicated =
      Object.prototype.hasOwnProperty.call(selectionLookup, a.id) ||
      Object.prototype.hasOwnProperty.call(intermediateLookup, a.id);
    const bIndicated =
      Object.prototype.hasOwnProperty.call(selectionLookup, b.id) ||
      Object.prototype.hasOwnProperty.call(intermediateLookup, b.id);

    return (aIndicated ? -1 : 1) + (bIndicated ? 1 : -1);
  };

  const sortBySelectableThenSelected = (
    a: TreeItem,
    b: TreeItem,
    selectionLookup: any,
    intermediateLookup: any
  ) => {
    const aIndicated =
      Object.prototype.hasOwnProperty.call(selectionLookup, a.id) ||
      Object.prototype.hasOwnProperty.call(intermediateLookup, a.id);

    const bIndicated =
      Object.prototype.hasOwnProperty.call(selectionLookup, b.id) ||
      Object.prototype.hasOwnProperty.call(intermediateLookup, b.id);

    const aSelectable = get(a.item, 'matchesActiveFilterSetView', true);
    const bSelectable = get(b.item, 'matchesActiveFilterSetView', true);

    return (aSelectable ? -1 : 1) + (bSelectable ? 1 : -1) === 0
      ? (aIndicated ? -1 : 1) + (bIndicated ? 1 : -1)
      : (aSelectable ? -1 : 1) + (bSelectable ? 1 : -1);
  };

  const filterParentsOnCandidateTree = (candidateTree: TreeItem[]) => {
    if (isEmpty(parentFilter)) {
      return candidateTree;
    }

    return candidateTree.filter((rootNode) =>
      parentFilter.includes((rootNode.item as Item).id)
    );
  };

  const filterChildrenOnCandidateTree = (candidateTree: TreeItem[]) => {
    if (isEmpty(childFilter)) {
      return candidateTree;
    }

    const filtered = candidateTree.map((node) => {
      const filteredChildren = node.children.filter(
        (childItem: TreeItem, i) => {
          if (childItem.item) {
            return childFilter.includes(childItem.item.id);
          }
          return false;
        }
      );

      const updatedProps =
        filteredChildren.length > 0 ? { children: filteredChildren } : {};

      return {
        ...node,
        ...updatedProps,
      };
    });

    return filtered;
  };

  const rebuildTree = (
    freshCandidateTree: any,
    selectionLookupInput: any,
    intermediateLookup: any,
    rebuildTreeSearch: any
  ) => {
    const selectionLookup = filterTreeItemData
      ? filterTreeItemData(selectionLookupInput)
      : selectionLookupInput;

    const isNestedTreeState = isNestedTree && isEmpty(rebuildTreeSearch);

    const parentFilteredCandidateTree = filterChildrenOnCandidateTree(
      filterParentsOnCandidateTree(freshCandidateTree)
    );

    setCandidateTree(parentFilteredCandidateTree);
    setParentBreadcrumbTrees((prev) => {
      if (prev?.[props.selectionLists[0]]) {
        return prev;
      }

      return {
        ...prev,
        [props.selectionLists[0]]: {
          candidateTree: parentFilteredCandidateTree,
        },
      };
    });

    const compareFuncton =
      props.selectionLists.length === 1 &&
      props.selectionLists[0] === SelectionCategories.SAVED_FILTER_SET
        ? sortBySelectableThenSelected
        : selectedToTop;

    if (parentFilteredCandidateTree.length || rebuildTreeSearch.length > 0) {
      toggleRoots(rebuildTreeSearch.length > 0 || expandRootsByDefault);
      setTreeWalker(
        () =>
          function* dataTreeWalker() {
            if (
              parentFilteredCandidateTree.length == 0 &&
              rebuildTreeSearch.length > 0
            ) {
              yield {
                data: {
                  id: '$no-results-node',
                  isOpenByDefault: false,
                  nestingLevel: 0,
                  selected: of(false),
                  intermediate: of(false),
                },
                node: {
                  id: '$no-results-node',
                  children: [],
                  item: { label: '' },
                },
              };
            }

            if (sortSelectedToTop) {
              parentFilteredCandidateTree.sort((a, b) =>
                compareFuncton(a, b, selectionLookup, intermediateLookup)
              );
            }

            for (const treeItem of parentFilteredCandidateTree) {
              yield getNodeData(
                treeItem,
                0,
                selectionLookupRef.current,
                intermediateLookupRef.current,
                rebuildTreeSearch,
                isNestedTreeState,
                ({ selectedTree, selectionList, header }) => {
                  setParentBreadcrumbTrees((prev) => ({
                    ...prev,
                    [selectionList]: {
                      candidateTree: selectedTree,
                      header,
                    },
                  }));
                  rebuildTree(
                    selectedTree,
                    selectionLookup,
                    intermediateLookup,
                    ''
                  );
                }
              );
            }

            if (!isNestedTreeState) {
              while (true) {
                const parentMeta: { node: any; nestingLevel: any } = yield;

                parentMeta.node.children = parentMeta.node.children.sort(
                  (a: TreeItem, b: TreeItem) =>
                    compareFuncton(a, b, selectionLookup, intermediateLookup)
                );

                for (let i = 0; i < parentMeta.node.children.length; i++) {
                  yield getNodeData(
                    parentMeta.node.children[i],
                    parentMeta.nestingLevel + 1,
                    selectionLookupRef.current,
                    intermediateLookupRef.current,
                    rebuildTreeSearch,
                    false,
                    (selectedTree) =>
                      rebuildTree(
                        selectedTree,
                        selectionLookup,
                        intermediateLookup,
                        ''
                      )
                  );
                }
              }
            }
            // eslint-disable-next-line @typescript-eslint/ban-types
          } as unknown as TreeWalker<any, {}>
      );
    }
  };

  const outerMenuOpenState = useRef<Subject<boolean>>(new Subject());

  useEffect(() => {
    if (isMenuOpen !== undefined) {
      outerMenuOpenState.current.next(isMenuOpen);
    }

    if (treeRef.current && isMenuOpen) {
      treeRef.current.scrollTo(0);
    }

    if (!isMenuOpen && rebuildOnClose) {
      setSearch('');
      searchString.current.next('');
    }
  }, [isMenuOpen]);

  useEffect$(() => {
    return combineLatest([
      outerMenuOpenState.current,
      selectionLookupRef.current,
      intermediateLookupRef.current,
    ]).pipe(
      withLatestFrom(searchHandle.current),
      map((combined) => {
        const [
          [outerMenuState, selectionLookup, intermediateLookup],
          { tree: freshCandidateTree, search },
        ] = combined;

        if (!outerMenuState && rebuildOnClose) {
          rebuildTree(
            freshCandidateTree,
            selectionLookup,
            intermediateLookup,
            search
          );
        }
      })
    );
  }, [selectionLookupRef.current]);

  useEffect$(() => {
    return searchHandle.current.pipe(
      withLatestFrom(
        selectionLookupRef.current.pipe(startWith({})),
        intermediateLookupRef.current.pipe(startWith({}))
      ),
      map((combined) => {
        const [
          { tree: freshCandidateTree, search },
          selectionLookup,
          intermediateLookup,
        ] = combined;

        rebuildTree(
          freshCandidateTree,
          selectionLookup,
          intermediateLookup,
          search
        );
      })
    );
  }, []);

  useEffect$(() => {
    // Makes sure changes to selectionListDataSource triggers rebuildTree
    // even when searchHandle ref, etc. haven't yet emitted a value
    return selectionListDataSource.data$({ key: props.selectionLists }).pipe(
      withLatestFrom(
        searchHandle.current.pipe(startWith(null)),
        selectionLookupRef.current.pipe(startWith({})),
        intermediateLookupRef.current.pipe(startWith({}))
      ),
      map(([_, searchValue, lookupValue, intermediateValue]) => {
        if (searchValue?.tree) {
          rebuildTree(
            searchValue?.tree,
            lookupValue,
            intermediateValue,
            searchValue?.search
          );
        }
      })
    );
  }, [
    selectionListDataSource,
    props.selectionLists,
    searchHandle,
    selectionLookupRef,
    intermediateLookupRef,
  ]);

  const [showExpandBtn, setShowExpandBtn] = useState<boolean>(false);

  useEffect(() => {
    const isButtonShowing =
      !isNestedTree &&
      (selectionLists.length > 1 ||
        nestedSelections.includes(
          selectionLists[0]?.id as LocalSelectionCategories
        )) &&
      !disableChildren;

    setShowExpandBtn(isButtonShowing);
  }, [isNestedTree, selectionLists, disableChildren]);

  const noSearchResults =
    !candidateTree.length && !isEmpty(searchValue.current);

  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  return (
    <Box
      width={props.width}
      background="transparent"
      borderRadius="3px"
      height="fit-content"
      ref={paneRef as React.RefObject<HTMLDivElement>}
      className={cx('filter-pane', NESTED_MENU_TOP_LEVEL_CLASS_NAME)}
      data-testid={NESTED_MENU_TOP_LEVEL_CLASS_NAME}
    >
      <WithDropdown
        isDropdown={isDropdown}
        trigger={
          <DropdownSelect
            value={
              // eslint-disable-next-line no-nested-ternary
              Object.keys(selectionRef.current).length === 1
                ? `${Object.values(selectionRef.current)[0]?.item?.shortName}`
                : Object.keys(selectionRef.current).length > 1
                  ? `${Object.keys(selectionRef.current).length} ${pluralize(
                      startCase(props.placeholder)
                    )}`
                  : `${pluralize(startCase(props.placeholder))}`
            }
            isDropdownOpen={isDropdownOpen}
            setIsDropdownOpen={setIsDropdownOpen}
          />
        }
      >
        <Box height="fit-content" marginTop={0} className="dropdown-pane">
          <Box>
            <Box marginBottom={2}>
              <InputGroup size="sm">
                <Input
                  autoFocus
                  placeholder="Search..."
                  value={search}
                  backgroundColor="#ffffff"
                  onChange={(e) => {
                    setSearch(e.target.value.toLowerCase());
                    searchString.current.next(e.target.value.toLowerCase());
                  }}
                  ref={initialFocusRef}
                  data-testid="tree-search-input"
                />
                <InputRightElement
                  children={
                    <>
                      {/* Render Search or Close icon */}
                      {search === '' ? (
                        <SearchIcon w={3.5} h={3.5} color="silver.600" />
                      ) : (
                        <CloseIcon
                          w={2.5}
                          h={2.5}
                          color="silver.600"
                          onClick={() => {
                            setSearch('');
                            searchString.current.next('');
                          }}
                        />
                      )}
                    </>
                  }
                />
              </InputGroup>

              {showExpandBtn && !noSearchResults && (
                <Button
                  size="sm"
                  variant="link"
                  fontWeight={400}
                  paddingTop={2}
                  paddingLeft={1}
                  colorScheme="navyBlue"
                  onClick={() => toggleRoots(!expandBtn)}
                >
                  {expandBtn ? 'Collapse All' : 'Expand All'}
                </Button>
              )}

              {noSearchResults && isTrialUser && trialNoResultsMessage}
            </Box>

            {nestingTreeType === TreeType.BREADCRUMB_NESTED && !search && (
              <Text fontSize="xs" fontWeight="600" letterSpacing={0.8}>
                <NestedTreeBreadcrumbHeader
                  lists={lMap(parentBreadcrumbTrees, (item, key) => ({
                    header: item?.header,
                    selectionList: key as SelectionListIdNames,
                  }))}
                  rebuildParentTree={(
                    selectedCrumbselectionListId: SelectionListIdNames
                  ) => {
                    const removedSelectionListIndex =
                      props.selectionLists.findIndex(
                        (l) => l === selectedCrumbselectionListId
                      );
                    const crumbsToRemove = props.selectionLists.slice(
                      removedSelectionListIndex + 1
                    );
                    // remove all nested items after selection list
                    setParentBreadcrumbTrees((prev) => {
                      return omit(prev, crumbsToRemove);
                    });
                    rebuildTree(
                      (parentBreadcrumbTrees as BreadcrumbParentTrees)[
                        selectedCrumbselectionListId
                      ]?.candidateTree,
                      selectionLookupRef.current,
                      intermediateLookupRef.current,
                      ''
                    );
                  }}
                />
              </Text>
            )}

            <Box height={245}>
              {showHeader &&
                (nestingTreeType !== TreeType.BREADCRUMB_NESTED || search) && (
                  <TreeHeader
                    hasSearch={!isEmpty(search)}
                    isNestedTree={isNestedTree}
                    showBreadcrumbsByDefault={showBreadcrumbsByDefault}
                    selectionLists={
                      disableChildren
                        ? props.selectionLists.slice(0, 1)
                        : props.selectionLists
                    }
                  />
                )}

              {!(noSearchResults && isTrialUser && trialNoResultsMessage) &&
                (treeWalker ? (
                  <FixedSizeTree
                    className={cx(
                      TreeStyles.fixedSizedTree,
                      actionMenuOpen && TreeStyles.disablePointerEvents
                    )}
                    treeWalker={treeWalker}
                    placeholder={
                      <VariableLengthSkeleton
                        data-testid="tree-loading-skeleton"
                        count={9}
                        fade
                        delay={1000}
                      />
                    }
                    itemSize={24}
                    height={height || 220}
                    ref={treeRef as LegacyRef<FixedSizeTree<any>>}
                    async={true}
                  >
                    {Node as any}
                  </FixedSizeTree>
                ) : (
                  <VariableLengthSkeleton
                    data-testid="tree-loading-skeleton"
                    count={9}
                    pt={1}
                    fade
                    delay={1000}
                    checkboxes
                  />
                ))}
            </Box>
            {showToggleBar && (
              <ToggleSelect
                filterName={filterNameForToggle}
                onChange={onToggleBarChange}
                data={data}
              />
            )}

            {internalControl && (
              <ButtonGroup
                size="sm"
                height="100%"
                justifyContent="flex-end"
                w="100%"
                spacing={4}
                mt={4}
              >
                {additionalBottomButtons}
                <Button
                  bg="green.500"
                  variant="filteraction"
                  onClick={() => submit()}
                  data-testid="filter-popover-tree-submit"
                >
                  Update
                </Button>
              </ButtonGroup>
            )}
          </Box>
        </Box>
      </WithDropdown>
    </Box>
  );
};

const getParentId = ({
  selectionListId,
  item,
  selectionListParentMap,
}: {
  item: Item;
  selectionListParentMap: SelectionListParentMap;
  selectionListId: SelectionListIdNames;
}) => {
  const parentSelectionListId = get(
    selectionListParentMap,
    selectionListId,
    'parentId'
  );

  const parentIdValue = parentSelectionListId
    ? item?.[parentSelectionListId] || item.parentId
    : undefined;
  if (!parentIdValue) {
    return undefined;
  }

  /// TODO: temp fix for multi-parent items
  const selectedParentId = Array.isArray(parentIdValue)
    ? parentIdValue[0]
    : parentIdValue;

  return parentSelectionListId + '.' + selectedParentId;
};

// HOC to forward ref to Tree
const withTreeRef = (TreeComponent: React.FunctionComponent<TreeProps>) => {
  return forwardRef(({ ...rest }: TreeProps, ref) => (
    <TreeComponent {...rest} forwardedRef={ref} />
  ));
};

export const TreeRef = React.memo(withTreeRef(Tree));

export default Tree;
