import { PageTitles, PrimaryFilters, PrimaryView } from '@revelio/core';
import {
  SelectionCategories,
  ViewTypes,
  FilterSets,
  PrimaryFilterLimits,
  FilterMenuLimits,
  GEOGRAPHY_GRANULARITY_FILTERS,
  ROLE_GRANULARITY_FILTERS,
  INDUSTRY_AND_COMPANY_FILTERS,
} from '@revelio/filtering';
import Compensation from '../../../compensation/compensation';

const title = [PageTitles.GEOGRAPHY, PageTitles.PAY];

const primaryViewFilters = [
  {
    filters: GEOGRAPHY_GRANULARITY_FILTERS,
    isNested: true,
  },
];
const selectableFilters = [
  {
    filters: INDUSTRY_AND_COMPANY_FILTERS,
    isNested: true,
    limit: 10,
  },
  {
    filters: ROLE_GRANULARITY_FILTERS,
    isNested: true,
    limit: 10,
  },
  SelectionCategories.SENIORITY,
  SelectionCategories.DATE_RANGE_FULL,
];

export function GeographyPay() {
  return (
    <Compensation
      title={title}
      primaryView={PrimaryView.GEOGRAPHY}
      viewType={ViewTypes.GEO}
      primaryFilter={PrimaryFilters.GEOGRAPHY}
      sharedFilterSetId={FilterSets.GEOGRAPHY}
      filterSet={FilterSets.GEOGRAPHY_COMPENSATION}
      primaryViewFilters={primaryViewFilters}
      primaryFiltersLimit={PrimaryFilterLimits.COMPENSATION}
      selectableFilters={selectableFilters}
      filterMenuLimits={FilterMenuLimits.COMPENSATION}
      otherFilters={[]}
    />
  );
}

export default GeographyPay;
