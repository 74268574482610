import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react';
import { ActionModal, ActionModalControlPanel } from '@revelio/core';
import { has } from 'lodash';
import { useEffect, useState } from 'react';
import { UserStoredFilterSet } from '../../../engine/filters.model';
import { PersistMethods } from '../../../engine/filters.persist';
import { upsertStoredFilterSet } from '../../../engine/filters.storedset';

/* eslint-disable-next-line */
export interface NodeEditModalProps {
  defaultText?: string;
  isOpen: boolean;
  portalProps?: any;
  onClose: () => void;
  finalFocusRef?: any;
  setMeta: UserStoredFilterSet;
}

export function NodeEditModal({
  setMeta,
  defaultText,
  isOpen,
  onClose,
  portalProps,
  finalFocusRef,
  ...props
}: NodeEditModalProps) {
  const [inputString, setInputString] = useState<string>(defaultText || '');

  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!isOpen) {
      setInputString(defaultText || '');
      setIsError(false);
    }
  }, [isOpen, defaultText]);

  return (
    <ActionModal
      header="Rename Filter Set"
      isOpen={isOpen}
      onClose={onClose}
      portalProps={portalProps}
      finalFocusRef={finalFocusRef}
    >
      <ModalBody>
        <FormControl isRequired isInvalid={isError}>
          <FormLabel fontSize="sm">Set Name</FormLabel>
          <Input
            type="text"
            placeholder="Enter a set name"
            value={inputString}
            onChange={(e) => {
              if (isError) {
                setIsError(false);
              }
              setInputString(e.target.value);
            }}
          />

          <FormErrorMessage>
            Could not save changes to filter set.
          </FormErrorMessage>
        </FormControl>
      </ModalBody>
      <ModalFooter>
        <ActionModalControlPanel
          onClose={onClose}
          submitIsLoading={isLoading}
          onSubmit={async () => {
            setIsLoading(true);

            try {
              const res = await upsertStoredFilterSet({
                def: {
                  ...setMeta,
                  name: inputString,
                  label: inputString,
                  lastModified: new Date(),
                },
                options: {
                  updateFiltersState: false,
                  updateView: false,
                  defaultOptions: {
                    isDefault: setMeta.isDefault,
                  },
                },
                mode: PersistMethods.UPDATE_PRESET,
              });

              if (res) {
                const isError = has(res, 'error');

                if (isError) {
                  throw new Error('Could not save changes');
                } else {
                  setIsError(false);
                  onClose();
                }
              }
            } catch (err) {
              setIsError(true);
              console.log(err);
            } finally {
              setIsLoading(false);
            }
          }}
          submitIsDisabled={inputString.trim().length === 0}
          onReset={() => {
            setInputString(defaultText || '');
          }}
        />
      </ModalFooter>
    </ActionModal>
  );
}

export default NodeEditModal;
