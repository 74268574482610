import {
  SelectionCategories,
  useViewFilters,
  FilterContainer,
  FilterChips,
  AddEntityButton,
  FilterMenu,
  EndpointSegment,
  ViewTypes,
  useViewFilterDefaults,
  useStoredFilterSet,
  PrimaryFilterLimits,
  FilterMenuLimits,
  useSelectionLists,
  upsertFiltersWithProvidedValue,
  requireAtLeastOneFilterValueOf,
  useManyPlotConfigProviders,
  useDefaultLastMonth,
  DefaultDates,
  useSyncFiltersToSearchParams,
  PrimaryDataView,
  getPrimaryDataView,
  provideBasePlotConfigDefaults,
  FilterSets,
  FilterMenuItemOrConfig,
  OtherFilterNames,
  createSelectableFiltersMap,
  useSingleOrMoreFilterState,
  FilterOrSubfilterName,
  AnyFilter,
  SelectFilter,
  FilterList,
  ValidValueTypes,
  GEOGRAPHY_GRANULARITY_FILTERS,
  ROLE_GRANULARITY_FILTERS,
  SHARED_SET_ENTITY_LIMIT,
  LocalSelectionCategories,
  Tab,
  FilterChipsContainer,
  PlotAdditionalQueryParams,
  FiltersUsedInTabs,
  useAdaptiveRoleTaxonomy,
} from '@revelio/filtering';
import {
  Grid,
  GridItem,
  Flex,
  FormLabel,
  Switch,
  Text,
} from '@chakra-ui/react';
import { D3ChartNames } from '@revelio/d3';
import { DefaultCard } from '@revelio/composed';
import {
  AddEntityButtonText,
  LoaderType,
  PageTitles,
  PrimaryFilters,
  Views,
  useResponsivePageGridDefs,
} from '@revelio/core';
import { useEffect, useMemo, useState } from 'react';
import { filter, pipe, map, tap, distinctUntilChanged } from 'rxjs';
import DashboardPage from '../DashboardPage';
import { flatten, isEmpty, pickBy } from 'lodash';
import { Socket } from 'socket.io-client';
import {
  GptSentimentReview,
  ISentimentGptReviewInput,
  gptSentimentIndexSetter,
  sentimentSocket,
} from './gpt-sentiment-review';
import objectHash from 'object-hash';

export interface SentimentEffectsProps {
  title: PageTitles[];
  view: Views;
  viewType: Tab;
  primaryFilter: PrimaryFilters;
  enableGptSummaryReviews?: boolean;
  sharedFilterSetId?: FilterSets;
  filterSet: FilterSets;
  primaryViewFilters: FilterMenuItemOrConfig[];
  primaryFiltersLimit: PrimaryFilterLimits | number;
  nonActiveSelectableFilters?: (SelectionCategories | SelectionCategories[])[];
  selectableFilters: FilterMenuItemOrConfig[];
  filterMenuLimits: FilterMenuLimits | number;
  additionalNonActiveFilters?: (OtherFilterNames | SelectionCategories)[];
  viewFiltersForDefault?: (OtherFilterNames | SelectionCategories)[];
  onlyConsiderTheseFiltersToTriggerDefaults?: (
    | OtherFilterNames
    | SelectionCategories
  )[];
  trialNoResultsMessage?: JSX.Element;
  isGqlQuery?: boolean;
  isGoRequest?: boolean;
}

export function Sentiment({
  sharedFilterSetId = FilterSets.NONE,
  filterSet,
  primaryFilter,
  enableGptSummaryReviews = false,
  ...props
}: SentimentEffectsProps) {
  const { templateRows, templateColumns, tallGridItemMinHeight } =
    useResponsivePageGridDefs(Views.SENTIMENT);

  const selectableFiltersMap = createSelectableFiltersMap(
    props.selectableFilters
  );

  const primaryFilters = flatten(
    createSelectableFiltersMap(props.primaryViewFilters)
  ) as SelectionCategories[];
  const selectableFilters = flatten(selectableFiltersMap);

  const primaryDataView: PrimaryDataView = useMemo(
    () => getPrimaryDataView(props.viewType),
    [props.viewType]
  );

  const brokenOutFilterIds = [SelectionCategories.PRIMARY_FILTER];

  const storedFilterSetArgs = {
    sharedSetId: sharedFilterSetId,
    tab: props.viewType,
    primaryEntitiesSync: true,
    limit: props.primaryFiltersLimit,
    filterNames: primaryFilters,
    uniqueSetId: filterSet,
    defaultLimit: PrimaryFilterLimits.POSTINGS_DEFAULT,
  };

  useStoredFilterSet(storedFilterSetArgs);

  useSelectionLists([
    ...primaryFilters,
    ...selectableFilters,
    ...FiltersUsedInTabs,
  ]);
  useViewFilters([...primaryFilters, ...selectableFiltersMap]);

  useAdaptiveRoleTaxonomy({
    viewType: props.viewType,
    primaryFilters,
  });

  const viewFilterDefaultArgs = {
    view: props.view,
    viewType: props.viewType,
    presetView: sharedFilterSetId,
    onlyConsiderTheseFiltersToTriggerDefaults: [
      LocalSelectionCategories.PRIMARY_ENTITIES,
    ],
    viewFilters: [LocalSelectionCategories.PRIMARY_ENTITIES],
    limit: PrimaryFilterLimits.SENTIMENT,
    dateKey: SelectionCategories.DATE_RANGE,
    primaryFilters,
    supportPrimaryEntities: true,
  };
  useViewFilterDefaults(viewFilterDefaultArgs);

  useDefaultLastMonth({
    view: props.view,
    viewType: props.viewType,
    dateType: DefaultDates.DEFAULT_LAST_MONTH,
    dateKey: SelectionCategories.DATE_RANGE,
  });

  useSyncFiltersToSearchParams({
    primaryFilters,
    syncToPrimaryEntities: true,
  });

  useEffect(() => {
    if (enableGptSummaryReviews) {
      sentimentSocket.io.opts.query = {
        'request-id': crypto.randomUUID(),
      };
      sentimentSocket.connect();
    }
    const connectHandler = (connect = true) =>
      console.log(`socket ${connect ? '' : 'dis'}connected...`);
    const disconnectHandler = (reason: Socket.DisconnectReason) =>
      console.log('Disconnect Reason:', reason);
    sentimentSocket.once('connect', connectHandler);
    sentimentSocket.once('disconnect', disconnectHandler);

    return () => {
      if (enableGptSummaryReviews) {
        sentimentSocket.disconnect();
      }
      sentimentSocket.off('connect', () => connectHandler);
      sentimentSocket.off('disconnect', () => disconnectHandler);
    };
  }, [enableGptSummaryReviews]);

  const allFilters = useMemo(
    () => [...primaryFilters, ...selectableFilters],
    [primaryFilters, selectableFilters]
  );

  useSingleOrMoreFilterState(
    allFilters as FilterOrSubfilterName[],
    pipe(
      filter((f) => !isEmpty(f) && enableGptSummaryReviews),
      map((filters: AnyFilter<FilterList<ValidValueTypes>>[]) => {
        let primarySetCount = 0;
        const filledResult = filters.reduce(
          (s, f) => {
            let propPath = 'id';
            if (f.id == SelectionCategories.COMPANY) {
              if (primaryFilter == PrimaryFilters.COMPANY) {
                s.COMPANY_NAME = (
                  f as SelectFilter<FilterList<ValidValueTypes>>
                ).value[0].shortName as string;
                primarySetCount += 1;
              }

              propPath = 'rcid';
            }
            if (
              f.id == SelectionCategories.INDUSTRY &&
              primaryFilter == PrimaryFilters.COMPANY
            ) {
              s.INDUSTRY_NAME = (f as SelectFilter<FilterList<ValidValueTypes>>)
                .value[0].label as string;
              primarySetCount += 1;
            }
            if (
              GEOGRAPHY_GRANULARITY_FILTERS.includes(
                f.id as SelectionCategories
              ) &&
              primaryFilter == PrimaryFilters.GEOGRAPHY
            ) {
              s.GEOGRAPHY_NAME = (
                f as SelectFilter<FilterList<ValidValueTypes>>
              ).value[0].label as string;
              primarySetCount += 1;
            }
            if (
              ROLE_GRANULARITY_FILTERS.includes(f.id as SelectionCategories) &&
              primaryFilter == PrimaryFilters.ROLE
            ) {
              s.ROLE_NAME = (f as SelectFilter<FilterList<ValidValueTypes>>)
                .value[0].label as string;
              primarySetCount += 1;
            }

            gptSentimentIndexSetter(f, s, propPath);
            return s;
          },
          {
            COMPANY_NAME: '',
            INDUSTRY_NAME: '',
            INDUSTRY_INDEX: [],
            RCID: [],
            GEOGRAPHY_NAME: '',
            MSA_INDEX: [],
            COUNTRY_INDEX: [],
            REGION_INDEX: [],
            ROLE_NAME: '',
            ROLE_K7_INDEX: [],
            ROLE_K150_INDEX: [],
            MAPPED_ROLE_INDEX: [],
            SENIORITY: [],
            MONTH_INDEX: [],
            MODEL: 'gpt-3.5-turbo',
          } as ISentimentGptReviewInput
        );

        const compactFilledResult = pickBy(filledResult, (x) => !isEmpty(x));
        return {
          primarySetCount,
          input: compactFilledResult,
          hash: objectHash(primarySetCount == 1 ? compactFilledResult : {}, {
            unorderedArrays: true,
          }),
        };
      }),
      filter((source) => source.primarySetCount == 1),
      distinctUntilChanged((prev, curr) => prev.hash == curr.hash),
      tap(({ input }) => {
        sentimentSocket.emit('chat', input);
      })
    )
  );

  const additionalOperatorsBeforeQuery = pipe(
    requireAtLeastOneFilterValueOf(primaryFilters),
    filter((source: PlotAdditionalQueryParams) => {
      // Require date to be set
      // do not fire a request until date range is set to avoid cancelled request (can happen before last month has resolved)
      return !!source.filters.find(
        (filter) => filter.id === SelectionCategories.DATE_RANGE
      );
    })
  );

  const {
    mappers: [
      {
        metaData,
        endpointMapper,
        downloadEndpointMapper,
        plotConfigMapper,
        brokenOutFilterIds: brokenOutFilterIdsConfig,
        // updater,
        endpointSegment,
        dataProvider,
      },
    ],
  } = useManyPlotConfigProviders([
    {
      view: Views.SENTIMENT_RATING,
      viewType: ViewTypes.SNAPSHOT,
      endpoint: EndpointSegment.EFFECT,
      chartType: D3ChartNames.BarChartHorizontalMirror,
      chartProps: {
        view: Views.SENTIMENT_EFFECT,
        name: 'sentiment-effect-plot',
        chartStyle: '.sentiment-effect-page',
        ttMainFormat: '.2f',
        ttSecondaryFormat: ',',
        useShortName: true,
        marginTop: 40,
      },
      brokenOutFilterIds: brokenOutFilterIds,
      metaData: {
        isGqlQuery: true,
        isGoRequest: true,
        pageGroupName: 'sentiment',
        primaryDataView: primaryDataView,
      },
    },
  ]);

  const viewDefaultsForPlots = provideBasePlotConfigDefaults({
    view: Views.SENTIMENT_RATING,
    viewType: ViewTypes.REVIEWS,
    chartType: D3ChartNames.WordCloud,
    brokenOutFilterIds: brokenOutFilterIds,
  });

  const { mappers: configMappers } = useManyPlotConfigProviders([
    viewDefaultsForPlots({
      endpoint: EndpointSegment.POSITIVE,
      chartProps: {
        name: 'wordcloud-positive',
        heading: 'Positive Reviews',
        chartStyle: `.sentiment-reviews-page-${EndpointSegment.POSITIVE}`,
        positiveSentiment: true,
        ttMainFormat: ',.0f',
      },
      metaData: {
        isGqlQuery: true,
        isGoRequest: true,
        pageGroupName: 'sentiment',
        primaryDataView: primaryDataView,
      },
    }),
    viewDefaultsForPlots({
      endpoint: EndpointSegment.NEGATIVE,
      chartProps: {
        name: 'wordcloud-negative',
        heading: 'Negative Reviews',
        chartStyle: `.sentiment-reviews-page-${EndpointSegment.NEGATIVE}`,
        positiveSentiment: false,
        ttMainFormat: ',.0f',
      },
      metaData: {
        isGqlQuery: true,
        isGoRequest: true,
        pageGroupName: 'sentiment',
        primaryDataView: primaryDataView,
      },
    }),
  ]);

  useEffect(() => {
    if (primaryFilter) {
      upsertFiltersWithProvidedValue(
        {
          [SelectionCategories.PRIMARY_FILTER]: primaryFilter,
        },
        true
      );
    }
  }, [primaryFilter]);

  const [showWordcloudNotGptSummaryPos, setshowWordcloudNotGptSummaryPos] =
    useState<boolean>(!enableGptSummaryReviews);
  const [showWordcloudNotGptSummaryNeg, setshowWordcloudNotGptSummaryNeg] =
    useState<boolean>(!enableGptSummaryReviews);

  const handleReviewsToggle = (type: 'pos' | 'neg') => {
    const toggle = {
      pos: setshowWordcloudNotGptSummaryPos,
      neg: setshowWordcloudNotGptSummaryNeg,
    }[type];
    toggle((b) => !b);
  };

  return (
    <DashboardPage
      title={props.title}
      hideSelectionsMargins
      loader={LoaderType.GLOBAL_V2}
      selections={
        <Flex
          justifyContent="flex-start"
          alignItems="center"
          flexDirection="row"
          wrap="wrap"
          rowGap="0.5rem"
        >
          <FilterChipsContainer
            filterNames={primaryFilters}
            variant="companyChip"
            isPrimaryChip={true}
            min={1}
            limit={PrimaryFilterLimits.SENTIMENT}
            addButton={
              <AddEntityButton
                entities={props.primaryViewFilters}
                entityName={AddEntityButtonText[primaryFilter]}
                buttonText={AddEntityButtonText[primaryFilter]}
                limit={SHARED_SET_ENTITY_LIMIT}
                required={1}
                activeLimit={1}
                trialNoResultsMessage={props.trialNoResultsMessage}
              />
            }
          />
        </Flex>
      }
    >
      <FilterContainer
        flexDirection="row"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Flex
          justifyContent="flex-start"
          alignItems="flex-start"
          flexDirection="row"
          wrap="wrap"
          rowGap="0.5rem"
        >
          <FilterChips
            filterNames={props.selectableFilters}
            variant="filterChip"
            limit={FilterMenuLimits.SENTIMENT}
            viewType={props.viewType}
            showGranularity
            addButton={
              <FilterMenu
                title="Filter"
                filters={[
                  ...props.selectableFilters,
                  // SelectionCategories.SAVED_FILTER_SET,
                ]}
                selectMenuOpenDefault
                limit={FilterMenuLimits.SENTIMENT}
                view={props.view}
                showFilterSetSaveMenu={false}
              />
            }
          />
        </Flex>
      </FilterContainer>

      <Grid
        height="100%"
        templateRows={templateRows}
        templateColumns={templateColumns}
        gap={4}
        data-testid="plots-grid"
      >
        <GridItem rowSpan={2} colSpan={2} minH={tallGridItemMinHeight}>
          <DefaultCard
            cardConfig={{
              header: 'Effects',
              endpointSegment,
              view: Views.SENTIMENT_EFFECT,
            }}
            plotConfig={{
              endpoint: endpointMapper,
              chartTypeAndProps: plotConfigMapper,
              additionalOperatorsBeforeQuery: additionalOperatorsBeforeQuery,
              brokenOutFilterIds: brokenOutFilterIdsConfig,
              dataProvider,
              isGqlQuery: metaData?.isGqlQuery,
            }}
            downloadConfig={{
              endpoint: downloadEndpointMapper,
              isGoRequest: metaData?.isGoRequest,
            }}
          />
        </GridItem>

        {configMappers.map(
          (
            {
              name,
              metaData,
              endpointMapper,
              plotConfigMapper,
              endpointSegment,
              downloadEndpointMapper,
              dataProvider,
              brokenOutFilterIds: brokenOutFilterIdsConfig,
            },
            i
          ) => (
            <GridItem key={i} rowSpan={1} colSpan={1}>
              <DefaultCard
                cardConfig={{
                  header: `${name} Summaries`,
                  endpointSegment,
                  view: Views.SENTIMENT_REVIEW,
                }}
                customPlotInfo={
                  (
                    i == 0
                      ? showWordcloudNotGptSummaryPos
                      : showWordcloudNotGptSummaryNeg
                  )
                    ? undefined
                    : {
                        plotInfoConfig: {
                          iconBoxSize: 3,
                          popoverPlacement: 'top',
                        },
                        plotInfoBody: (
                          <Text variant="tooltip">
                            An AI-generated summary of all{' '}
                            {i == 0 ? 'positive' : 'negative'} reviews.
                          </Text>
                        ),
                      }
                }
                alternateMainContent={
                  enableGptSummaryReviews ? (
                    <GptSentimentReview
                      sx={{
                        visibility: (
                          i == 0
                            ? showWordcloudNotGptSummaryPos
                            : showWordcloudNotGptSummaryNeg
                        )
                          ? 'hidden'
                          : 'visible',
                        height: (
                          i == 0
                            ? showWordcloudNotGptSummaryPos
                            : showWordcloudNotGptSummaryNeg
                        )
                          ? 0
                          : '100%',
                        padding: (
                          i == 0
                            ? showWordcloudNotGptSummaryPos
                            : showWordcloudNotGptSummaryNeg
                        )
                          ? '0px'
                          : '32px 12px 12px 12px',
                      }}
                      reviewType={i == 0 ? 'pos' : 'neg'}
                      filters={allFilters}
                      socket={sentimentSocket}
                    />
                  ) : undefined
                }
                alternateMainContentDisplayStateForPrimaryContent={
                  /* eslint-disable-next-line no-nested-ternary */
                  enableGptSummaryReviews
                    ? i == 0
                      ? showWordcloudNotGptSummaryPos
                      : showWordcloudNotGptSummaryNeg
                    : undefined
                }
                plotConfig={{
                  endpoint: endpointMapper,
                  chartTypeAndProps: plotConfigMapper,
                  additionalOperatorsBeforeQuery:
                    additionalOperatorsBeforeQuery,
                  brokenOutFilterIds: brokenOutFilterIdsConfig,
                  isGqlQuery: metaData?.isGqlQuery,
                  dataProvider: dataProvider,
                  sx: {
                    paddingBottom: '12px',
                  },
                }}
                downloadConfig={{
                  endpoint: downloadEndpointMapper,
                  isGoRequest: metaData?.isGoRequest,
                }}
                disableTopRightCardActionMenu={true}
                topRight={
                  enableGptSummaryReviews && (
                    <Flex>
                      <FormLabel
                        htmlFor="uni-toggle"
                        margin="0"
                        px="4px"
                        fontSize="12px"
                        color="text.primary"
                      >
                        Word Cloud
                      </FormLabel>
                      <Switch
                        id="uni-toggle"
                        className={`uni-toggle-${name?.toLowerCase()}`}
                        size="sm"
                        isChecked={
                          i == 0
                            ? showWordcloudNotGptSummaryPos
                            : showWordcloudNotGptSummaryNeg
                        }
                        onChange={() =>
                          handleReviewsToggle(i == 0 ? 'pos' : 'neg')
                        }
                        colorScheme="green"
                        paddingRight="4px"
                        data-testid={`${
                          i == 0 ? 'pos' : 'neg'
                        }-word-cloud-switch`}
                      />
                    </Flex>
                  )
                }
              />
            </GridItem>
          )
        )}
      </Grid>
    </DashboardPage>
  );
}
