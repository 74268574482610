import { Input } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { usePlayer } from './context/PlayerContext';
import { isEmpty } from 'lodash';
import useSpacebarPress from '../game/hooks/useSpacebarPress';
import { useCallback, useEffect } from 'react';
import { nanoid } from 'nanoid';

type FormValues = {
  email: string;
};

interface EmailFormInputProps {
  subscribe: (value: { EMAIL: string; tags: string }) => void;
  status: string;
  message: string;
  isOpen: boolean;
  onClose: () => void;
}

export const EmailFormInput = ({
  subscribe,
  status,
  message,
  isOpen,
  onClose,
}: EmailFormInputProps) => {
  const { isPressed } = useSpacebarPress();
  const {
    handleSubmit,
    register,
    // reset,
    getValues,
    trigger,
    formState: { errors, isValid },
  } = useForm<FormValues>();

  const { setPlayer } = usePlayer();

  const _onSubmit = (value: { email: string }) => {
    const { email } = value;

    setPlayer({ playerId: `player_${nanoid()}`, email });

    if (!isEmpty(email)) {
      subscribe({
        EMAIL: email,
        tags: '5379473',
      });
    }
  };

  const onSubmit = useCallback(_onSubmit, [setPlayer, subscribe]);

  useEffect(() => {
    if (isPressed && isOpen) {
      trigger('email').then((isValid) => {
        const { email } = getValues();

        if (isValid) {
          onSubmit({ email });
          onClose();
          return;
        }

        if (!isValid && isEmpty(email)) {
          setPlayer({ playerId: `player_${nanoid()}`, email: '' });
          onClose();
        }
      });
    }
  }, [
    isPressed,
    onSubmit,
    onClose,
    isOpen,
    getValues,
    isValid,
    setPlayer,
    subscribe,
    trigger,
  ]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {/* <Text color="white" mb="8px">
        Enter your email
      </Text> */}
      <Input
        autoFocus
        autoComplete="off"
        w="full"
        size="lg"
        h="80px"
        fontSize="36px"
        textAlign="center"
        type="email"
        backgroundColor="white"
        color="black"
        // pl="40px"
        // variant={'filled'}
        borderRadius="40px"
        placeholder="Enter your email"
        {...register('email', {
          required: 'Email',
          pattern: {
            value: /^\s*[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\s*$/i,
            message: 'Invalid Email Address',
          },
        })}
      />
      {/* <button type="submit" style={{ color: 'white' }}>
          Submit
        </button> */}
      <div style={{ color: 'white' }}>
        {status === 'success' && message}
        {status === 'error' && message}
        {errors?.email &&
          Object.entries(errors).map((error, index) => (
            <span key={error[1].message}>
              {Object.keys(errors).length === index + 1 ? (
                <>{error[1].message} Required</>
              ) : (
                <>{error[1].message}, </>
              )}
            </span>
          ))}
      </div>
    </form>
  );
};
