import { Views } from '@revelio/core';
import {
  FilterMenuLimits,
  ISelectFilter,
  LocalSelectionCategories,
  SelectionCategories,
} from '../../../engine/filters.model';
import { SubmitRefHandle, TempSelections } from '../types';
import { TreeMethodHandle, TreeRef, TreeType } from '../../collection';
import {
  SelectionListsToNotSortSelectedToTop,
  formatOverrideLookup,
} from '../lookups.config';
import { ProviderSelectionList } from './provider-selection-list';

const screenerAndLayoffFilters = [
  SelectionCategories.COUNT,
  SelectionCategories.DATE_RANGE,
  SelectionCategories.DATE_RANGE_FULL,
  SelectionCategories.HEADCOUNT,
  SelectionCategories.HIRING_RATE,
  SelectionCategories.ATTRITION_RATE,
  SelectionCategories.GROWTH_RATE,
  SelectionCategories.AVERAGE_TENURE,
  SelectionCategories.AVERAGE_SALARY,
  SelectionCategories.COMPANY_CLEANED,
];

const otherFilters = [
  SelectionCategories.SNAPSHOT_DATE,
  SelectionCategories.DATE_RANGE,
  SelectionCategories.DATE_RANGE_FULL,
  SelectionCategories.COMPANY_NAME,
  SelectionCategories.COMPANY_CLEANED,
  SelectionCategories.KEYWORD,
  SelectionCategories.RAW_TITLE,
];

export interface TreeFiltersProps {
  view: Views;
  selectValue: ISelectFilter | null | undefined;
  submitRef: React.MutableRefObject<SubmitRefHandle>;
  setTempSelections: React.Dispatch<React.SetStateAction<TempSelections>>;
  expandRoots: boolean;
  offsetParent: (SelectionCategories | LocalSelectionCategories)[];
  showActionMenu: boolean;
  setNodeModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  viewIdForDefault: string | undefined;
  handleFilterSubmit: (selections?: TempSelections) => void;
  closeFilterMenu: () => void;
}

export const TreeFilters = ({
  view,
  selectValue,
  submitRef,
  setTempSelections,
  expandRoots,
  offsetParent,
  showActionMenu,
  setNodeModalOpen,
  handleFilterSubmit,
  viewIdForDefault,
  closeFilterMenu,
}: TreeFiltersProps) => {
  if (!selectValue) {
    return null;
  }

  const hasFormatOverride = (view: Views, filterName: SelectionCategories) => {
    if (view in formatOverrideLookup) {
      const filtersThatRequireOverride = formatOverrideLookup[view];

      return filtersThatRequireOverride.includes(filterName);
    }

    return false;
  };

  const limit =
    // eslint-disable-next-line no-nested-ternary
    view === Views.SCREENER
      ? FilterMenuLimits.COMPANY_SCREENER_INDUSTRY
      : [
            SelectionCategories.SAVED_FILTER_SET,
            LocalSelectionCategories.DATA_METRIC,
            LocalSelectionCategories.METRIC_MODE,
          ].includes(selectValue.filterName as any)
        ? FilterMenuLimits.SAVED_FILTER_SET
        : selectValue?.limit;

  const ref = (element: TreeMethodHandle) =>
    (submitRef.current[selectValue.filterName] = {
      filterName: selectValue.filterName,
      value: element,
    });

  const shouldRenderTreeRef = () => {
    if ([Views.SCREENER, Views.LAYOFF].includes(view)) {
      return !screenerAndLayoffFilters.includes(
        selectValue.filterName as SelectionCategories
      );
    }

    return !otherFilters.includes(
      selectValue.filterName as SelectionCategories
    );
  };

  if (selectValue.filterName === LocalSelectionCategories.PROVIDER) {
    return (
      <ProviderSelectionList
        close={closeFilterMenu}
        handleFilterSubmit={handleFilterSubmit}
      />
    );
  }

  return (
    shouldRenderTreeRef() && (
      <TreeRef
        key={selectValue.label}
        placeholder={selectValue.label}
        selectionLists={
          Array.isArray(selectValue.value)
            ? selectValue.value
            : [selectValue.value]
        }
        formatOverride={
          hasFormatOverride(view, selectValue.filterName as SelectionCategories)
            ? view
            : undefined
        }
        submitOnBlur={false}
        setTempSelections={setTempSelections}
        limit={limit}
        ref={ref}
        required={0}
        expandRootsByDefault={expandRoots}
        offsetParent={offsetParent}
        disableParentSelect={selectValue.isParentListDisabled}
        hideParentCheckbox={selectValue.isParentListDisabled}
        showActionMenu={showActionMenu}
        setNodeModalOpen={setNodeModalOpen}
        unselectParentOnChildSelect
        showHeader={selectValue.isNested}
        viewIdForDefault={viewIdForDefault}
        nestingTreeType={
          selectValue.isNested ? TreeType.SUB_MENU_NESTED : undefined
        }
        sortSelectedToTop={
          !SelectionListsToNotSortSelectedToTop.includes(selectValue.filterName)
        }
      />
    )
  );
};
