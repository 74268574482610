import { Box, Flex } from '@chakra-ui/layout';
import { useEffect, useRef, useState } from 'react';
import Select, { Props, components } from 'react-select';
import { Button, ButtonGroup } from '@chakra-ui/react';
import { isEqual, noop } from 'lodash';
import { defaultStyles } from './select-styles';
import { CustomOption } from './option';
import { SearchIcon } from '@chakra-ui/icons';

export type Option = {
  value: string;
  label: string;
};

export type SelectionListValue = readonly Option[];
export type SelectionListControls = {
  value: SelectionListValue;
  setValue: React.Dispatch<React.SetStateAction<SelectionListValue>>;
  clearSelections: () => void;
};

export type SelectionListProps = {
  defaultValue?: Option[];
  close?: () => void;
  onChangeOverride?: (
    selectedOptions: SelectionListValue,
    { value, setValue, clearSelections }: SelectionListControls
  ) => void;
  submitLabel?: string;
  submit: (value: SelectionListValue) => void;
};

export const SelectionList = ({
  options,
  defaultValue = [],
  close,
  onChangeOverride = noop,
  submitLabel = 'Add',
  submit,
}: Pick<Props<Option, true>, 'options'> & SelectionListProps) => {
  const [value, setValue] = useState<SelectionListValue>(defaultValue);

  const prevDefaultValue = useRef(defaultValue);
  useEffect(() => {
    if (!isEqual(prevDefaultValue.current, defaultValue)) {
      prevDefaultValue.current = defaultValue;
      setValue(defaultValue);
    }
  }, [defaultValue]);

  const clearSelections = () => {
    setValue([]);
  };

  const handleAddSelections = () => {
    submit(value);
    close && close();
  };

  const onChange = onChangeOverride
    ? (selectedOptions: SelectionListValue) =>
        onChangeOverride(selectedOptions, { value, setValue, clearSelections })
    : (selectedOptions: SelectionListValue) => {
        setValue(selectedOptions);
      };

  return (
    <Box>
      <Select<Option, true>
        options={options}
        value={value}
        menuIsOpen
        isMulti
        placeholder="Search..."
        controlShouldRenderValue={false}
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        isClearable={false}
        backspaceRemovesValue={false}
        components={{
          DropdownIndicator: (props) => (
            <components.DropdownIndicator {...props}>
              <SearchIcon />
            </components.DropdownIndicator>
          ),
          Option: CustomOption,
        }}
        onChange={onChange}
        styles={{
          container: (base) => ({ ...base, ...defaultStyles.container }),
          control: (base) => ({ ...base, ...defaultStyles.control }),
          valueContainer: (base) => ({
            ...base,
            ...defaultStyles.valueContainer,
          }),
          input: (base) => ({ ...base, ...defaultStyles.input }),
          indicatorsContainer: (base) => ({
            ...base,
            ...defaultStyles.indicatorsContainer,
          }),
          indicatorSeparator: (base) => ({
            ...base,
            ...defaultStyles.indicatorSeparator,
          }),
          dropdownIndicator: (base) => ({
            ...base,
            ...defaultStyles.dropdownIndicator,
          }),
          menu: (base) => ({ ...base, ...defaultStyles.menu }),
          menuList: (base) => ({ ...base, ...defaultStyles.menuList }),
          option: (base, { isFocused }) => ({
            ...base,
            ...defaultStyles.option,
            backgroundColor: isFocused ? '#F7FAFC' : 'transparent',
            borderColor: isFocused ? '#CBD5E0' : '#E2E8F0',
          }),
        }}
      />
      <Flex justifyContent="space-between">
        <Button
          size="sm"
          fontSize="12px"
          variant="ghost"
          colorScheme="red"
          onClick={clearSelections}
        >
          Clear Selections
        </Button>
        <ButtonGroup size="sm" spacing={4}>
          <Button
            size="md"
            fontSize="12px"
            variant="link"
            colorScheme="gray"
            onClick={close}
          >
            Cancel
          </Button>
          <Button
            variant="filteraction"
            bg="green.500"
            colorScheme="green"
            onClick={handleAddSelections}
            data-testid="filter-popover-submit"
          >
            {submitLabel}
          </Button>
        </ButtonGroup>
      </Flex>
    </Box>
  );
};
