import React, { createContext, useContext } from 'react';

export type Player = {
  playerId: string;
  email: string;
};

type PlayerContextType = {
  player: Player;
  setPlayer: React.Dispatch<React.SetStateAction<Player>>;
  resetPlayer: () => void;
};

const PlayerContext = createContext<PlayerContextType | undefined>(undefined);

export const usePlayer = () => {
  const context = useContext(PlayerContext);

  if (!context) {
    throw new Error('usePlayer must be used within a PlayerProvider');
  }

  return context;
};

export const PlayerProvider: React.FC<{
  player: Player;
  setPlayer: PlayerContextType['setPlayer'];
  resetPlayer: () => void;
  children: React.ReactNode;
}> = ({ player, setPlayer, resetPlayer, children }) => {
  return (
    <PlayerContext.Provider value={{ player, setPlayer, resetPlayer }}>
      {children}
    </PlayerContext.Provider>
  );
};
